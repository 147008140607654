import { DateTime } from 'luxon';

const today = () => {
  return DateTime.local();
};
const lastWeek = (d = today()) => {
  return d.minus({ days: 7 });
};
const beginningOfTime = () => {
  return date(new Date('2021-12'));
};
const date = (d) => DateTime.fromMillis(new Date(d).getTime());

const formatDateForTitle = (date) => {
  return date.toFormat('d MMM yyyy');
};
const formatDateForTitleShort = (date) => {
  return date.toFormat('MMM') + "'" + date.toFormat('yy');
};

const getMonthsBetweenDates = (format, start, end) => {
  let count = 0;
  let temp = new DateTime(start);
  const months = [];
  while (
    count < 1000 &&
    temp.toFormat('MMM yyyy') != end.toFormat('MMM yyyy')
  ) {
    months.push(temp.toFormat(format));
    temp = temp.plus({ months: 1 });
    count++;
  }
  months.push(temp.toFormat(format));
  return months;
};

const isInDateRange = (from, to, current) => {
  return from <= current && current <= to;
};

const getLastTimeStart = (from, to) => {
  return from.plus({
    days: Math.floor(from.diff(to, 'days').toObject().days) - 1
  });
};

const getLastTimeEnd = (from) => {
  return from.plus({ days: -1 });
};

const dateState = function (start, end) {
  this.start = start;
  this.end = end;
  this.lastTimeStart = getLastTimeStart(this.start, this.end);
  this.lastTimeEnd = getLastTimeEnd(this.start);
  this.dates = {
    start,
    end
  };
  this.lastTimeDates = {
    start: this.lastTimeStart,
    end: this.lastTimeEnd
  };
  this.setDates = (dates) => {
    this.start = date(dates.start);
    this.end = date(dates.end);
    this.lastTimeStart = getLastTimeStart(this.start, this.end);
    this.lastTimeEnd = getLastTimeEnd(this.start);
    this.dates = {
      start: this.start,
      end: this.end
    };
    this.lastTimeDates = {
      start: this.lastTimeStart,
      end: this.lastTimeEnd
    };
  };
};

export {
  today,
  lastWeek,
  date,
  formatDateForTitle,
  formatDateForTitleShort,
  getMonthsBetweenDates,
  isInDateRange,
  getLastTimeStart,
  getLastTimeEnd,
  beginningOfTime,
  dateState
};
