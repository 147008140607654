import axios from 'axios';
import { ref } from 'vue';
import { getCompaniesSearchConfig } from '~/functions/setup/api-setup';
import dataWrapper from '~/functions/wrappers/data-wrapper';
import { LAZY_SEARCH_PAGE_SIZE } from '~/constants/index';
import userState from '~/store/state-managers/user';
import { map } from 'lodash';

const BASE_URL = process.env.VUE_APP_STRAPI_URL;
function useCompany(companies) {
  let companySearchHasChanged = true;
  const { isSuperAdmin, isUser } = userState();
  const trainingVideo = ref({ success: false, url: false });
  const fetchTrainingVideo = async (id) => {
    try {
      const result = await axios({
        method: 'GET',
        url: `${BASE_URL}/companies/training-video/${id}`,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('_token')}`
        }
      });
      trainingVideo.value = result.data;
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const { suggestedCompanies, fetchData: fetchCompanySuggestions } =
    dataWrapper({
      reports: getCompaniesSearchConfig(
        {
          'pagination[pageSize]': LAZY_SEARCH_PAGE_SIZE,
          _append_: isSuperAdmin
            ? undefined
            : '&' +
              map(
                companies,
                (company, index) => `filters[id][$eq][${index}]=${company.id}`
              ).join('&'),
          sort: 'description:asc',
          publicationState: 'preview',
          'populate[0]': 'masterProfiles'
        },
        isUser
      ),
      onStart: () => (companySearchHasChanged = true)
    });

  const resetCompanySearch = () => {
    if (companySearchHasChanged) {
      fetchCompanySuggestions({});
    }
    companySearchHasChanged = false;
  };
  return {
    fetchTrainingVideo,
    trainingVideo,
    suggestedCompanies,
    fetchCompanySuggestions,
    resetCompanySearch
  };
}

export default useCompany;
