<template>
  <div class="home-page grid grid-cols-2 justify-items-stretch gap-4 p-5 md:p-0 lg:grid-cols-4 md:grid-cols-1">
    <template v-if="featureFlagHandler.allowedFeature['corporate'] || featureFlagHandler.isSuperAdmin">
      <template v-if="featureFlagHandler.isUser">
        <div
          :class="buttonBlock"
          @click="goto('self-service')"
          class="go-to-self-service"
        >
          <div :class="buttonBlockTopCircle">
            <i
              class="fa-solid fa-user pr-1 pl-1"
              :class="buttonBlockIconCircle"
            ></i>
          </div>
          <div>
            <h2 class="text-3xl mb-2"> Edit my profile </h2>
            <p :class="buttonBlockSubtitle"> Update basic details </p>
          </div>
        </div>

        <div
          :class="buttonBlock"
          @click="goto('statistics')"
          class="go-to-users"
        >
          <div :class="buttonBlockTopCircle">
            <i
              class="fa-sharp fa-solid fa-chart-simple pr-1 pl-1"
              :class="buttonBlockIconCircle"
            ></i>
          </div>
          <div>
            <h2 class="text-3xl mb-2"> View my statistics </h2>
            <p :class="buttonBlockSubtitle">
              View content interactions and insights
            </p>
          </div>
        </div>
      </template>
      <template v-else>
        <v-modal
          v-if="featureFlagHandler.allowedFeature['invite_users'] || featureFlagHandler.isSuperAdmin"
          title="Invite a New User"
          :modalName="'newUserModal'"
        >
          <template #trigger="{ open }">
            <div
              @click="open"
              :class="buttonBlock"
              class="invite-new-user bg-brand-color text-white"
            >
              <div :class="buttonBlockTop">
                <i
                  class="fa-solid fa-circle-plus text-white"
                  :class="buttonBlockIcon"
                ></i>
                <p
                  :class="buttonBlockGroupTitle"
                  class="text-white"
                >Users</p>
              </div>
              <div>
                <h2 class="text-3xl mb-2"> Invite a New User </h2>
                <p
                  :class="buttonBlockSubtitle"
                  class="text-white"
                >
                  Active new users
                </p>
              </div>
            </div>
          </template>
          <template #content>
            <add-users-modal @onComplete="(newUser) => afterAddingUser(newUser)" />
          </template>
        </v-modal>

        <div
          :class="buttonBlock"
          @click="goto('users')"
          class="manage-users"
        >
          <div :class="buttonBlockTop">
            <i
              class="fa-solid fa-circle-user"
              :class="buttonBlockIcon"
            ></i>
            <p :class="buttonBlockGroupTitle">Users</p>
          </div>
          <div>
            <h2 class="text-3xl mb-2"> Manage Users </h2>
            <p :class="buttonBlockSubtitle"> Add & edit content </p>
          </div>
        </div>

        <div
          v-if="hasTemplatesFlag"
          :class="buttonBlock"
          @click="goto('templates')"
          class="manage-templates"
        >
          <div :class="buttonBlockTop">
            <i
              class="fa-solid fa-user-gear"
              :class="buttonBlockIcon"
            ></i>
            <p :class="buttonBlockGroupTitle">Templates</p>
          </div>
          <div>
            <h2 class="text-3xl mb-2"> Manage Templates </h2>
            <p :class="buttonBlockSubtitle"> Same test here </p>
          </div>
        </div>

        <div
          v-if="hasApprovalsFlag"
          :class="buttonBlock"
          @click="goto('approvals')"
          class="manage-groups"
        >
          <div :class="buttonBlockTop">
            <i
              class="fa-solid fa-user-check"
              :class="buttonBlockIcon"
            ></i>
            <p :class="buttonBlockGroupTitle">Approvals</p>
          </div>
          <div>
            <h2 class="text-3xl mb-2"> Manage Approvals </h2>
            <p :class="buttonBlockSubtitle"> Approve Changes </p>
          </div>
        </div>
      </template>
    </template>

    <template v-else>
      <div
        :class="buttonBlock"
        @click="goto('self-service')"
        class="go-to-self-service"
        v-if="featureFlagHandler.isUser"
      >
        <div :class="buttonBlockTopCircle">
          <i
            class="fa-solid fa-user pr-1 pl-1"
            :class="buttonBlockIconCircle"
          ></i>
        </div>
        <div>
          <h2 class="text-3xl mb-2"> Edit my profile </h2>
          <p :class="buttonBlockSubtitle"> Update basic details </p>
        </div>
      </div>

      <div
        :class="buttonBlock"
        @click="goto('users')"
        class="go-to-users"
        v-else
      >
        <div :class="buttonBlockTopCircle">
          <i
            class="fa-solid fa-users pt-1 pb-1"
            :class="buttonBlockIconCircle"
          ></i>
        </div>
        <div>
          <h2 class="text-3xl mb-2"> Users </h2>
          <p :class="buttonBlockSubtitle">
            Edit profiles and view individual stats
          </p>
        </div>
      </div>

      <div
        :class="buttonBlock"
        @click="goto('statistics')"
        class="go-to-users"
      >
        <div :class="buttonBlockTopCircle">
          <i
            class="fa-sharp fa-solid fa-chart-simple pr-1 pl-1"
            :class="buttonBlockIconCircle"
          ></i>
        </div>
        <div>
          <h2 class="text-3xl mb-2"> View my statistics </h2>
          <p :class="buttonBlockSubtitle">
            View content interactions and insights
          </p>
        </div>
      </div>
      <a
        v-if="!hasWhiteLabel"
        :class="buttonBlock"
        href="https://hello.profileme.app/marketplace"
        class="go-to-users text-brand-gray"
        target="_blank"
      >
        <div :class="buttonBlockTopCircle">
          <i
            class="fa-solid fa-cart-shopping"
            :class="buttonBlockIconCircle"
          ></i>
        </div>
        <div>
          <h2 class="text-3xl mb-2"> Go to marketplace </h2>
          <p :class="buttonBlockSubtitle"> Get add-ons for your profile </p>
        </div>
      </a>
      <a
        :class="buttonBlock"
        :href="customSupportEmail"
        class="goto text-brand-gray"
        target="_blank"
      >
        <div :class="buttonBlockTopCircle">
          <i
            class="fa-solid fa-headset"
            :class="buttonBlockIconCircle"
          ></i>
        </div>
        <div>
          <h2 class="text-3xl mb-2"> Get support </h2>
          <p :class="buttonBlockSubtitle"> Ask our support team for help </p>
        </div>
      </a>
    </template>

    <v-modal
      :title="'Success!'"
      :modalName="'addedUserModal'"
    >
      <template #content>
        <div class="p-12">
          <p
            class="text-2xl"
            style="color: gray"
          >
            The user has successfully been invited! An email has been sent to
            {{ newUser.email }} to sign up.
          </p>

          <div class="h-12" />
          <div class="w-96">
            <pm-button @click="clickOnUserSuccess">Manage User </pm-button>
          </div>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<script>
import AddUsersModal from '~/components/functional/add-users-modal.vue';
import vModal from '~/components/functional/v-modal.vue';
import { $vfm } from 'vue-final-modal';
import PmButton from '~/components/ui/pm-button.vue';
import { get, includes } from 'lodash';

export default {
  props: {
    featureFlagHandler: {
      default: () => { },
      type: Object
    }
  },
  components: {
    AddUsersModal,
    vModal,
    PmButton
  },
  data: function () {
    return {
      newUser: {},
      buttonBlock:
        'border rounded-lg p-6 cursor-pointer h-96 flex flex-col justify-between bg-gray-200',
      buttonBlockTop: 'flex justify-between',
      buttonBlockTopCircle:
        'flex justify-between bg-brand-color rounded-full w-min p-10 box-content',
      buttonBlockSubtitle: 'text-xl text-gray-400',
      buttonBlockIcon: 'text-6xl text-brand-color',
      buttonBlockIconCircle: 'text-5xl text-brand-color text-white',
      buttonBlockGroupTitle: 'text-xl text-gray-400'
    };
  },
  computed: {
    hasApprovalsFlag() {
      return this.featureFlagHandler.allowedFeature['approvals'] || this.featureFlagHandler.isSuperAdmin;
    },
    hasTemplatesFlag() {
      return this.featureFlagHandler.allowedFeature['templates'] || this.featureFlagHandler.isSuperAdmin;
    },
    customSupportEmail() {
      const link = get(this.$store, 'state.auth.user.companies[0].customSupportLink', 'https://hello.profileme.app/support');
      return includes(link, '@') ? `mailto:${link}` : link;
    },
    hasWhiteLabel() {
      return get(this.$store, 'state.whiteLabel.hasWhiteLabel', false);
    },
  },
  methods: {
    afterAddingUser(newUser) {
      this.newUser = newUser;
      $vfm.hide('newUserModal');
      $vfm.show('addedUserModal');
    },
    goto(path) {
      this.$router.push({
        name: 'Main',
        params: { report: path }
      });
    },
    clickOnUserSuccess() {
      this.$router.push({
        path: `statistics/${`${this.newUser?.id}_${this.newUser?.company?.id
          }_${this.newUser?.person?.first_name?.split(' ').join('-')}`}`
      });
    }
  }
};
</script>

<style scoped>
i.text-white,
p.text-white {
  color: #fff;
}
</style>