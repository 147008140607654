<template>
  <div class="flex items-center">
    <i
      @click="() => onNextPageSelect(-1)"
      v-show="pageNumber != 0"
      class="
        flex
        items-center
        justify-center
        text-center
        font-bold
        fa-regular fa-chevron-left
        text-3xl text-brand-color
        p-4
        w-16
        h-16
      "
    ></i>

    <div
      @click="() => onPageSelect(item)"
      v-for="item in firstOptions"
      :key="item"
      :class="`text-2xl
              inline-block
              cursor-pointer
              bg-white
              border border-solid
              px-6
              py-4
              mt-2
               ${pageNumber === item ? 'text-brand-color' : 'text-gray-800'}`"
    >
      {{ item + 1 }}
    </div>
    <div
      v-show="split"
      class="text-brand-color text-2xl w-16 h-16 flex items-end justify-center"
    >
      ...
    </div>
    <div
      @click="() => onPageSelect(item)"
      v-for="item in secondOptions"
      :key="item"
      :class="`text-2xl
              inline-block
              cursor-pointer
              bg-white
              border border-solid
              px-6
              py-4
              mt-2
               ${pageNumber === item ? 'text-brand-color' : 'text-gray-800'}`"
    >
      {{ item + 1 }}
    </div>
    <i
      @click="() => onNextPageSelect(1)"
      v-show="Array(Math.ceil(totalItems / splitBy)).length > pageNumber + 1"
      class="
        flex
        items-center
        justify-center
        text-center
        font-bold
        fa-regular fa-chevron-right
        text-3xl text-brand-color
        p-4
        w-16
        h-16
      "
    ></i>
  </div>
</template>

<script>
export default {
  emits: ['pageSelected'],
  props: {
    splitBy: {
      type: Number,
      default: 10
    },
    totalItems: {
      type: Number,
      default: 0
    },
    maxOptions: {
      type: Number,
      default: 3
    },
    pageNumber: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      split: false,
      firstOptions: [],
      secondOptions: []
    };
  },
  methods: {
    onPageSelect(page) {
      this.$emit('pageSelected', page);
    },
    onNextPageSelect(amount) {
      this.$emit('pageSelected', this.pageNumber + amount);
    },
    managePages() {
      const items = Math.ceil(this.totalItems / this.splitBy);
      const arrayItems = [...Array(items).keys()];
      if (items > this.maxOptions + 1) {
        this.split = true;
        if (items - this.pageNumber < this.maxOptions) {
          this.firstOptions = [0];
          this.secondOptions = arrayItems.slice(
            items - 1 - this.maxOptions,
            arrayItems.length
          );
        } else {
          this.firstOptions = [
            ...arrayItems.slice(
              this.pageNumber,
              this.pageNumber + this.maxOptions - 1
            )
          ];
          this.secondOptions = [items - 1];
        }
      } else {
        this.split = false;
        this.firstOptions = arrayItems;
      }
    }
  },
  mounted() {
    this.managePages();
  },
  watch: {
    totalItems() {
      this.managePages();
    },
    pageNumber() {
      this.managePages();
    }
  }
};
</script>

