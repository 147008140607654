<template>
  <div class="flex align-center justify-around">
    <div
      :class="controlStyling()"
      v-show="optionalId || itemId"
      :title="optionalTooltip"
    >
      <i
        @click="handleChange('optional', optionalId || itemId, !optional)"
        class="w-16 text-center text-3xl md:text-2xl self-center relative cursor-pointer fa-solid"
        :class="{
          'fa-asterisk text-success': optional,
          'fa-asterisk text-gray-400': !optional
        }"
      ></i>
      <span class="hidden md:inline-block" v-if="detail">{{ optional ? 'Optional' : 'Required' }}</span>
    </div>
    <div
      :class="controlStyling()"
      v-show="showId || itemId"
      :title="showTooltip"
    >
      <i
        @click="handleChange('show', showId || itemId, !show)"
        class="fa-solid text-3xl md:text-2xl w-16 self-center text-center relative cursor-pointer"
        :class="{
          'fa-eye text-success': show,
          'fa-eye-slash text-gray-400': !show
        }"
      ></i>
      <span class="hidden md:inline-block" v-if="detail">{{ show ? 'Shown' : 'Hidden' }}</span>
    </div>
    <div
      :class="controlStyling(true)"
      v-show="editId || itemId"
      :title="editableTooltip"
    >
      <i
        @click="handleChange('edit', editId || itemId, !editable)"
        class="w-16 text-center text-3xl md:text-2xl self-center relative cursor-pointer bottom-0.5 fa-solid"
        :class="{
          'fa-lock-open text-success': editable,
          'fa-lock text-gray-400': !editable
        }"
      ></i>
      <span class="hidden md:inline-block" v-if="detail">{{ editable ? 'Editable' : 'Locked' }}</span>
    </div>
  </div>
</template>

<script>
import { getPermissionsValue, getFieldArray, stripFields } from '~/functions/permissions-functions';

export default {
  props: {
    permissions: {
      default: () => [],
      type: Array
    },
    itemId: {
      default: '',
      type: String
    },
    showId: {
      default: '',
      type: String
    },
    optionalId: {
      default: '',
      type: String
    },
    editId: {
      default: '',
      type: String
    },
    sortableId: {
      default: '',
      type: String
    },
    detail: {
      type: Boolean,
      default: false
    }
  },
  emits: ['updated'],
  methods: {
    handleChange(type, itemId, value) {
      getFieldArray(itemId).forEach(v => {
        this.$emit('updated', { type, value, id: stripFields(itemId) + v });
      }).value();
    },
    findPermissionValue(key, itemId, defaultValue) {
      return getFieldArray(itemId).reduce((acc, v) => getPermissionsValue(
        this.permissions,
        stripFields(itemId) + v,
        key,
        defaultValue
      ) && acc, true).value();
    },
    controlStyling(end) { return this.detail ? 'flex items-center justify-center md:justify-start py-5 px-2 border-l border-b flex-1' + (end ? ' border-r' : '') : ''; }
  },
  computed: {
    optional() {
      return this.findPermissionValue('optional', this.optionalId || this.itemId, true);
    },
    editable() {
      return this.findPermissionValue('edit', this.editId || this.itemId, true);
    },
    show() {
      return this.findPermissionValue('show', this.showId || this.itemId, true);
    },
    sortable() {
      return this.findPermissionValue('sortable', this.sortableId || this.itemId, true);
    },
    optionalTooltip() {
      return this.optional ? 'Field / Section is optional' : 'Field / Section is not optional';
    },
    editableTooltip() {
      return this.editable ? 'Field / Section can be edited by profile users' : 'Field / Section can not be edited by profile users';
    },
    showTooltip() {
      return this.show ? 'Field / Section is visible to profile users' : 'Field / Section is not visible to profile users';
    }
  }
};
</script>
