<template>
  <div class="flex flex-col">
    <div class="text-left p-12">
      <p class="text-3xl">{{ message }} </p>
    </div>

    <div class="w-64 px-12 pb-12">
      <pm-button
        @click="confirm"
        :danger="true"
        :background="'outline'"
      >Delete</pm-button>
    </div>
  </div>
</template>

<script>
import pmButton from '~/components/ui/pm-button.vue';
export default {
  components: { pmButton },
  emits: ['confirm', 'cancel'],
  props: {
    message: {
      type: String,
      default: 'Are you sure you want to delete item ?'
    },
    color: {
      type: String,
      default: 'red'
    }
  },
  methods: {
    confirm: function () {
      this.$emit('confirm', true);
      this.$emit('cancel', true);
    },
    cancel: function (e) {
      e.stopPropagation();
      this.$emit('cancel', true);
    }
  }
};
</script>

<style></style>