<template>
  <div v-if="!underConstruction">
    <router-view @setLoader="setLoader" />
    <div v-if="loading" class="fixed w-screen h-screen z-50 top-0">
      <v-loader></v-loader>
    </div>
  </div>
  <div
    v-else
    class="under-construction w-screen h-screen flex justify-center bg-brand-color"
  >
    <img
      v-if="whiteLabelProfileMe"
      src="/update.png"
      alt="Under construction"
      :style="{
        maxWidth: 'unset',
        height: 'auto'
      }"
    />
    <img
      v-else
      src="/update-non-branded.png"
      alt="Under construction"
      :style="{
        maxWidth: 'unset',
        height: 'auto'
      }"
    />
  </div>
</template>
<script>
import store from './store';
import axios from 'axios';
import './normalize.css';
import { sizedImage } from './functions/utils-functions';
import { DateTime } from 'luxon';
import vLoader from './components/ui/v-loader.vue';

const BASE_URL = process.env.VUE_APP_STRAPI_URL;
const UNDER_CONSTRUCTION = process.env.VUE_APP_UNDER_CONSTRUCTION;

const getOrCreateElement = (id, type = 'style') => {
  if (document.getElementById(id)) return document.getElementById(id);
  const elem = document.createElement(type);
  elem.id = id;
  return elem;
};

const updateWhiteLabel = () => {
  const cssWhiteLabel = getOrCreateElement('whiteLabel');
  const title = getOrCreateElement('title', 'title');
  cssWhiteLabel.innerHTML = store.state.whiteLabel.css;
  if (store?.state?.whiteLabel?.icon) {
    const favicon = getOrCreateElement('favicon');
    favicon.href = sizedImage('', store.state.whiteLabel.icon, 16);
  }
  title.innerHTML = `${store.state.whiteLabel.title} Hub`;
  const head = document.head || document.getElementsByTagName('head')[0];
  head.appendChild(cssWhiteLabel);
  head.appendChild(title);
};
export default {
  components: {
    vLoader
  },
  data() {
    return {
      loading: false,
      whiteLabelProfileMe: true
    };
  },
  async created() {
    if (store.state.whiteLabel.date != 0) {
      updateWhiteLabel();
    }
    const domain = new URL(window.document.URL);
    try {
      const whiteLabel = await axios({
        method: 'GET',
        url:
          `${BASE_URL}/white-labels?populate=*&filters[domain][$eq]=` +
          domain.hostname
      });

      if (whiteLabel.data.length) {
        this.whiteLabelProfileMe = !whiteLabel.data.length;
        const whiteLabelUpdated = DateTime.fromISO(
          whiteLabel.data[0].updatedAt
        ).toMillis();

        if (store.state.whiteLabel.date < whiteLabelUpdated) {
          store.commit('whiteLabel/setWhiteLabel', whiteLabel.data[0]);
          updateWhiteLabel();
        }
      } else {
        store.commit('whiteLabel/setWhiteLabel', undefined);
        const title = getOrCreateElement('title', 'title');
        title.innerHTML = `ProfileMe Hub`;
      }
    } catch (e) {
      console.log('No white label found: ', e.message);
    }
  },
  computed: {
    disableLoader() {
      if (this.$route.name == 'Login') {
        return true;
      }
      return false;
    },
    underConstruction() {
      return UNDER_CONSTRUCTION == 'true';
    }
  },
  watch: {
    disableLoader() {
      this.loading = false;
    }
  },
  methods: {
    setLoader(isLoading) {
      this.loading = isLoading;
    }
  }
};
</script>

<style>
:root {
  --text-gray: #2c3e50;
}

.text-brand-gray {
  color: --text-gray;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: --text-gray;
}
</style>
