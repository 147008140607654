<template>
  <div
    id="cropper-trigger"
    @click="open = true"
  >
    <slot name="trigger"></slot>
  </div>
  <div
    class="backsplash"
    v-if="open"
  >
    <div class="canvas bg-white py-8 px-4">
      <vue-cropper
        ref="cropper"
        :initialAspectRatio="aspect"
        :aspectRatio="aspect"
        :containerStyle="{ 'max-height': '60vh' }"
        :minCropBoxWidth="minWidth"
        :minCropBoxHeight="minHeight"
        :src="formatImageUrl(imgSrc)"
        :viewMode="2"
        :fixedBox="true"
      />

      <div class="flex py-4">
        <pm-button
          @click.prevent="closeDialog"
          background="outline"
        >
          Cancel
        </pm-button>
        <div
          v-show="!forceCrop"
          class="w-12"
        />
        <pm-button @click.prevent="cropImage"> Crop </pm-button>
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import pmButton from '~/components/ui/pm-button.vue';
import { formatImageUrl } from '~/functions/utils-functions';
export default {
  components: {
    VueCropper,
    pmButton
  },
  props: {
    modelValue: Object,
    forceCrop: {
      type: Boolean,
      default: false
    },
    aspect: {
      type: Number,
      default: NaN
    },
    minWidth: {
      type: Number,
      default: 256
    },
    minHeight: {
      type: Number,
      default: 256
    },
    maxWidth: {
      type: Number,
      default: 1280
    },
    maxHeight: {
      type: Number,
      default: 1280
    },
  },
  emits: ['update:modelValue', 'cropped'],
  data: function () {
    return {
      open: false
    };
  },
  computed: {
    imgSrc() {
      if (this.forceCrop) {
        return this.modelValue.tempOg || this.modelValue.tempUrl;
      }
      return this.modelValue.og || this.modelValue.url;
    }
  },
  methods: {
    formatImageUrl,
    loadXHR(url) {
      return new Promise((res) =>
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            return res(URL.createObjectURL(blob));
          })
      );
    },
    closeDialog(e) {
      if (e) {
        e?.stopPropagation();
      }
      this.$emit('update:modelValue', {
        ...this.modelValue,
        tempOg: undefined,
        tempUrl: undefined,
        tempId: undefined
      });
      this.open = false;
    },
    openDialog() {
      this.open = true;
    },
    createObjectURL(object) {
      return window.URL
        ? window.URL.createObjectURL(object)
        : window.webkitURL.createObjectURL(object);
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      const opt = {

      };
      this.cropImg = this.$refs.cropper.getCroppedCanvas(opt).toDataURL();
      this.$refs.cropper.getCroppedCanvas(opt).toBlob(async (blob) => {
        let ogImage = this.modelValue.og || this.modelValue.url;
        if (!ogImage.includes('http')) {
          ogImage = await this.loadXHR(this.formatImageUrl(ogImage));
        }
        this.$emit('update:modelValue', {
          ...this.modelValue,
          og: ogImage,
          id: this.modelValue.id,
          url: this.createObjectURL(blob)
        });
        this.$emit('cropped');
        this.open = false;
      });
    }
  },
  expose: ['openDialog']
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.backsplash {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30;
  position: fixed;
  background: #0505059e;
}

.canvas {
  position: fixed;
  top: 50%;
  left: 50%;
  box-shadow: 0 0 0 5px white;
  transform: translate(-50%, -50%);
  max-height: 90vh;
  width: 50vw;
}

@media screen and (max-width: 1400px) {
  .canvas {
    width: 60vw;
  }
}

@media screen and (max-width: 768px) {
  .canvas {
    width: 95vw;
  }
}

a[role='button'] {
  background: var(--brand-color);
  padding: 12px 8px;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 18px;
  text-decoration: none;
}
</style>
