<template>
  <div class="bg-white relative"><slot></slot></div>
</template>

<script>
export default {
  props: {
    noPadding: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    borderRound: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style scoped>
.gray-border {
  border: 4px solid #a9b1b3;
}
.round-border {
  border-radius: 3.25rem;
}
</style>
