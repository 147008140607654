<script setup>
import { VAceEditor } from 'vue3-ace-editor';
import {
  ref,
  watch,
  toRef
} from 'vue';
const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: { type: String, default: '' },
});

const modelValue = toRef(props, 'modelValue');

const current = ref(props.modelValue);
watch(current, (newValue) => {
  emit('change');
  emit('update:modelValue', newValue);
});
watch(modelValue, (newValue) => {
  current.value = newValue;
});
const change = () => {
  emit('change');
};
</script>

<template>
  <v-ace-editor
    @change="change"
    class="css-editor"
    v-model:value="current"
    lang="css"
    theme="chrome"
    style="height: 400px"
  />
</template>
<style>
.css-editor * {
  font-family: unset;
}
</style>