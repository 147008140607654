export const HISTOGRAM_DATE_FORMAT = 'MMM yyyy';
export const USERS_TABLE_PAGE_SIZE = 50;
export const LAZY_SEARCH_PAGE_SIZE = 15;
export const ROLE_IDS = {
  superadmin: 'superadmin',
  supervisor: 'supervisor',
  pmuser: 'user',
  authenticated: 'user'
};
// @primary-color@
// @secondary-color@
export default {
  HISTOGRAM_DATE_FORMAT,
  USERS_TABLE_PAGE_SIZE,
  LAZY_SEARCH_PAGE_SIZE,
  ROLE_IDS
};
