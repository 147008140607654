<template>
  <label class="switch">
    <input
      :name="name"
      type="checkbox"
      @change="onChange"
      :checked="modelValue"
    />
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  props: {
    modelValue: Boolean,
    name: String
  },
  emits: ['update:modelValue'],
  methods: {
    onChange: function (e) {
      this.$emit('update:modelValue', e.target.checked);
    }
  }
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  margin: auto 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: var(--brand-color);
}

input:focus+.slider {
  box-shadow: 0 0 1px var(--brand-color);
}

input:checked+.slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 14px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>