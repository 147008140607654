<template>
  <main class="main background overflow-hidden flex relative justify-center items-center w-ful py-12">
    <div class="container">
      <div class="flex flex-col justify-center items-center">
        <img
          :src="icon"
          alt="Profile Me Logo"
          width="120"
        />
        <h3 class="text-white text-4xl font-light mb-4 mt-4 text-center">UPDATE YOUR PASSWORD</h3>
        <h5 class="accent-color text-2xl font-light mb-2 mt-2 text-center">
          * Minimum 10 characters</h5>
        <h5 class="accent-color text-2xl font-light mb-12 mt-2 text-center">* At least 1 Uppercase, 1 Lowercase &amp; 1
          Number</h5>
      </div>
      <form @submit.prevent="handleSubmit">
        <div class="flex flex-col mt-8 relative border-b">
          <label
            class="text-white font-light opacity-60 text-2xl"
            for="password-input"
          >New Password</label>
          <div class="relative">
            <input
              required
              v-model="formData.password"
              @keydown="(e) => {
                  if (e.code == 'Space') return e.preventDefault();
                }
                "
              id="password-input"
              class="bg-transparent text-3xl text-white py-2 mt-2 w-4/5 rounded-none focus:outline-none"
              :type="showPassword ? 'text' : 'password'"
            />
            <!-- Show Password Button -->
            <span class="show-password">
              <span
                class="text-white font-bold"
                @click="showPassword = true"
                v-show="!showPassword"
              >SHOW</span>
              <span
                class="text-white font-bold"
                @click="showPassword = false"
                v-show="showPassword"
              >HIDE</span>
            </span>
          </div>
        </div>
        <div class="flex flex-col mt-8 relative border-b">
          <label
            class="text-white font-light opacity-60 text-2xl"
            for="password-input"
          >Confirm Password</label>
          <div class="relative">
            <input
              required
              v-model="formData.password2"
              id="password-input2"
              @keydown="(e) => {
                  if (e.code == 'Space') return e.preventDefault();
                }
                "
              class="bg-transparent text-3xl text-white py-2 mt-2 w-4/5 rounded-none focus:outline-none"
              :type="showPassword1 ? 'text' : 'password'"
            />
            <!-- Show Password Button -->
            <span class="show-password">
              <span
                class="text-white font-bold"
                @click="showPassword1 = true"
                v-show="!showPassword1"
              >SHOW</span>
              <span
                class="text-white font-bold"
                @click="showPassword1 = false"
                v-show="showPassword1"
              >HIDE</span>
            </span>
          </div>
        </div>
        <div
          v-show="canCancel"
          class="flex mt-6 text-2xl font-light"
        >
          <p class="text-white mr-2">Changed your mind?</p>
          <a
            @click="backToHome"
            class="text-white accent-color font-medium underline cursor-pointer"
          >Click Here</a>
        </div>

        <pm-button
          v-if="!loading"
          type="submit"
          background="solid-light"
          :gutters="true"
          size="small"
        >UPDATE PASSWORD</pm-button>
        <pm-button
          v-else
          background="solid-light"
          :gutters="true"
          size="small"
        >Please wait...</pm-button>
      </form>
    </div>
  </main>
</template>

<script>
import authService from '~/api/auth';
import pmButton from '~/components/ui/pm-button.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { userRole } from '~/functions/utils-functions';

export default {
  components: {
    pmButton
  },
  setup() {
    const store = useStore();
    return {
      jwt: sessionStorage.getItem('_token'),
      store,
      icon: computed(() =>
        store.state.whiteLabel.date
          ? store.state.whiteLabel.lightLogo
          : store.state.whiteLabel.lightIcon
      )
    };
  },

  data: function () {
    return {
      showPassword: false,
      showPassword1: false,
      loading: false,
      formData: {
        password: '',
        password2: ''
      }
    };
  },
  computed: {
    canCancel() {
      return localStorage.getItem('_c');
    }
  },
  methods: {
    backToHome() {
      return this.$router.push({
        name: 'Main',
        params: { report: 'home' },
        query: this.$route.query
      });
    },
    async handleSubmit() {
      if (this.formData.password !== this.formData.password2) {
        return this.$showError('Passwords do not match');
      }
      if (this.formData.password.trim().split('').length < 10) {
        return this.$showError('Password is too short');
      }

      if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/.test(this.formData.password)) {
        return this.$showError('Password is invalid');
      }
      this.loading = true;
      let result = {};
      if (this.$route.query.code) {
        result = await authService().resetForgottenPassword(
          this.formData.password.trim(),
          this.$route.query.code
        );
      } else if (this.jwt) {
        result = await authService(this.jwt).resetPassword(
          this.formData.password.trim()
        );
      }

      if (result.success) {
        this.$showSuccess('Password reset!');
        if (this.$route.query.code) {
          return this.$router.push({ name: 'Login', query: this.$route.query });
        }

        if (
          this.store.state.auth?.user?.profile ||
          userRole(this.store.state.auth.user) != 'user'
        ) {
          return this.$router.push({
            name: 'Main',
            params: { report: 'home' },
            query: this.$route.query
          });
        } else {
          return this.$router.push({
            path: '/self-service'
          });
        }
      }
      this.loading = false;
      return this.$showError(result.message);
    }
  }
};
</script>
<style scoped>
.background {
  background-color: var(--brand-dark-color);
  min-height: 100vh;
}

.container {
  width: 24%;
}

.accent-color {
  color: var(--brand-accent-color);
}

.show-password {
  position: absolute;
  top: 4px;
  padding: 5px;
  color: white;
  right: 12px;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .container {
    width: 30%;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    width: 40%;
  }
}

@media screen and (max-width: 800px) {
  .container {
    width: 50%;
  }
}

@media screen and (min-width: 768px) {
  .show-password {
    position: absolute;
    top: 8px;
    padding: 5px;
    color: white;
    right: 12px;
    cursor: pointer;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 80%;
  }
}

@media screen and (max-width: 350px) {
  .container {
    width: 90%;
  }
}
</style>
