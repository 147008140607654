import { filter } from 'lodash';

const fields = {
  // Personal Information
  'personalDetails.profileImage': {
    name: 'profilePhoto.url',
    title: 'Profile Photo',
    target: '#profile-picture',
    section: 'personal-details'
  },
  'personalDetails.fullName': {
    name: 'firstName',
    title: 'Full name',
    target: '#fullName',
    section: 'personal-details'
  },
  'personalDetails.firstName': {
    name: 'firstName',
    title: 'First name',
    target: '#firstName',
    section: 'personal-details'
  },
  'personalDetails.lastName': {
    name: 'lastName',
    title: 'Last name',
    target: '#lastName',
    section: 'personal-details'
  },
  'personalDetails.jobTitle': {
    name: 'jobTitle',
    title: 'Position/Subheading',
    target: '#jobtitle',
    section: 'personal-details'
  },
  'personalDetails.mainContactNumber': {
    name: 'mainContactNumber',
    title: 'Main contact number',
    target: '#contactnumber',
    section: 'personal-details'
  },
  'personalDetails.whatsappNumber': {
    name: 'whatsappNumber',
    title: 'Whatsapp number',
    target: '#contactnumber',
    section: 'personal-details'
  },
  'personalDetails.officeNumber': {
    name: 'officeNumber',
    title: 'Office number',
    target: '#contactnumber',
    section: 'personal-details'
  },
  'personalDetails.emailAddress': {
    name: 'emailAddress',
    title: 'Email address',
    target: '#emailaddress',
    section: 'personal-details'
  },
  'personalDetails.navigateToOffice': {
    name: 'navigateToOffice',
    title: 'Navigate to office',
    target: '#emailaddress',
    section: 'personal-details'
  },
  // Appearance
  'appearance.primaryColor': {
    name: 'primaryColor',
    title: 'Primary colour',
    target: '#primary-color',
    section: 'appearance'
  },
  'appearance.secondaryColor': {
    name: 'secondaryColor',
    title: 'Secondary colour',
    target: '#secondary-color',
    section: 'appearance'
  },
  'appearance.iconStyle': {
    name: 'iconStyle',
    title: 'Icon Style',
    target: '#secondary-color',
    section: 'appearance'
  },

  // Themes
  themes: {
    name: 'stylingId',
    title: 'Template',
    target: '#theme-selection',
    section: 'themes'
  },

  // Your Branding
  'branding.logo': {
    name: 'logo.url',
    title: 'Logo',
    target: '#company-logo',
    section: 'branding'
  },
  'branding.companyName': {
    name: 'brandingCompanyName',
    title: 'Commpany name',
    target: '#company-name',
    section: 'branding'
  },
  'branding.subdomain': {
    name: 'subdomain',
    title: 'Subdomain',
    target: '#company-name',
    section: 'branding'
  },
  'branding.coverImagesList': {
    name: 'banner',
    title: 'Cover images',
    target: '#company-name',
    section: 'branding',
    validation: (list) => list.length > 0
  },
  // Main Action Button
  'links.mainActionButtonTitle': {
    name: 'mainActionButton.title',
    title: 'Call to action title',
    target: '#cto',
    section: 'links'
  },
  'links.mainActionButtonUrl': {
    name: 'mainActionButton.url',
    title: 'Call to action url',
    target: '#cto',
    section: 'links'
  },
  'links.mainActionButtonIcons': {
    name: 'mainActionButton.icon',
    title: 'Call to action icon',
    target: '#cto',
    section: 'links'
  },

  // Testimonials
  'testimonials.groupReviews': {
    name: 'testimonials',
    title: 'Testimonial review',
    target: '#testimonials',
    section: 'testimonials',
    validation: (list) => filter(list, (item) => item.review).length === 0
  },
  'testimonials.groupAuthor': {
    name: 'testimonials',
    title: 'Testimonial author',
    target: '#testimonials',
    section: 'testimonials',
    validation: (list) => filter(list, (item) => item.author).length === 0
  },
  'testimonials.groupRating': {
    name: 'testimonials',
    title: 'Testimonial rating',
    target: '#testimonials',
    section: 'testimonials',
    validation: (list) => filter(list, (item) => item.rating).length === 0
  },
  // Contact Form
  'contactForm.sendToEmail': {
    name: 'sendToMail',
    title: 'Send to email',
    target: '#form-responses',
    section: 'contact-form'
  },
  'contactForm.sendToEmailCC': {
    name: 'sendToMailCC',
    title: 'Send to email cc',
    target: '#form-responses',
    section: 'contact-form'
  },
  'contactForm.thankYouMessage': {
    name: 'contactForm.thankYouMessage',
    title: 'Thank you message',
    target: '#form-responses',
    section: 'contact-form'
  }
};

export const getFieldDataFromItemId = (key) => {
  const validation = (val) => {
    return val ? true : false;
  };
  if (fields[key]) {
    return {
      ...fields[key],
      validation: fields[key].validation ? fields[key].validation : validation
    };
  } else {
    const permissionsElement = document.getElementById(key);
    if (permissionsElement)
      return {
        name: permissionsElement.getAttribute('fieldpath'),
        title: permissionsElement.getAttribute('grouptitle'),
        section: permissionsElement.getAttribute('section'),
        target: '#' + permissionsElement.id,
        validation
      };
    return key;
  }
};
