
import Quill from 'quill';
const Delta = Quill.import("delta");
const Clipboard = Quill.import("modules/clipboard");
const CodeBlock = Quill.import("formats/code");

const embedYoutubeUrl = 'https://www.youtube.com/embed/';
const ytRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/|shorts\/)|(watch\?))\??v?=?([^#&?]*).*/;
const imgExtensionRegEx = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/;
function fetchYouTubeID(url) {
  var match = url.match(ytRegex);
  return (match && match[7].length == 11) ? match[7] : false;
}
function convertYouTubeUrlToEmbed(url) {
  const ytId = fetchYouTubeID(url);
  return `${embedYoutubeUrl}${ytId}?autoplay=0&rel=0`;
}
function deltaEndsWith(delta, text) {
  let endText = '';
  for (let i = delta.ops.length - 1; i >= 0 && endText.length < text.length; --i // eslint-disable-line no-plusplus
  ) {
    const op = delta.ops[i];
    if (typeof op.insert !== 'string') break;
    endText = op.insert + endText;
  }
  return endText.slice(-1 * text.length) === text;
}
export default class SpecialClipboard extends Clipboard {
  convert(_ref2) {
    let {
      html,
      text
    } = _ref2;
    let formats = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    if (formats[CodeBlock.blotName]) {
      return new Delta().insert(text || '', {
        [CodeBlock.blotName]: formats[CodeBlock.blotName]
      });
    }
    if (!html) {
      if (imgExtensionRegEx.test(text)) {
        return new Delta().insert({
          image: text
        }, formats);
      } else if (ytRegex.test(text)) {
        const embedUrl = convertYouTubeUrlToEmbed(text);
        return new Delta().insert({
          video: embedUrl
        }, formats);
      }
      // need to check if yt-video or image then handle
      return new Delta().insert(text || '', formats);
    }
    const delta = this.convertHTML(html);
    // Remove trailing newline
    if (deltaEndsWith(delta, '\n') && (delta.ops[delta.ops.length - 1].attributes == null || formats.table)) {
      return delta.compose(new Delta().retain(delta.length() - 1).delete(1));
    }
    return delta;
  }
}
