<template>
  <div
    class="mb-12 border-b flex items-center relative"
    :itemId="itemId"
  >
    <input
      v-model="inputValue"
      :class="inputClasses"
      :type="type"
      :name="name"
      :id="name"
      :placeholder="placeholder || ' '"
      :maxlength="maxlength"
      @input="inputEvent"
    />
    <label
      :for="name"
      :class="[
        `floating-label ${placeholderClasses}`,
        { active: placeholder?.length }
      ]"
    >
      <slot></slot>
    </label>
    <permission-controls
      :permissions="permissions"
      :itemId="itemId"
      @updated="handlePermissionUpdate"
      v-show="mode == 'permissions' && mode !== 'hidden'"
    />
    <i
      v-show="mode != 'permissions' && mode !== 'hidden'"
      @click="focusElement(name)"
      class="fa-solid fa-pencil edit-icon"
    ></i>
  </div>
</template>

<script>
import permissionControls from '~/components/functional/permission-controls.vue';
export default {
  components: { permissionControls },
  emits: ['update:modelValue', 'updatePermissions', "change"],
  props: {
    modelValue: String,
    name: {
      type: String,
      default: ''
    },
    itemId: {
      type: String,
      default: ''
    },
    permissions: {
      type: Array,
      default: () => []
    },
    placeholderClasses: {
      type: String,
      default: ''
    },
    inputClasses: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'default'
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    maxlength: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      inputValue: this.modelValue
    };
  },
  methods: {
    handlePermissionUpdate(data) {
      this.$emit('updatePermissions', data);
    },
    focusElement(el) {
      document.getElementById(el).focus();
    },
    inputEvent() {
      this.$emit('update:modelValue', this.inputValue);
      this.$emit('change');
    }
  }
};
</script>
<style scoped>
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
</style>