<template>
  <div class="px-12 pt-12 md:px-16 md:pt-16 overflow-auto">
    <div style="max-height: 70vh" class="hidden sm:block">
      <table class="table-auto">
        <thead class="mb-12">
          <tr>
            <th
              @click="
                sorting =
                  sorting === SORTING.STATUS
                    ? SORTING.STATUS_REV
                    : SORTING.STATUS
              "
              class="text-left text-2xl text-brand-color pb-12"
            >
              <i
                class="fas mr-2 text-gray-800"
                :class="
                  sorting === SORTING.STATUS
                    ? 'fa-sort-alpha-down'
                    : sorting === SORTING.STATUS_REV
                    ? 'fa-sort-alpha-up-alt'
                    : 'fa-sort-alt'
                "
              />STATUS</th
            >
            <th
              @click="
                sorting =
                  sorting === SORTING.WHAT ? SORTING.WHAT_REV : SORTING.WHAT
              "
              class="text-left text-2xl text-brand-color pb-12"
            >
              <i
                class="fas mr-2 text-gray-800"
                :class="
                  sorting === SORTING.WHAT
                    ? 'fa-sort-alpha-down'
                    : sorting === SORTING.WHAT_REV
                    ? 'fa-sort-alpha-up-alt'
                    : 'fa-sort-alt'
                "
              />WHAT</th
            >
            <th
              class="
                text-left text-2xl text-brand-color
                pb-12
                hidden
                lg:table-cell
              "
              >OLD VALUE</th
            >
            <th class="text-left text-2xl text-brand-color pb-12">NEW VALUE</th>
          </tr>
        </thead>
        <tbody>
          <tr
            :class="`${
              index == changesSorted.length - 1
                ? ''
                : 'border-b border-b-gray-500'
            }`"
            v-for="(item, index) in changesSorted"
            :key="index"
          >
            <td
              :class="`${
                overflowIndex !== index ? 'py-2' : 'py-4'
              } pl-2 pr-24 text-2xl white-space-nowrap`"
            >
              {{
                item.actionType.charAt(0).toUpperCase() +
                item.actionType.slice(1)
              }}
            </td>
            <td
              :class="`${
                overflowIndex !== index ? 'py-2' : 'py-4'
              } text-brand-color text-2xl underline w-96 pr-24 white-space-nowrap`"
              >{{ item.fieldTitle }}</td
            >
            <td
              :class="`pr-24 text-2xl hidden lg:table-cell  ${
                overflowIndex !== index
                  ? 'value-max-width py-2'
                  : 'value-max-width-expanded py-4'
              } white-space-nowrap`"
              ><span
                :style="`overflow-wrap: ${
                  overflowIndex === index ? 'anywhere' : 'initial'
                } `"
                v-if="getValue(item, 'old')?.trim()"
                >{{
                  overflowIndex !== index
                    ? truncate(getValue(item, 'old')).value
                    : getValue(item, 'old') + ' ... '
                }}<span
                  v-show="truncate(getValue(item, 'old')).trimmed"
                  class="underline"
                  @click="
                    overflowIndex === index
                      ? (overflowIndex = false)
                      : (overflowIndex = index)
                  "
                  >{{
                    overflowIndex !== index ? 'Read More' : 'Read Less'
                  }}</span
                ></span
              ><span v-else class="italic text-gray-400"> No Value</span></td
            >
            <td
              :class="`${
                overflowIndex !== index
                  ? 'value-max-width py-2'
                  : 'value-max-width-expanded py-4'
              } text-2xl white-space-nowrap pr-12`"
              ><span
                :style="`overflow-wrap: ${
                  overflowIndex === index ? 'anywhere' : 'initial'
                } `"
                v-if="getValue(item, 'new')?.trim()"
                >{{
                  overflowIndex !== index
                    ? truncate(getValue(item, 'new')).value
                    : getValue(item, 'new') + ' ... '
                }}<span
                  v-show="truncate(getValue(item, 'new')).trimmed"
                  class="underline"
                  @click="
                    overflowIndex === index
                      ? (overflowIndex = false)
                      : (overflowIndex = index)
                  "
                  >{{
                    overflowIndex !== index ? 'Read More' : 'Read Less'
                  }}</span
                ></span
              ><span v-else class="italic text-gray-400"> No Value</span></td
            >
          </tr>
          <tr
            ><td class="pt-12 pr-24 white-space-nowrap" colspan="4"
              ><div v-show="changes.length > PAGE_SIZE"
                ><v-pagnate
                  :maxOptions="MAX_OPTIONS"
                  :pageNumber="currentPage"
                  :totalItems="changes.length"
                  :splitBy="PAGE_SIZE"
                  @pageSelected="(p) => navToNewPage(p)" /></div></td
          ></tr>
        </tbody>
      </table>
      <div class="h-12" />
    </div>
    <div style="height: 70vh" class="sm:hidden mt-8">
      <div class="flex flex-row mb-6">
        <div
          @click="
            sorting =
              sorting === SORTING.STATUS ? SORTING.STATUS_REV : SORTING.STATUS
          "
          class="text-left text-brand-color pb-12 mr-6"
        >
          <i
            class="fas mr-2 text-gray-800"
            :class="
              sorting === SORTING.STATUS
                ? 'fa-sort-alpha-down'
                : sorting === SORTING.STATUS_REV
                ? 'fa-sort-alpha-up-alt'
                : 'fa-sort-alt'
            "
          />STATUS</div
        >
        <div
          @click="
            sorting = sorting === SORTING.WHAT ? SORTING.WHAT_REV : SORTING.WHAT
          "
          class="text-left text-brand-color pb-12 mr-6"
        >
          <i
            class="fas mr-2 text-gray-800"
            :class="
              sorting === SORTING.WHAT
                ? 'fa-sort-alpha-down'
                : sorting === SORTING.WHAT_REV
                ? 'fa-sort-alpha-up-alt'
                : 'fa-sort-alt'
            "
          />WHAT</div
        >
        <div class="text-left text-brand-color pb-12 mr-6">OLD VALUE</div>
        <div class="text-left text-brand-color pb-12">NEW VALUE</div>
      </div>
      <div
        class="flex flex-col mb-8 shadow p-6"
        v-for="(item, index) in changesSorted"
        :key="index"
      >
        <div class="border-b font-bold border-b-gray-500 py-4">
          {{
            item.actionType.charAt(0).toUpperCase() + item.actionType.slice(1)
          }}</div
        >
        <div class="border-b border-b-gray-500 py-4">{{ item.fieldTitle }}</div>
        <div class="border-b border-b-gray-500 py-4 mxW-mobile">
          <span
            :style="`overflow-wrap: ${
              overflowIndex === index ? 'anywhere' : 'initial'
            } `"
            v-if="getValue(item, 'old')?.trim()"
            >{{
              overflowIndex !== index
                ? truncate(getValue(item, 'old'), 25).value
                : getValue(item, 'old') + ' ... '
            }}<span
              v-show="truncate(getValue(item, 'old'), 25).trimmed"
              class="underline"
              @click="
                overflowIndex === index
                  ? (overflowIndex = false)
                  : (overflowIndex = index)
              "
              >{{ overflowIndex !== index ? 'Read More' : 'Read Less' }}</span
            ></span
          ><span v-else class="italic text-gray-400"> No Value</span>
        </div>
        <div class="border-b border-b-gray-500 py-4 mxW-mobile">
          <span
            :style="`overflow-wrap: ${
              overflowIndex === index ? 'anywhere' : 'initial'
            } `"
            v-if="getValue(item, 'new')?.trim()"
            >{{
              overflowIndex !== index
                ? truncate(getValue(item, 'new'), 25).value
                : getValue(item, 'new') + ' ... '
            }}<span
              v-show="truncate(getValue(item, 'new'), 25).trimmed"
              class="underline"
              @click="
                overflowIndex === index
                  ? (overflowIndex = false)
                  : (overflowIndex = index)
              "
              >{{ overflowIndex !== index ? 'Read More' : 'Read Less' }}</span
            ></span
          ><span v-else class="italic text-gray-400"> No Value</span>
        </div>
      </div>
      <div class="my-12"
        ><div v-show="changes.length > PAGE_SIZE"
          ><v-pagnate
            :pageNumber="currentPage"
            :totalItems="changes.length"
            :splitBy="PAGE_SIZE"
            :maxOptions="MAX_OPTIONS"
            @pageSelected="(p) => navToNewPage(p)"
        /></div>
      </div>
      <div class="h-12" />
    </div>
  </div>
</template>

<script>
import { get, isArray, isObject } from 'lodash';
import VPagnate from './v-pagnate.vue';
const SORTING = {
  STATUS: 'STATUS',
  STATUS_REV: 'STATUS_REV',
  WHAT: 'WHAT',
  WHAT_REV: 'WHAT_REV'
};
const PAGE_SIZE = 7;
const MAX_OPTIONS = 4;
export default {
  components: {
    VPagnate
  },
  data() {
    return {
      SORTING,
      sorting: '',
      PAGE_SIZE,
      MAX_OPTIONS,
      overflowIndex: false,
      currentPage: 0
    };
  },
  props: {
    changes: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    changesSorted() {
      const all_changes = [...this.allChanges];
      if (this.sorting === SORTING.STATUS) {
        all_changes.sort((a, b) => a.actionType.localeCompare(b.actionType));
      } else if (this.sorting === SORTING.STATUS_REV) {
        all_changes.sort((a, b) => b.actionType.localeCompare(a.actionType));
      } else if (this.sorting === SORTING.WHAT) {
        all_changes.sort((a, b) => a.fieldTitle.localeCompare(b.fieldTitle));
      } else if (this.sorting === SORTING.WHAT_REV) {
        all_changes.sort((a, b) => b.fieldTitle.localeCompare(a.fieldTitle));
      }
      return all_changes;
    },
    allChanges() {
      const changesPerPage = isArray(this.changes) ? [...this.changes] : [];
      return changesPerPage.slice(
        this.currentPage * PAGE_SIZE,
        this.currentPage * PAGE_SIZE + PAGE_SIZE
      );
    }
  },
  methods: {
    navToNewPage(p) {
      this.overflowIndex = false;
      this.currentPage = p;
    },
    truncate(input, length = 35) {
      if (input.length > length) {
        return { trimmed: true, value: input.substring(0, length) + ' ... ' };
      }
      return { trimmed: false, value: input };
    },
    getValue(item, version) {
      if (version === 'old') {
        if (isObject(item.oldValue)) {
          if (get(item, 'oldValue.url', '').toString().trim()) {
            return get(item, 'oldValue.url').toString();
          }
          if (get(item, 'oldValue.value', '').toString().trim()) {
            return get(item, 'oldValue.value').toString();
          }
          if (get(item, 'oldValue.title', '').toString().trim()) {
            return get(item, 'oldValue.title').toString();
          }
          if (get(item, 'oldValue.description', '').toString().trim()) {
            return get(item, 'oldValue.description').toString();
          }
          if (get(item, 'oldValue.review', '').toString().trim()) {
            return get(item, 'oldValue.review').toString();
          }
          if (get(item, 'oldValue.name', '').toString().trim()) {
            return get(item, 'oldValue.name').toString();
          }
          return '';
        } else {
          return item.oldValue?.toString();
        }
      }
      if (version === 'new') {
        if (isObject(item.newValue)) {
          if (get(item, 'newValue.url', '').toString().trim()) {
            return get(item, 'newValue.url').toString();
          }
          if (get(item, 'newValue.value', '').toString().trim()) {
            return get(item, 'newValue.value').toString();
          }
          if (get(item, 'newValue.title', '').toString().trim()) {
            return get(item, 'newValue.title').toString();
          }
          if (get(item, 'newValue.description', '').toString().trim()) {
            return get(item, 'newValue.description').toString();
          }
          if (get(item, 'newValue.review', '').toString().trim()) {
            return get(item, 'newValue.review').toString();
          }
          if (get(item, 'newValue.name', '').toString().trim()) {
            return get(item, 'newValue.name').toString();
          }
          return '';
        } else {
          return item.newValue?.toString();
        }
      }
      return '';
    }
  }
};
</script>

<style lang="css" scoped>
.value-max-width {
  max-width: 450px;
  min-width: 350px;
}
.value-max-width-expanded {
  max-width: 400px;
  min-width: 300px;
}
.mxW-mobile {
  max-width: 250px;
}
</style>