<template>
  <div @click="handleContainerClick">
    <slot
      :open="openModal"
      name="trigger"
    ></slot>
    <vue-final-modal
      classes="modal-container"
      @close="handleClose"
      @open="handleOpen"
      v-model="showModal"
      v-slot="{ close }"
      :fit-parent="false"
      :name="modalName"
      :esc-to-close="preventClose"
      :content-class="classes"
    >
      <div class="bg-white flex flex-col relative">
        <div
          v-if="title || extraTitle"
          class="bg-gray-200 z-10 p-8 text-2xl flex justify-between items-center sticky top-0"
        ><span class="font-bold text-3xl text-black">{{ title }}
            <span class="font-normal text-3xl ml-2 text-gray-500">{{ extraTitle }}</span><span
              v-show="showColon"
              class="font-bold ml-1 text-3xl text-black"
            >:</span></span>
          <div
            v-show="!hideClose"
            @click="() => { close(); handleClose(); }"
            class="ml-8 cursor-pointer rounded-full flex justify-center items-center p-2 bg-white w-10 h-10"
          ><i class="fa-solid fa-xmark relative text-gray-400 text-3xl"></i></div>
        </div>
        <div class="content">
          <slot
            name="content"
            :close="closeModal"
          ></slot>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal';
export default {
  components: {
    VueFinalModal
  },
  props: {
    hideClose: {
      type: Boolean,
      default: false
    },
    preventClose: {
      type: Boolean,
      default: false
    },
    closeOnOutsideClick: {
      type: Boolean,
      default: false
    },
    showColon: {
      type: Boolean,
      default: false
    },
    modalName: {
      type: String,
      default: 'modal-wrapper'
    },
    title: {
      default: '',
      type: String
    },
    extraTitle: {
      default: '',
      type: String
    },
    classes: {
      default: 'modal-content',
      type: String
    }
  },
  data: function () {
    return { showModal: false };
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    handleClose() {
      this.$emit('close');
    },
    handleOpen() {
      this.$emit('open');
    },
    handleContainerClick(e) {
      if (e) e?.stopPropagation();
      if (
        this.closeOnOutsideClick &&
        e.target.classList.contains('vfm__content')
      )
        this.closeModal();
    },
    openModal: function (e) {
      e?.stopPropagation();
      this.showModal = true;
    }
  },
  emits: ['close', 'open']
};
</script>

<style>
.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content-compressed {
  min-width: 50%;
  max-width: 60%;
  max-height: 80%;
  overflow: auto;
}

.modal-content {
  width: 95%;
  max-height: 90%;
  overflow: auto;
}

@media screen and (min-width: 760px) {
  .modal-content {
    width: auto;
    min-width: 60%;
    max-width: 95%;
    max-height: 80%;
    overflow: auto;
  }
}

@media screen and (max-width: 1200px) {
  .modal-content-compressed {
    min-width: 50%;
    max-width: 70%;
    max-height: 80%;
    overflow: auto;
  }
}

@media screen and (max-width: 760px) {
  .modal-content-compressed {
    min-width: 50%;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
  }
}

.modal-content-small {
  min-width: 25%;
  max-width: 95%;
}

.modal-content-full {
  width: 100%;
}

div.default-overflow {
  overflow: visible;
}
</style>
