import './toast-style.css';
export default {
  install: (
    app,
    options = {
      timeout: 6000,
      id: 'cstm-toast',
      mountTo: '#app'
    }
  ) => {
    Element.prototype.remove = function () {
      this.parentElement.removeChild(this);
    };
    NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
      for (var i = this.length - 1; i >= 0; i--) {
        if (this[i] && this[i].parentElement) {
          this[i].parentElement.removeChild(this[i]);
        }
      }
    };
    /* declare global component */
    function buildComp(success, message) {
      clearComp();
      const container = document.createElement('div');
      container.id = options.id;
      container.classList.add('toast_container');

      const body = document.getElementById('app');
      body.append(container);

      const header = document.createElement('div');
      header.classList.add('toast_header');
      const headerText = document.createElement('div');

      headerText.classList.add('toast_header__text');
      if (success) {
        headerText.innerText = 'Success';
        header.classList.add('toast_header__success');
      } else {
        headerText.innerText = 'Error';
        header.classList.add('toast_header__error');
      }

      const close = document.createElement('div');
      close.innerText = 'X';
      close.onclick = clearComp;
      close.classList.add('toast-close');

      header.append(headerText, close);

      container.append(header);

      const content = document.createElement('div');
      content.innerText = message;
      content.classList.add('toast_body');

      container.append(content);

      showComp();
    }
    function clearComp() {
      if (document.getElementById(options.id)) {
        document.getElementById(options.id).remove();
      }
    }
    function showComp() {
      setTimeout(() => {
        clearComp();
      }, options.timeout);
    }

    app.config.globalProperties.$toast = ({
      success = true,
      message = ''
    }) => {
      buildComp(success, message);
    };
    app.config.globalProperties.$showSuccess = (message) => {
      buildComp(true, message);
    };
    app.config.globalProperties.$showError = (message) => {
      buildComp(false, message);
    };
  }
};
