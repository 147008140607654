import { reactive } from 'vue';

const popupsParams = ['infoPopups', null, 'key', reactive];
const linksParams = ['links', null, 'key', reactive];
const socialMediaParams = ['socialLinks', null, 'key', reactive];
export {
  popupsParams,
  linksParams,
  socialMediaParams
};
