export const titles = {
  statistics: `{profile_picture}<div class="text-gray-900 align-middle title-grid">{type} stats overview {for} {time} {users_total}</div>`,
  users: 'Users {users_total}',
  home: '<span class="text-brand-color">What would you like to do ?</span>',
  approvals: 'Approvals {approvals_total}',
  permissions: 'Templates Permissions',
  templates: 'Templates {templates_total}',
  'self-service': '',
  'manage-templates': ''
};
export const links = [
  {
    label: 'Home',
    path: 'home',
    icon: 'far fa-home-alt'
  },
  {
    label: 'Approvals',
    path: 'approvals',
    icon: 'fa-light fa-circle-check'
  },
  {
    label: 'Statistics',
    path: 'statistics',
    icon: 'fa-regular fa-wave-pulse'
  },
  {
    label: 'Users',
    path: 'users',
    icon: 'far fa-user',
    altPath: ['self-service']
  },
  {
    label: 'Templates',
    path: 'templates',
    icon: 'fa-light fa-chart-tree-map',
    altPath: ['manage-templates']
  },
  {
    label: 'Profile',
    path: `self-service`,
    icon: 'far fa-edit',
    altPath: ['users']
  }
];
