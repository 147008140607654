import { ref } from '@vue/reactivity';

import { reduce, forEach, fill, indexOf, every } from 'lodash';
import { count } from '~/functions/data-functions';

export default function ({ reports, onComplete = () => { }, onStart = () => { } }) {
  const loadedStates = fill(Array(count(reports)), false);

  const dataRefs = reduce(
    reports,
    (acc, cur) => {
      acc[cur.report] = ref(cur.default ? cur.default() : []);
      return acc;
    },
    {}
  );

  const apiFunctions = reduce(
    reports,
    (acc, cur) => {
      acc[cur.report] = (parameters) =>
        cur.apiFunction(parameters, dataRefs[cur.report]);
      return acc;
    },
    {}
  );

  const formatters = reduce(
    reports,
    (acc, cur) => {
      if (cur.format) acc[cur.report] = cur.format;
      return acc;
    },
    {}
  );

  const queryFormatters = reduce(
    reports,
    (acc, cur) => {
      if (cur.formatQuery) acc[cur.report] = cur.formatQuery;
      return acc;
    },
    {}
  );
  const onError = reduce(
    reports,
    (acc, cur) => {
      if (cur.onError) acc[cur.report] = cur.onError;
      return acc;
    },
    {}
  );
  const loadOnce = reduce(
    reports,
    (acc, cur) => {
      if (cur.loadOnce) acc[cur.report] = 0;
      return acc;
    },
    {}
  );

  const fetchData = (query, callback = () => { }, errorCallback = () => { }) => {
    fill(loadedStates, false);
    const trackLoadedStates = () => {
      loadedStates[indexOf(loadedStates, false)] = true;
      if (every(loadedStates, Boolean)) onComplete();
    };
    const format = (data, formatter) => (formatter ? formatter(data) : data);
    const formatQuery = (query, formatter) =>
      formatter ? formatter(query) : query;

    forEach(apiFunctions, (getData, key) => {
      if (
        loadOnce[key] == undefined ||
        (loadOnce[key] != undefined && loadOnce[key] != 1)
      ) {
        onStart(query);
        getData({
          query: formatQuery(query, queryFormatters[key]),
          callback: (data) => {
            dataRefs[key].value = format(data, formatters[key]);
            trackLoadedStates();
            callback(dataRefs[key].value);
          },
          catchCallback: () => {
            if (onError[key] && typeof onError[key] == 'function') {
              onError[key](dataRefs[key]);
              errorCallback(onError[key](dataRefs[key]));
            }
            trackLoadedStates();
          }
        });
        if (loadOnce[key] != undefined) loadOnce[key]++;
      } else {
        trackLoadedStates();
      }
    });
  };

  return {
    ...dataRefs,
    dataRefs,
    fetchData
  };
}
