const selfServiceSchema = () => ({
  userId: '',
  userAccountEmail: '',
  company: {},
  profilePhoto: {
    id: null,
    url: ''
  },
  isMaster: false,
  masterProfile: {},
  firstName: '',
  lastName: '',
  subscriptionType: 'lite',
  jobTitle: '',
  mainActionButton: {
    title: '',
    url: '',
    key: 'main-action-button',
    icon: ''
  },
  iconStyle: 'regular',
  navigateToOffice: '',
  mainContactNumber: '',
  whatsappNumber: '',
  emailAddress: '',
  aboutMeVideo: '',
  logo: {
    id: null,
    url: ''
  },
  billing: {
    status: '',
    token: '',
    billingDate: '',
    amountGross: '',
    provider: ''
  },
  stylingId: undefined,
  companyName: '',
  subdomain: '',
  officeNumber: '',
  banner: [],
  banners: [],
  mobileBanner: [],
  desktopBanner: [],
  links: [],
  domain: {},
  imageGallery: [],
  videoGallery: [],
  socialLinks: [],
  qrCodeData: null,
  profileAlias: '',
  companyId: '',
  companyLogo: { url: '' },
  contactForm: {
    id: null,
    formId: '',
    enabled: false,
    name: { required: true, enabled: false },
    emailAddress: { required: true, enabled: false },
    mobile: { required: false, enabled: false },
    message: { required: true, enabled: false },
    sendToMail: '',
    thankYouMessage: ''
  },
  limits: {
    imageGallery: 3,
    banner: 5
  },
  testimonials: [],
  cssVariables: {},
  variableChangeRecords: {}
});

module.exports = { selfServiceSchema };
