import {
  defaultTo,
  reduce,
  values,
  keys,
  curry,
  filter,
  map,
  get,
  sum,
  chain,
  isUndefined,
  isNull,
  isEmpty,
  isString,
  isArray
} from 'lodash';
import { getMonthsBetweenDates } from '~/functions/date-functions';
import { joinSansUndefined } from '~/functions/utils-functions';

const facadeApiCall = ({ callback = () => {} }) => callback([]);
const facadeApiCallWithDefault =
  (withDefault) =>
  ({ callback = () => {} }) =>
    callback(withDefault);

const noData = () => ({
  count: 0,
  data: [],
  labels: []
});

const count = (data) => data && defaultTo(data.length, 0);

const formatApprovalData = (approval) => {
  const template = () => {
    if (
      approval?.targetProfile?.profile_styling != null &&
      approval?.targetProfile?.profile_styling != false
    ) {
      return [approval?.targetProfile?.profile_styling];
    } else if (
      approval?.targetProfile?.masterProfile?.profile_styling != false &&
      approval?.targetProfile?.masterProfile?.profile_styling != null
    ) {
      return [approval?.targetProfile?.masterProfile?.profile_styling];
    } else {
      return [];
    }
  };

  const genUrl = () => {
    if (approval.targetProfile) {
      if (approval.targetProfile.hosting_site) {
        if (approval.targetProfile.hosting_site.siteId) {
          return `https://${get(
            approval,
            'targetProfile.hosting_site.domainAlias',
            ''
          )}/${get(approval, 'targetProfile.profileAlias', '')}`;
        }
      }
    }
    return 'No Url';
  };

  return {
    id: get(approval, 'profileId'),
    fullProfile: approval.targetProfile,
    approvalId: get(approval, 'id'),
    publishedAt: get(approval, 'requestedAt', null),
    fullName: joinSansUndefined(
      [
        get(approval, 'targetProfile.person.first_name', ''),
        get(approval, 'targetProfile.person.last_name', '')
      ],
      ' '
    ),
    profileChanges: get(approval, 'profileChanges', []),
    isMaster: false,
    stylingId: get(approval, 'targetProfile.profile_styling.id', null),
    template: template(),
    alias: get(approval, 'targetProfile.profileAlias'),
    approved: get(approval, 'approved', null),
    companyName: get(approval, 'company.title'),
    companyId: get(approval, 'company.id', ''),
    url: genUrl(),
    jobTitle: get(approval, 'targetProfile.biography.job_title', ''),
    profilePicture: get(
      approval,
      'targetProfile.person.profile_gallery[0].url',
      get(approval, 'image')
    ),
    profileThumbnail: get(
      approval,
      'targetProfile.person.profile_gallery[0].formats.thumbnail.url',
      get(approval, 'image')
    )
  };
};

const chartReducer = (formatKey, acc, entry) => {
  if (acc[formatKey(entry)]) acc[formatKey(entry)]++;
  else acc[formatKey(entry)] = 1;
  return acc;
};

const aggregateData = (
  data,
  key,
  formatKey = (value) => value,
  filterData = () => true
) => {
  const filteredData = filter(data, filterData);
  const r = curry(chartReducer)((entry) => formatKey(entry[key], entry));
  return reduce(filteredData, r, {});
};

const formatArrayForHistogram = (
  dateRange,
  data,
  dataKey = 'data',
  labelKey = 'label'
) => {
  const obj = reduce(
    data,
    (acc, cur) => {
      acc[cur[labelKey]] = cur[dataKey];
      return acc;
    },
    {}
  );
  const allMonths = getMonthsBetweenDates(
    'MMM yy',
    dateRange.start,
    dateRange.end
  );

  const dataWithPaddedDates = map(allMonths, (month) =>
    obj[month] ? obj[month] : 0
  );
  return {
    count: allMonths.length,
    data: dataWithPaddedDates,
    labels: allMonths
  };
};

const formatCollectionForChart = (data) => {
  return {
    count: count(values(data)),
    data: map(data),
    labels: keys(data)
  };
};

const formatArrayForChart = (data, dataKey = 'data', labelKey = 'label') => {
  return {
    count: count(data),
    data: map(data, dataKey),
    labels: map(data, labelKey)
  };
};

const convertCountToPercentages = (data) => {
  const total = sum(data);
  return map(
    map(data, (count) => Math.floor((count / total) * 100)),
    (per) => (per == 0 ? '<1' : per)
  );
};

const shortenNumbers = (numbers) => {
  if (numbers >= 1000) return Math.floor(numbers / 100) / 10 + 'K';
  if (numbers >= 1000000) return Math.floor(numbers / 100000) / 10 + 'M';
  return numbers;
};

const sanitize = (obj) =>
  chain(obj)
    .omitBy(isUndefined)
    .omitBy(isNull)
    .omitBy((val) => isEmpty(val) && (isString(val) || isArray(val)))
    .value();

export {
  count,
  noData,
  aggregateData,
  chartReducer,
  formatCollectionForChart,
  formatArrayForChart,
  formatArrayForHistogram,
  facadeApiCall,
  facadeApiCallWithDefault,
  formatApprovalData,
  convertCountToPercentages,
  shortenNumbers,
  sanitize
};
