import AuthPage from '../views/auth/auth-page.vue';
import RegisterPage from '../views/auth/register-page.vue';
import base from '../views/base.vue';
import ResetPasswordPage from '../views/auth/reset-password-page.vue';
import VerifyGooglePage from '../views/auth/verify-google-page.vue';
// import HomePage from '../views/home-page.vue';
import auth from '../middleware/auth.middleware';
import guest from '../middleware/guest.middleware';
import setup from '../middleware/setup.middleware';

// App Routes
const routes = [
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: HomePage
  // },
  {
    path: '/sign-in',
    name: 'Login',
    component: AuthPage,
    meta: {
      middleware: [guest]
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterPage,
    meta: {
      middleware: [setup, guest]
    }
  },
  {
    path: '/authenticate/google',
    name: 'AuthGoogle',
    component: VerifyGooglePage,
    meta: {
      middleware: [setup, guest]
    }
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: ResetPasswordPage
  },
  {
    path: '/:report',
    name: 'Main',
    component: base,
    children: [
      {
        path: ':alias',
        name: 'Main',
        component: base,
        meta: {
          middleware: [setup, auth]
        }
      }
    ],
    meta: {
      middleware: [setup, auth]
    }
  },
  { path: '/', redirect: '/home' }
];

export default routes;
