<template>
  <i
    @click="() => onChange(false)"
    v-if="modelValue"
    class="fa-solid fa-eye text-3xl md:text-2xl cursor-pointer text-brand-color"
  ></i>
  <i
    v-else
    @click="() => onChange(true)"
    class="fa-solid fa-eye-slash text-2xl cursor-pointer"
  ></i>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'on-change'],
  methods: {
    onChange: function (value) {
      if (!this.disabled) {
        this.$emit('update:modelValue', value);
        this.$emit('on-change', value);
      }
    }
  }
};
</script>

