<template>
  <div class="pie-chart" :class="chartStyling">
    <div class="w-3/4 md:w-2/3 mb-5 md:mb-0 relative">
      <canvas ref="pieChart" height="500"></canvas>
      <span
        v-if="title"
        class="h-max w-min text-4xl text-center absolute m-auto inset-0 xs:text-5xl sm:text-3xl"
        v-html="getTitle(title, getMax)"
      >
      </span>
    </div>
    <div
      class="labels-height flex flex-wrap justify-center md:ml-10 md:w-1/3 md:flex-col"
    >
      <div
        v-for="(label, index) in labels"
        :key="index"
        class="flex items-start mb-2 ml-4 md:ml-0"
      >
        <i
          class="fas fa-circle leading-8"
          :style="`color:${colors[index]}`"
        ></i>
        <span class="pl-2 md:pl-5 text-2xl leading-8">{{ label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getColors,
  getPieConfig,
  formatPieChartTitle,
  chartProps,
  updatePieChart,
  chartStyling,
  sortLabelsAndWithData
} from '~/functions/chart-functions';
import { sum, take, takeRight } from 'lodash';
import { Chart, ArcElement, PieController, Tooltip } from 'chart.js';
import { hexToRgb } from '~/functions/utils-functions';
import store from '~/store';
Chart.register(ArcElement, PieController, Tooltip);

/* eslint-disable no-unused-vars */
const limitChartData = (data, count) => {
  const sortedData = sortLabelsAndWithData(data.data, data.labels);
  return {
    labels: [...take(sortedData.labels, count), 'Other'],
    data: [
      ...take(sortedData.data, count),
      sum(takeRight(sortedData.data, sortedData.data.length - count))
    ],
    count
  };
};

export default {
  props: chartProps(),
  mounted() {
    this.initPieGraph(this.$refs.pieChart);
  },
  data() {
    const limitData = limitChartData(this.chartData, this.limit);
    const brandColor = store.state.whiteLabel?.brandColor
      ? hexToRgb(store.state.whiteLabel.brandColor)
      : undefined;
    return {
      chart: null,
      chartStyling: chartStyling(),
      config: getPieConfig(
        limitData.labels,
        limitData.data,
        getColors(this.limit + 1, brandColor)
      ),
      brandColor
    };
  },
  watch: {
    chartData(newData) {
      const limitData = limitChartData(newData, this.limit);
      updatePieChart(this.config, this.chart, limitData, this.brandColor);
      this.initPieGraph(this.$refs.pieChart);
    }
  },
  computed: {
    labels() {
      return this.config.data.labels;
    },
    colors() {
      return this.config.data.datasets[0].backgroundColor;
    },
    getMax() {
      return sum(this.config.data.datasets[0].data);
    }
  },
  methods: {
    initPieGraph(ctx) {
      try {
        this.chart = new Chart(ctx, this.config);
      } catch (error) {
        console.log('Retrying pie graph');
        setTimeout(() => this.initPieGraph(ctx), 100);
      }
    },
    getTitle: formatPieChartTitle
  }
};
</script>
<style>
.pie-chart div div:last-child span {
  color: #777;
}
@media screen and (min-width: 768px) {
  .labels-height {
    height: 300px;
  }
}
/* Hide scrollbar for Chrome,
Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
