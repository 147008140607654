<template>
  <div class="bg-white h-full">
    <div class="
        bg-brand-color
        flex
        relative
        w-full
        h-full
        items-center
        justify-start
        py-12
        z-10
        sticky
        top-0
        px-10
        md:px-16
        text-4xl text-white
      "><i
        @click="goToPrevRoute"
        class="
          fa-solid fa-arrow-left-long
          mr-6
          hidden
          md:inline
          text-white
          font-light
          cursor-pointer
          text-5xl
        "
      ></i>
      <i
        @click="goBack"
        class="
          fa-solid fa-arrow-left-long
          mr-6
          inline
          md:hidden
          text-white
          font-light
          cursor-pointer
          text-5xl
        "
      ></i>
      {{ activePageName }}
    </div>

    <div class="h-4 bg-gray-100" />

    <div
      style="height: 100vh"
      class="flex"
    >
      <div :class="`px-8 sidepanel ${!path ? 'block' : 'hidden'
              } md:block md:border-r`">
        <div
          v-show="shouldShowBilling"
          @click="goToHash('#current-subscription')"
          :class="`
 
          flex
          justify-between
          items-center
          cursor-pointer
          px-6
          py-12
          md:px-16
          w-full
        `"
        >
          <p class="text-4xl text-black">Manage Billing</p>
          <i class="fa-solid fa-chevron-right text-black text-4xl"></i>
        </div>
        <div
          @click="goToResetPassword"
          :class="`
          flex
          justify-between
          items-center
          cursor-pointer
          px-6
          md:px-16
          py-12
          border-b
          w-full
          ${shouldShowBilling ? 'border-t' : ''}
        `"
        >
          <p class="text-4xl text-black">Change Password</p>
          <i class="fa-solid fa-chevron-right text-black text-4xl"></i>
        </div>
        <div
          v-show="shouldShowTrainingVideo && isUser"
          @click="goToTrainingVideo"
          :class="`
          flex
          justify-between
          items-center
          cursor-pointer
          px-6
          md:px-16
          py-12
          border-b
          w-full
          
        `"
        >
          <p class="text-4xl text-black">Training Video</p>
          <i class="fa-solid fa-chevron-right text-black text-4xl"></i>
        </div>
        <div class="my-12 ml-14 items-center flex w-96">
          <pm-button
            background="outline"
            @click="logoutUser"
          >
            Log Out
          </pm-button>
        </div>
      </div>
      <component
        v-if="path"
        class="flex-1"
        @mountSelfServiceProfile="fetchSelfServiceProfile"
        :selfServiceProfile="profile"
        :selfServiceApi="selfService"
        :profileId="profileId"
        :is="views[path]"
      ></component>
      <div
        v-else
        class="hidden md:block md:flex-1"
      >
        <support-page />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/reactivity';
import { useRouter, useRoute } from 'vue-router';
import userState from '~/store/state-managers/user';
import { titleCase, doesRequireBilling } from '~/functions/utils-functions';
import deleteAccountPage from '~/views/settings/delete-account-page.vue';
import faqPage from '~/views/settings/faq-page.vue';
import legalPage from '~/views/settings/legal-page.vue';
import emailNotificationPage from '~/views/settings/email-notifications-page.vue';
import supportPage from '~/views/settings/support-page.vue';
import { useStore } from 'vuex';
import selfServiceApi from '~/api/self-service-v4';
import pmButton from '~/components/ui/pm-button.vue';
import { onMounted } from '@vue/runtime-core';
import useCompany from '~/functions/setup/useCompany';
import useSelfService from '~/functions/setup/useSelfService';

export default {
  emits: ['close'],
  components: {
    pmButton,
    'delete-account-page': deleteAccountPage,
    'faq-page': faqPage,
    'email-notifications-page': emailNotificationPage,
    'support-page': supportPage,
    'legal-page': legalPage
  },
  setup(props, { emit }) {
    const { isUser, getProfileId, userCompanyId } = userState();
    const router = useRouter();
    const route = useRoute();
    const path = computed(() => route.hash);
    const store = useStore();
    const { trainingVideo, fetchTrainingVideo } = useCompany();
    const {
      fetchSelfServiceProfile: fetchProfile,
      selfServiceProfile: profile
    } = useSelfService(() => {
      // ignore
    });
    const activePageName = computed(() => {
      if (path.value) {
        return titleCase(path.value.slice(1).split('-').join(' '));
      }
      return 'Settings';
    });

    const views = {
      '#current-subscription': 'billing-page',
      '#faq': 'faq-page',
      '#legal': 'legal-page',
      '#support': 'support-page',
      '#manage-notifications': 'email-notifications-page',
      '#delete-account': 'delete-account-page'
    };

    const goToPrevRoute = () => {
      emit('close');
    };

    const goBack = () => {
      emit('close');
    };

    onMounted(() => {
      if (isUser) {
        fetchTrainingVideo(userCompanyId.value);
      }
      if (getProfileId()) {
        fetchProfile({ profileId: getProfileId() });
      }
    });

    const goToResetPassword = () => {
      localStorage.setItem('_c', true);
      return router.push({ name: 'Reset Password' });
    };

    const goToTrainingVideo = () => {
      const link = document.createElement('a');
      link.href = shouldShowTrainingVideo.value || '#';
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
    };

    const goToHash = (hash) => {
      return router.push(hash);
    };

    const logoutUser = () => {
      store.commit('auth/logout');
      store.commit('supervisor/clear');
      delete localStorage._token;
      return router.push({ name: 'Login' });
    };

    // Data wrappers initialized
    const profileId = getProfileId();
    const selfService = selfServiceApi(localStorage.getItem('_token'));

    const shouldShowTrainingVideo = computed(() => {
      return trainingVideo.value.url;
    });

    const shouldShowBilling = computed(() => {
      return profile.value.company?.paymentStructure == 'individual';
    });

    const requiresBilling = computed(() => {
      return doesRequireBilling(profile.value.company);
    });

    return {
      profile,
      fetchSelfServiceProfile: fetchProfile,
      shouldShowBilling,
      shouldShowTrainingVideo,
      selfService,
      activePageName,
      goBack,
      path,
      goToTrainingVideo,
      requiresBilling,
      views,
      isUser,
      goToHash,
      goToPrevRoute,
      goToResetPassword,
      logoutUser,
      profileId
    };
  }
};
</script>

<style scoped>
.sidepanel {
  width: 400px;
}

@media screen and (max-width: 767px) {
  .sidepanel {
    width: 100%;
  }
}
</style>