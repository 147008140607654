<!-- need to implement support for disco-->
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
const defaultLink = "https://hello.profileme.app/support";
const links = {
  profileme: defaultLink,
  discovery: "mailto:marketing_support@discovery.co.za?subject=Need Help on Hub&body=I need assistance on hub. I am experiencing the following issue..."
};
const store = useStore();
const helpLink = computed(() => {
  if (store.state?.whiteLabel?.title) {
    return links[store.state.whiteLabel.title.toLowerCase()] || defaultLink;
  }
  return defaultLink;
});
</script>
<template>
  <div class="flex justify-center text-2xl font-light">
    <a
      :href="helpLink"
      class="mx-2 text-white underline font-medium cursor-pointer accent-color"
    >Need help?</a>
  </div>
</template>
<style scoped>
.accent-color {
  color: #ffc507;
}
</style>