<template>
  <div class="stats-date-picker bg-datepicker-grey flex flex-col items-end bg-custom-grey-1 pt-10">
    <div class="px-14 w-full grid grid-cols-1 flex-wrap justify-between items-center text-center xl:flex">
      <v-dropdown
        :dropdownClasses="'w-full xl:w-max left-0 right-0 xl:left-auto xl:right-0'"
        class="dropdown-styling mb-5 w-full"
      >
        <template #button>
          <button
            class="datepicker-button py-4 px-10 border border-gray-600 text-2xl rounded-2xl min-w-auto xl:max-w-xl bg-white text-left flex justify-between items-center w-full"
          >
            Select Date Range
            <i class="fa-light fa-angle-down xl:hidden"></i>
          </button>
        </template>
        <template #content>
          <div class="flex flex-col justify-end bg-white border w-full p-5 xl:p-0 xl:w-max xl:flex-row">
            <div>
              <div class="scroll-x items-stretch text-2xl mb-5 flex flex-wrap xl:hidden">
                <div
                  class="w-1/4 py-4 border -mr-0.5 cursor-pointer relative flex items-center justify-center bg-white"
                  :class="{
                    'border-brand-color border z-10': item.text == title,
                    '-mb-0.5': item.text != title
                  }"
                  v-for="(item, index) in preSelectRanges"
                  :key="index"
                  @click="apply(item.dates(), item.text)"
                >
                  {{ item.text }}
                </div>
              </div>
              <div class="hidden xl:flex">
                <p :class="calenderTitles">
                  <span class="xl:inline-block">From</span>
                  <span class="text-black">{{
                    dates.start.toFormat(displayFormat)
                  }}</span>
                </p>
                <p :class="calenderTitles">
                  <span>To</span>
                  <span class="text-black">{{ dates.end.toFormat(displayFormat) }}
                  </span>
                </p>
              </div>
              <div class="flex mb-5 cursor-pointer text-3xl xl:hidden text-left">
                <div
                  class="border py-5 pl-5 flex-1 mr-4"
                  :class="{ 'border-brand-color': calendarSelected == 'from' }"
                  @click="calendarSelected = 'from'"
                >
                  <i class="fa fa-calendar text-4x text-gray-400 mr-5" />
                  <span class="text-black">{{
                    dates.start.toFormat('yyyy/MM/dd')
                  }}</span>
                </div>
                <div
                  @click="calendarSelected = 'to'"
                  :class="{ 'border-brand-color': calendarSelected == 'to' }"
                  class="border py-5 pl-5 flex-1"
                >
                  <i class="fa fa-calendar text-4xl text-gray-400 mr-5" />
                  <span class="text-black">{{ dates.end.toFormat('yyyy/MM/dd') }}
                  </span>
                </div>
              </div>
              <div
                class="xl:inline"
                :class="{
                  hidden: calendarSelected == 'to'
                }"
              >
                <date-picker
                  @update:modelValue="(d) => onCalendarUpdate(d, 'start')"
                  :model-value="calendarDates.start"
                  mode="date"
                ></date-picker>
              </div>
              <div
                class="xl:inline"
                :class="{
                  hidden: calendarSelected == 'from'
                }"
              >
                <date-picker
                  @update:modelValue="(d) => onCalendarUpdate(d, 'end')"
                  :model-value="calendarDates.end"
                  mode="date"
                ></date-picker>
              </div>
            </div>
            <div class="dropdown-presets-styling z-10">
              <div
                class="hidden xl:block"
                :class="preSelectStyling"
                v-for="(item, index) in preSelectRanges"
                :key="index"
                @click="apply(item.dates(), item.text)"
              >
                {{ item.text }}
              </div>
              <pm-button
                class="text-white"
                size="medium"
                @click="apply(calendarDates)"
              >Select</pm-button>
            </div>
          </div>
        </template>
      </v-dropdown>
      <div class="pt-24 w-full xl:pb-14">
        <range-select
          :options="dateOptions"
          :formatOutput="formatOutput"
          :startValue="sliderStart"
          :endValue="sliderEnd"
          @onChange="apply"
        />
      </div>
      <h1 class="text-brand-color text-3-5xl py-8 xl:py-0 font-semibold xl:order-first">
        Slide to select date range
      </h1>
    </div>
  </div>
</template>
<script>
import vDropdown from '~/components/ui/v-dropdown';
import { HISTOGRAM_DATE_FORMAT } from '~/constants';
import rangeSelect from '~/components/functional/v-range-select.vue';
import pmButton from '~/components/ui/pm-button.vue';
import { DateTime } from 'luxon';
import {
  today,
  date,
  beginningOfTime,
  getMonthsBetweenDates,
  formatDateForTitle
} from '~/functions/date-functions';
import { smartJoin } from '~/functions/utils-functions';

export default {
  props: {
    defaultDate: {
      type: Object,
      default: () => ({
        start: beginningOfTime(),
        end: today()
      })
    },
    defaultTitle: {
      type: String,
      default: ''
    },
    minDate: {
      type: Object,
      default: () => beginningOfTime()
    },
    maxDate: {
      type: Object,
      default: () => today()
    },
    resetDates: {
      type: Number,
      default: 0
    }
  },
  emits: ['change-date', 'change-title'],
  components: {
    vDropdown,
    rangeSelect,
    pmButton
  },
  data() {
    return {
      calenderTitles:
        'flex-1 py-8 text-gray-400 text-2xl xl:flex justify-between align-center px-6 calendar-border',
      sliderStart: this.defaultDate.start.toFormat(HISTOGRAM_DATE_FORMAT),
      sliderEnd: this.defaultDate.end.toFormat(HISTOGRAM_DATE_FORMAT),
      dates: {
        start: this.defaultDate.start,
        end: this.defaultDate.end
      },
      calendarDates: {
        start: this.defaultDate.start,
        end: this.defaultDate.end
      },
      dateOptions: getMonthsBetweenDates(
        HISTOGRAM_DATE_FORMAT,
        this.minDate,
        this.maxDate
      ),
      title: this.defaultTitle || 'Lifetime overview',
      calendarSelected: 'from',
      preSelectStyling:
        'p-4 border-b text-2xl cursor-pointer hover:bg-gray-100',
      formatOutput: (dates) => {
        return {
          start: DateTime.fromFormat(dates.start, HISTOGRAM_DATE_FORMAT),
          end: DateTime.fromFormat(dates.end, HISTOGRAM_DATE_FORMAT)
        };
      },
      preSelectRanges: [
        {
          dates: () => ({
            start: beginningOfTime(),
            end: today()
          }),
          text: 'Lifetime'
        },
        {
          dates: () => ({
            start: today().set({ day: 1 }),
            end: today()
          }),
          text: 'This Month'
        },
        {
          dates: () => ({
            start: today().set({ day: 1 }).plus({ month: -1 }),
            end: today().set({ day: 1 }).plus({ day: -1 })
          }),
          text: 'Last Month'
        },
        {
          dates: () => ({
            start: today().plus({ month: -3 }),
            end: today()
          }),
          text: 'Last 3 Months'
        },
        {
          dates: () => ({
            start: today().plus({ month: -6 }),
            end: today()
          }),
          text: 'Last 6 Months'
        },
        {
          dates: () => ({
            start: today().plus({ month: -12 }),
            end: today()
          }),
          text: 'Last 12 Months'
        },
        {
          dates: () => ({
            start: today().startOf('year'),
            end: today()
          }),
          text: 'This Year'
        },
        {
          dates: () => ({
            start: today().startOf('year').plus({ year: -1 }),
            end: today().endOf('year').plus({ year: -1 })
          }),
          text: 'Last Year'
        }
      ],
      format: HISTOGRAM_DATE_FORMAT,
      displayFormat: 'MMMM dd, yyyy'
    };
  },
  watch: {
    resetDates() {
      this.apply({ start: this.minDate, end: this.maxDate }, 'Lifetime');
    },
    defaultDate(newDates) {
      this.sliderStart = newDates.start.toFormat(HISTOGRAM_DATE_FORMAT);
      this.sliderEnd = newDates.end.toFormat(HISTOGRAM_DATE_FORMAT);
    }
  },
  methods: {
    onCalendarUpdate(d, key) {
      if (d && this.dates[key].toMillis() != date(d).toMillis()) {
        this.calendarDates[key] = date(d);
      }
    },
    apply(dates, title) {
      this.dates.start = dates.start;
      this.dates.end = dates.end;
      this.calendarDates.start = dates.start;
      this.calendarDates.end = dates.end;
      this.$emit('change-date', this.dates);
      this.sliderStart = dates.start.toFormat(this.format);
      this.sliderEnd = dates.end.toFormat(this.format);
      this.title = title
        ? title
        : `${dates.start.toFormat(
          HISTOGRAM_DATE_FORMAT
        )} - ${dates.end.toFormat(HISTOGRAM_DATE_FORMAT)}`;

      this.$emit(
        'change-title',
        smartJoin([
          formatDateForTitle(dates.start),
          formatDateForTitle(dates.end)
        ])
      );
      this.$emit('change-dropdown-title', this.title);
      document.getElementsByTagName('body')[0].click();
    }
  }
};
</script>
<style>
.calendar-border {
  border-right: 1px solid rgba(229, 231, 235, var(--tw-border-opacity));
}

.stats-date-picker.bg-custom-grey-1 {
  background-color: #e6e8ef;
}

.stats-date-picker .vc-container {
  border: none;
  border-radius: 0;
}

.stats-date-picker .dropdown-styling {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .stats-date-picker .dropdown-styling {
    max-width: 300px;
  }

  .stats-date-picker .vc-container {
    border-right: 1px solid rgba(229, 231, 235, var(--tw-border-opacity));
    padding-bottom: 44px;
  }
}

.stats-date-picker .dropdown-presets-styling {
  min-width: 200px;
}
</style>
