import { chain } from 'lodash';
import {
  computed, ref,
  inject
} from 'vue';
/**
 * This wrapper is responsible for reading and updating permissions from the database
 */
export default function (
  selfServiceForm,
  permissions,
  useMasterPermissions = false
) {

  const toast = inject('toast');
  const updatingMasterPermissions = ref(useMasterPermissions);

  const masterPermissions = ref(
    selfServiceForm.profile.masterPermissions
  );
  const childPermissions = ref(selfServiceForm.profile.childPermissions);
  const managePermissions = computed({
    get() {
      return updatingMasterPermissions.value
        ? masterPermissions.value
        : childPermissions.value;
    },
    set(newValue) {
      const currentPermissions = updatingMasterPermissions.value
        ? masterPermissions
        : childPermissions;
      currentPermissions.value = newValue;
    }
  });
  const permissionId = computed({
    get() {
      return updatingMasterPermissions.value
        ? selfServiceForm.profile.masterPermissionId
        : selfServiceForm.profile.permissionId;
    },
    set(newValue) {
      selfServiceForm.profile[
        updatingMasterPermissions.value ? 'masterPermissionId' : 'permissionId'
      ] = newValue;
    }
  });
  async function applyPermissions() {
    selfServiceForm.savingProfile.loading = true;
    let result = {};
    if (permissionId.value) {
      result = await selfServiceForm.selfServiceApi.updatePermissions(
        permissionId.value,
        managePermissions.value
      );
    } else {
      result = await selfServiceForm.selfServiceApi.createPermissions(
        selfServiceForm.profile.profileId,
        managePermissions.value,
        selfServiceForm.profile.profileName + ' - Permissions',
        updatingMasterPermissions.value
      );
      if (result.success) {
        permissionId.value = result.id;
      }
    }
    if (result.success) {
      toast({
        success: true,
        message: 'Permissions has been updated!'
      });
      selfServiceForm.savingProfile.loading = false;

      return;
    }
    toast({
      success: false,
      message: result.message
    });
    selfServiceForm.savingProfile.loading = false;

    return;
  }

  const defaultPermission = {
    edit: true,
    show: true,
    optional: true,
    sortable: true
  };

  function handlePermissionUpdate({ type, value, id }) {
    const currentPermissions = updatingMasterPermissions.value
      ? masterPermissions
      : childPermissions;
    currentPermissions.value = chain(currentPermissions.value)
      .find({ key: id })
      .thru((item) => {
        if (item) item[type] = value;
        else
          currentPermissions.value.push({
            ...defaultPermission,
            [type]: value,
            key: id
          });
        return currentPermissions.value;
      })
      .value();
  }

  const resetPermissions = async () => {
    selfServiceForm.savingProfile.loading = true;
    try {
      const result = await selfServiceForm.selfServiceApi.clearPermissions(
        permissionId.value
      );
      if (result.success) {
        managePermissions.value = [];
        toast({
          success: true,
          message: 'Permissions Reset!'
        });
      } else {
        toast({
          success: false,
          message: result.message
        });
      }
    } catch (err) {
      toast({
        success: false,
        message: err.message
      });
    } finally {
      selfServiceForm.savingProfile.loading = false;
    }
  };

  const savePermissionsButtonTitle = computed(() => {
    if (selfServiceForm.savingProfile.loading) {
      return 'Saving...';
    }
    return 'Apply permissions updates';
  });

  const switchPermissionsForMaster = () => {
    updatingMasterPermissions.value = !updatingMasterPermissions.value;
  };

  return {
    ...selfServiceForm,
    saveButtonTitle: computed(() => {
      if (selfServiceForm.savingProfile.loading) {
        return 'Saving...';
      }
      return 'Apply template updates';
    }),
    permissions: permissions ? permissions : selfServiceForm.permissions,
    applyPermissions,
    managePermissions,
    handlePermissionUpdate,
    resetPermissions,
    updatingMasterPermissions,
    savePermissionsButtonTitle,
    switchPermissionsForMaster
  };
}
