import { omit, split } from 'lodash';
import {
  authenticateWithStore,
  parseForParams,
  logoutRoute
} from '~/functions/login-functions';
import { userRole } from '~/functions/utils-functions';
import featureFlagWrapper from '~/functions/wrappers/feature-flag-wrapper';
// Hide premium hardcode

export default function auth({ next, store, session, to, local }) {
  const storage = { ...session, ...local };
  if (authenticateWithStore(store, storage)) {
    if (
      to.query.params &&
      (to.name.includes('Login') ||
        to.name.includes('Authenticate') ||
        to.name.includes('Reset Password'))
    ) {
      return next({
        path: parseForParams(to.query.params),
        query: omit(to.query, 'params')
      });
    }

    const featureFlagHandler = featureFlagWrapper(
      store.state.auth.user.companies,
      userRole(store.state.auth.user)
    );

    if (to.params?.alias && (to.params?.report == 'templates' || to.params?.report == 'self-service')) {
      if (featureFlagHandler.isSupervisor || featureFlagHandler.isUser) {
        const redirectBack = {
          name: 'Main',
          params: {
            report: to.params?.report == 'self-service' ? 'users' : 'templates'
          },
          query: to.query
        };

        if (featureFlagHandler.isUser)
          return next(redirectBack);
        const userCompanyId = split(to.params?.alias, '_')[1];
        // check if supervisor has access to the self service profile company
        const activeCompany =
          (store.state?.supervisor?.supervisorCompanies || []).filter(
            (e) => e.id == userCompanyId
          )?.[0];
        // if self service company can be found in supervisor companies -> allow acces, otherwise, return to previous route
        if (!activeCompany) {
          return next(redirectBack);
        }
      }
    }

    return next(
      featureFlagHandler.getPathByPermissions(to.path, to.params.alias)
    );
  }

  return next(logoutRoute(to));
}
