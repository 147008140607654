<template>
  <div class="flex items-center justify-center" :class="color">
    <i class="fa-regular fa-ellipsis-vertical text-5xl 2-10"></i>
  </div>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'gray'
    }
  }
};
</script>
<style scoped>
.gray {
  color: #999999;
}
</style>
