import './index.css';
import 'vue-loaders/dist/vue-loaders.css';

import App from './App.vue';
import VueLoaders from 'vue-loaders';
import 'v-calendar/dist/style.css';
import { DatePicker } from 'v-calendar';
import { createApp } from 'vue';
import router from './router/index.js';
import store from './store';
import toast from './components/plugins/toast/toast';

// css editor
import ace from 'ace-builds';

import modeHtmlUrl from 'ace-builds/src-noconflict/mode-css?url';
ace.config.setModuleUrl('ace/mode/css', modeHtmlUrl);

import themeChromeUrl from 'ace-builds/src-noconflict/theme-chrome?url';
ace.config.setModuleUrl('ace/theme/chrome', themeChromeUrl);

import workerBaseUrl from 'ace-builds/src-noconflict/worker-base?url';
ace.config.setModuleUrl('ace/mode/base', workerBaseUrl);

import workerHtmlUrl from 'ace-builds/src-noconflict/worker-css?url';
ace.config.setModuleUrl('ace/mode/css_worker', workerHtmlUrl);

import snippetsHtmlUrl from 'ace-builds/src-noconflict/snippets/css?url';
ace.config.setModuleUrl('ace/snippets/css', snippetsHtmlUrl);

import 'ace-builds/src-noconflict/ext-language_tools';
ace.require("ace/ext/language_tools");

// maps api
const head = document.querySelector('head');
const script = document.createElement('script');
script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_MAPS_API_KEY}&libraries=places`;
head.appendChild(script);
// ProfileMe library utils

const libraryUtils =
  {
    development: 'https://development.cdn.profileme.app/src/',
    beta: 'https://beta.cdn.profileme.app/src/',
    local: process.env.VUE_APP_LIBRARY_UTILS_LOCAL_URL
  }[process.env.VUE_APP_LIBRARY_UTILS] || 'https://cdn.profileme.app/src/';

const utilsScript = document.createElement('script');
utilsScript.src = `${libraryUtils}ProfileMeUtils.js`;
head.appendChild(utilsScript);
// Create App
const app = createApp(App);

app.use();
app.component('DatePicker', DatePicker);
// Add plugins
app.use(router);
app.use(store);
app.use(toast);
app.provide('toast', app.config.globalProperties.$toast);

router.app = app;

app.use(VueLoaders);

// Mount App
app.mount('#app');
