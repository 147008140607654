<template>
  <div class="relative">
    <div>
      <slot></slot>
      <div v-if="profilesLoading" class="w-full" :style="{ height: '500px' }">
        <v-loader :hideText="true"></v-loader>
      </div>
      <div v-else class="pb-10 xl:pb-4">
        <!-- Controls -->
        <div class="text-2xl" :class="controlStyling('xl')">
          <v-auto-complete
            class="xl:w-128 w-full mb-4 xl:mb-0 bg-gray-100 relative"
            placeholder="Select Division"
            labelKey="description"
            valueKey="id"
            :multiple="true"
            :suggestions="suggestedCompanies"
            :value="userWrapper.currentFilteredDivision.id"
            @update="handleDivisionFilter"
            @onSelect="filterStatsByDivision"
            @onCancel="filterStatsByDivision()"
          />
          <v-search-input
            v-model="searchString"
            class="xl:w-128 w-full"
            title="Search templates"
          />
        </div>
        <!-- USER TABLE DESKTOP -->
        <div class="hidden xl:grid grid-cols-4 user-table-ratio">
          <v-table-sort
            fieldName="NAME"
            :direction="getSortDirection('NAME')"
            title="A-Z"
            @sort="
              (s) => {
                sortDirection = getNextDirection(
                  sortDirection,
                  sortFieldName != s
                );
                sortFieldName = s;
              }
            "
          />
          <v-table-sort
            fieldName="DIVISION"
            :direction="getSortDirection('DIVISION')"
            title="Division"
            @sort="
              (s) => {
                sortDirection = getNextDirection(
                  sortDirection,
                  sortFieldName != s
                );
                sortFieldName = s;
              }
            "
          />
          <v-table-sort
            fieldName="PROFILES"
            :direction="getSortDirection('PROFILES')"
            title="Linked Profiles"
            iconUp="fa-sort-numeric-up-alt"
            iconDown="fa-sort-numeric-down-alt"
            @sort="
              (s) => {
                sortDirection = getNextDirection(
                  sortDirection,
                  sortFieldName != s
                );
                sortFieldName = s;
              }
            "
          />
          <div></div>
          <template v-for="profile in profilesOnPage" :key="profile.id">
            <!-- User Column -->
            <div :class="cellStyling(true, true, false)">
              <v-profile-card
                :fullName="profile.templateName || profile.profileName"
                :profileImage="formatImageUrl(profile.profileThumbnail)"
                @clickProfile="goToProfile(formatPathForEdit(profile))"
              />
            </div>
            <!-- Division Column -->
            <div :class="cellStyling(false, true, false)">
              {{ profile.companyName }}
            </div>
            <!-- ProfileView Column -->
            <div :class="cellStyling(true, true, false)">
              {{ profile.linkedProfiles }}
            </div>
            <!-- View Stats button column -->
            <div :class="cellStyling(true, false, false)">
              <span class="w-max my-4 mr-4">
                <pm-button @click="showPreviewEvent(profile)" size="medium"
                  >Preview</pm-button
                >
              </span>
              <router-link class="w-max" :to="formatPathForEdit(profile)">
                <pm-button size="medium" :background="'outline'"
                  >Edit</pm-button
                >
              </router-link>
            </div>
          </template>
        </div>

        <div
          class="text-2xl bg-white border flex justify-between items-center p-4 mb-6 w-32 cursor-pointer xl:hidden"
          @click="showModal('mobile-sort')"
        >
          <span>Sort</span>
          <i class="fa-sharp fa-solid fa-caret-down"></i>
        </div>
        <!-- USER TABLE MOBILE -->
        <div class="block xl:hidden">
          <v-table-mobile-cell
            v-for="profile in profilesOnPage"
            class="mb-6"
            :key="profile.id"
          >
            <template #profile-card>
              <v-profile-card
                :fullName="profile.templateName || profile.profileName"
                :profileImage="formatImageUrl(profile.profileThumbnail)"
                @clickProfile="goToProfile(formatPathForEdit(profile))"
              />
            </template>

            <template #preview-buttons>
              <router-link
                class="w-max p-3"
                style="width: inherit; width: 50px"
                :to="formatPathForEdit(profile)"
              >
                <pm-button
                  size="small"
                  style="width: inherit; width: 50px"
                  :background="'outline'"
                  >Edit</pm-button
                >
              </router-link>
              <span class="w-max my-4 pl-5">
                <pm-button @click="showPreviewEvent(profile)" size="small"
                  >Preview</pm-button
                >
              </span>
            </template>

            <template #content>
              <div class="text-xl pr-4">
                <p class="text-gray-800 mb-4">Division</p>
                <p class="text-gray-500 whitespace-nowrap">{{
                  profile.companyName
                }}</p>
              </div>
              <div class="text-xl">
                <p class="text-gray-800 mb-4">Linked Profiles</p>
                <p class="text-gray-500 whitespace-nowrap">{{
                  profile.linkedProfiles
                }}</p>
              </div>
            </template>
          </v-table-mobile-cell>
        </div>

        <p class="text-2xl xl:p-5 mt-6 pl-4 pb-2 xl:pl-0"
          >{{ profilesFiltered.length ? page * pageSize + 1 : 0 }} to
          {{ Math.min((page + 1) * pageSize, profilesFiltered.length) }} of
          {{
            profilesFiltered.length + (searchString !== '' ? ' filtered' : '')
          }}
          results</p
        >
        <div class="pl-4 xl:pl-0" v-if="profilesFiltered.length > pageSize">
          <v-pagnate
            :maxOptions="5"
            :pageNumber="page"
            :totalItems="profilesHandler.currentItems.length"
            :splitBy="pageSize"
            @pageSelected="(p) => pageTo(p)"
          />
        </div>
      </div>
    </div>
    <v-modal
      modalName="mobile-sort"
      classes="modal-content-full"
      :closeOnOutsideClick="true"
    >
      <template #content>
        <v-mobile-sorting-panel-vue
          :config="SORTING_CONFIG"
          :modelValue="sortFieldName"
          modalName="mobile-sort"
          @sort="
            (name) => {
              sortDirection = getNextDirection(
                sortDirection,
                sortFieldName != name
              );
              sortFieldName = name;
              hideModal('mobile-sort');
            }
          "
        />
      </template>
    </v-modal>
    <!-- Profile Preview -->
    <vue-final-modal
      :fit-parent="false"
      :name="'modal-preview'"
      v-model="showPreviewModal"
      :esc-to-close="false"
    >
      <preview
        v-if="showPreviewModal"
        :profileChanges="[]"
        :profile="selfServiceProfile?.fullProfile"
        :showPreview="showPreview"
        :updatePreview="updatePreview"
        @closePreview="
          () => {
            showPreview = false;
            previewProfile = null;
          }
        "
      />
    </vue-final-modal>
  </div>
</template>

<script>
import {
  formatPath,
  cellStyling,
  controlStyling,
  formatImageUrl
} from '~/functions/utils-functions';
import sortWrapper from '~/functions/wrappers/sort-wrapper';
import pmButton from '~/components/ui/pm-button.vue';
import vTableSort from '~/components/ui/v-table-sort.vue';
import vProfileCard from '~/components/ui/v-profile-card.vue';
import vTableMobileCell from '~/components/ui/v-table-mobile-cell.vue';
import { $vfm, VueFinalModal } from 'vue-final-modal';
import manageUsersApi from '~/api/manageUsers';
import selfServiceApi from '~/api/self-service-v4';
import preview from '~/views/self-service/preview.vue';
import vModal from '~/components/functional/v-modal.vue';
import vMobileSortingPanelVue from '~/components/ui/v-mobile-sorting-panel.vue';
import vLoader from '~/components/ui/v-loader.vue';
import { defaultTo, chain, map, isEmpty, includes } from 'lodash';
import VSearchInput from '~/components/ui/v-search-input.vue';
import vAutoComplete from '~/components/functional/v-autocomplete.vue';
import vPagnate from '~/components/functional/v-pagnate.vue';
import userState from '~/store/state-managers/user';

const SORTING_CONFIG = [
  { name: 'NAME', key: 'templateName', backupKey: 'profileName', title: 'A-Z' },
  { name: 'DIVISION', key: 'companyName', title: 'Division' },
  { name: 'PROFILES', key: 'linkedProfiles', title: 'Linked profiles' }
];
const { getSortDirectionStatic, getSortedDataStatic, getNextDirection } =
  sortWrapper(SORTING_CONFIG, []);

export default {
  components: {
    vPagnate,
    pmButton,
    vModal,
    vTableSort,
    vProfileCard,
    vTableMobileCell,
    vMobileSortingPanelVue,
    VueFinalModal,
    preview,
    VSearchInput,
    vLoader,
    vAutoComplete
  },
  props: {
    profilesHandler: {
      type: Object,
      default: () => {}
    },
    selfServiceProfile: {
      type: Object,
      default: () => {}
    },
    reports: {
      type: Object,
      default: () => {}
    },
    user: {
      type: Object,
      default: () => {}
    },
    profilesLoading: {
      type: Boolean,
      default: false
    },
    suggestedCompanies: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ...this.reports,
      controlStyling,
      showPreview: false,
      showPreviewModal: false,
      updatePreview: 0,
      previewProfile: null,
      sortDirection: 'asc',
      sortFieldName: 'NAME',
      SORTING_CONFIG,
      pageSize: 50,
      page: 0,
      filter: {
        company: ''
      },
      searchString: '',
      divisionFilterString: '',
      usersApi: manageUsersApi(sessionStorage.getItem('_token')),
      selfServiceApi: selfServiceApi(sessionStorage.getItem('_token')),
      userWrapper: userState()
    };
  },
  mounted() {
    this.profilesHandler.setItemsFilters({
      search: (p) =>
        chain(p)
          .get('templateName')
          .defaultTo(chain(p).get('profileName'))
          .toLower()
          .includes(defaultTo(this.searchString, '').toLowerCase())
          .value(),
      division: (p) =>
        includes(this.divisionFilter, p.companyId) ||
        isEmpty(this.divisionFilter)
    });

    this.profilesHandler.toggleItemsStatus(
      'search',
      'division',
      'masterProfiles'
    );
  },
  computed: {
    divisionFilter() {
      return this.$store.state.supervisor.filterByDivision;
    },
    availableCompanies() {
      return this.$store.state.supervisor.supervisorCompanies || [];
    },
    profilesFiltered() {
      return getSortedDataStatic(
        this.sortFieldName,
        this.sortDirection,
        this.profilesHandler.currentItems,
        this.searchString,
        this.divisionFilterString
      );
    },
    profilesOnPage() {
      return this.profilesFiltered.slice(
        this.page * this.pageSize,
        (this.page + 1) * this.pageSize
      );
    }
  },
  watch: {
    sortFieldName() {
      this.pageTo(0);
    },
    searchString() {
      this.pageTo(0);
      this.$forceUpdate();
    },
    selfServiceProfile() {
      this.updatePreview++;
      setTimeout(() => {
        this.showPreview = true;
      }, 200);
    }
  },
  methods: {
    filterStatsByDivision(division) {
      this.divisionFilterString = division.join(',');
      this.$store.commit('supervisor/setDivisionFilter', map(division, 'id'));
    },
    handleDivisionFilter(value) {
      this.$emit('fetchCompanySuggestions', {
        'filters[description][$containsi]': value.filter,
        'pagination[page]': value.page
      });
    },
    formatImageUrl,
    cellStyling,
    getNextDirection,
    getSortDirection(name) {
      return getSortDirectionStatic(
        name,
        this.sortFieldName,
        this.sortDirection
      );
    },
    showModal(name) {
      $vfm.show(name);
    },
    hideModal(name) {
      $vfm.hide(name);
    },
    pageTo(pageNum) {
      this.page = pageNum;
      window.scrollTo(0, 0);
    },
    formatPath(profile) {
      return formatPath('/statistics/', profile, this.$route.query.companyId);
    },
    formatPathForEdit(profile) {
      return formatPath(
        '/manage-templates/',
        profile,
        this.$route.query.companyId
      );
    },
    goToProfile(path) {
      this.$router.push(path);
    },
    showPreviewEvent(profile) {
      this.showPreviewModal = true;
      this.$emit('fetchSelfServiceProfile', {
        profileId: profile.id,
        noLoading: true
      });
    }
  }
};
</script>

<style scoped>
@media screen and (min-width: 768px) {
  .user-table-ratio {
    grid-template-columns: repeat(
      minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr) 90px minmax(0, 1fr) 80px
    );
  }
}
</style>
