<template>
  <div class="sidenav bg-brand-color flex flex-col items-start text-white pb-10 w-full md:py-0 md:pt-24 md:w-1/3">
    <div
      v-for="(link, index) in links"
      :key="index"
      :class="[
        linkStyling,
        {
          'md:bg-navigation-active':
            link.path == value || link.altPath?.includes(value)
        }
      ]"
      @click="navigateTo(link.path)"
    >
      <div :class="[
        thumbStyling,
        link.path == value ? 'bg-gray' : 'bg-brand-light-color',
        { 'md:border-t': !index }
      ]"></div>
      <div :class="[
        bodyStyling,
        {
          'md:border-t': !index
        }
      ]">
        <i :class="[link.icon, iconStyling]"></i>
        <div :class="[
          bodyInnerStyling,
          {
            'opacity-100': link.path == value,
            'active-tab': link.path == value
          }
        ]">
          <p>{{ link.label }}</p>
          <div
            class="text-xl rounded-full bg-red-600 w-8 h-8 text-center justify-center items-center flex absolute -top-4 right-40"
            v-if="link.label === 'Approvals' && approvals.length > 0"
          >
            {{ approvals.length }}
          </div>
        </div>
        <i
          class="fa-solid fa-caret-right text-center md:hidden"
          :class="iconStyling"
        ></i>
      </div>
    </div>

    <div
      :class="linkStyling"
      @click="logout"
    >
      <div :class="thumbStyling"></div>
      <div
        class="logout"
        :class="bodyStyling"
      >
        <i
          class="fa-light fa-arrow-right-from-bracket"
          :class="iconStyling"
        ></i>
        <div :class="bodyInnerStyling">
          <p>Logout</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  emits: ['link-select'],
  props: {
    links: {
      type: Array,
      default: () => []
    },
    approvals: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      linkStyling: `
        w-full
        sidenav-link
        relative
        items-center
        h-28
        flex
        px-20
        box-border
        md:px-0
        cursor-pointer md:h-36`,
      iconStyling: 'mr-2 icon-color text-3xl w-16 ml-10',
      thumbStyling: `
        navigation-thumb
        h-full
        no-side-border
        md:w-4 md:border md:border-t-0
      `,
      bodyInnerStyling: `
        flex-1
        text-4xl
        relative
        px-6
        opacity-60
        md:opacity-100 md:font-normal
      `,
      bodyStyling: `
        sidenav-link-content
        no-side-border
        flex flex-1
        items-center
        h-full
        justify-start
        md:border md:border-t-0`
    };
  },
  methods: {
    navigateTo(path) {
      if (path != this.value) {
        this.$emit('link-select');
        this.$nextTick(() =>
          this.$router.push({
            name: 'Main',
            params: { report: path },
            query: this.$route.query
          })
        );
      }
    },
    logout() {
      this.$store.commit('auth/logout');
      this.$store.commit('supervisor/clear');
      delete localStorage._token;
      delete sessionStorage._token;
      return this.$router.push({ name: 'Login' });
    }
  }
};
</script>
<style>
.sidenav-link-content,
.navigation-thumb {
  border-color: #707070;
}

.no-side-border {
  border-left: none;
  border-right: none;
}

.bg-gray {
  background-color: #b9b9b9;
  border-color: #b9b9b9;
}

.bg-blue {
  background-color: var(--brand-color);
  border-color: #707070;
}

.bg-navigation-active.sidenav-link-content {
  background-color: #157188;
}

.icon-color {
  color: #b9b9b9;
}

.active-tab {
  text-decoration: underline;
  text-underline-offset: 6px;
  text-decoration-thickness: 2px;
}

.logout.sidenav-link-content {
  border: white 1px solid;
  border-radius: 0.8rem;
}

@media screen and (min-width: 768px) {
  .logout.sidenav-link-content {
    border: none;
  }

  .sidenav {
    min-width: 320px;
    max-width: 255px;
  }

  .md\:bg-navigation-active.sidenav-link-content {
    background-color: #157188;
  }
}
</style>