import { chain, isEmpty, replace } from 'lodash';

/**
 * @param {*} itemId 
 * @returns The itemId without '[string]'
 */
const stripFields = (itemId) => replace(itemId, /\[(.*?)\]/, '');
const getPermissionsValue = (permissions, groupId, key, defaultValue) => {
  return chain(permissions)
    .filter((e) => e.key === groupId)
    .first()
    .defaultTo({ [key]: defaultValue })
    .get(key)
    .value();
};
const getClassForPermission = ({ notEdit, show, optional }, key) => {
  return chain({
    'permissions-no-edit': !notEdit,
    'permissions-hide': !show,
    ['permissions-required']: !optional,
    [key]: !optional
  })
    .omitBy((i) => !i)
    .keys()
    .thru((val) => (isEmpty(val) ? ['no-negative-permissions'] : val))
    .value();
};

/**
 * If the itemId contains anything between []
 * it will return them in a list (splitted on ',') with '.' prepended 
 * on each field. Otherwise it will just return [''].
 * @param {*} itemId
 * @returns  Returns a curried function of list that can be chained.
 */
const getFieldArray = (itemId) => {
  return chain(itemId)
    .thru((v) => /\[(.*?)\]/.exec(v))
    .defaultTo([''])
    .first()
    .replace(/[[\]]/g, '')
    .split(',')
    .map((v) => (v ? '.' : '') + v);
};

export {
  getPermissionsValue,
  getClassForPermission,
  getFieldArray,
  stripFields
};
