import { authenticateWithStore } from '~/functions/login-functions';
import { userRole } from '~/functions/utils-functions';

export default function guest({ next, store, session, to, local }) {
  const storage = { ...session, ...local };
  if (!authenticateWithStore(store, storage)) {
    return next();
  }

  if (userRole(store.state.auth.user) === 'user') {
    return next({
      name: 'Main',
      params: {
        report: 'self-service'
      },
      query: to.query
    });
  } else {
    return next({
      name: 'Main',
      params: {
        report: 'home'
      },
      query: to.query
    });
  }
}
