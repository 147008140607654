<template>
  <main class="main">
    <div class="card">
      {{ displayText }}
      <div class="loader">
        <pm-button
          @onClick="backToLogin"
          :disabled="loading"
          :loading="loading"
          size="small"
          >Back to login</pm-button
        >
      </div>
    </div>
  </main>
</template>

<script>
import authService from '~/api/auth';
import pmButton from '~/components/ui/pm-button.vue';
export default {
  components: { pmButton },
  data: function () {
    return {
      loading: false,
      displayText: 'Please wait while we authenticate your account...'
    };
  },
  mounted() {
    if (this.$route.query['id_token']) {
      this.signInWithGoogle();
    } else {
      this.displayText = 'You have not requested to sign in with Google';
    }
  },
  methods: {
    backToLogin() {
      this.$router.push({ name: 'Login' });
    },
    async signInWithGoogle() {
      try {
        this.loading = true;
        const user = await authService().getUserFromToken(
          window.location.href.split('?')[1]
        );

        if (user.success) {
          sessionStorage.setItem('_token', user.message.jwt);
          sessionStorage.setItem('_reload', true);
          if (user.message.user.profile) {
            return this.$router.push({
              name: 'Login'
            });
          }
          const link = await authService(user.message.jwt).registerWithGoogle(
            user.message.user.email
          );
          if (link.success) {
            this.$router.push({
              name: 'Login'
            });
          } else {
            sessionStorage.removeItem('_token');
            sessionStorage.removeItem('_reload');
            this.displayText = link.message;
            return this.$showError(link.message);
          }
        } else {
          sessionStorage.removeItem('_token');
          sessionStorage.removeItem('_reload');
          this.displayText = user.message;
          return this.$showError(user.message);
        }
      } catch (err) {
        sessionStorage.removeItem('_token');
        sessionStorage.removeItem('_reload');
        return this.$showError(err.toString());
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.main {
  background-color: rgba(var(--brand-dark-color), 1);
  height: 100vh;
  position: relative;
}

.card {
  text-align: center;
  background: white;
  border-radius: 3px;
  font-size: 1.8rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 5rem 15rem;

  .loader {
    margin-top: 2rem;
  }
}
</style>
