import { getDataConfig } from '~/functions/setup/api-setup';
import dataWrapper from '~/functions/wrappers/data-wrapper';
import {
  today,
  date,
  beginningOfTime,
  dateState
} from '~/functions/date-functions';
import { assign, chain, defaultTo, first, get, map } from 'lodash';
import userState from '~/store/state-managers/user';
// Analytics data
const useDataRefs = (
  onStartCallback = () => {},
  callback = () => {},
  reactive
) => {
  const statsState = reactive({
    query: {
      profileId: ''
    },
    firstLoad: true
  });
  const { getUser, isUser, getCurrentFilteredDivision, isSuperAdmin } =
    userState();

  const currentDates = new dateState(beginningOfTime(), today());
  const onDateChange = (dates) => {
    currentDates.setDates(dates);
  };
  const statsProfileId = () => statsState.query.profileId;
  const updateQuery = (alias) => {
    const { profile, companies } = getUser();
    const profileId = first(
      defaultTo(alias, isUser ? profile + '_' : undefined)?.split('_')
    );

    const companyId = chain(getCurrentFilteredDivision())
      .thru((filteredDivision) => {
        const defaultCompanyIds = isSuperAdmin
          ? undefined
          : map(companies, (entry) => entry.id);
        if (alias) return [get(alias.split('_'), '1', defaultCompanyIds)];
        if (filteredDivision) return map(filteredDivision, 'id');
        return defaultCompanyIds;
      })
      .value();

    assign(statsState.query, {
      start: date(currentDates.start).toFormat('yyyy-MM-dd'),
      end: date(currentDates.end).toFormat('yyyy-MM-dd'),
      companyId,
      profileId
    });
  };
  const { dataRefs, topProfiles, fetchData } = dataWrapper({
    reports: getDataConfig(currentDates, isUser),
    onStart: onStartCallback,
    onComplete: callback
  });

  return {
    dataRefs,
    currentDates,
    fetchData: () => fetchData(statsState.query),
    topProfiles,
    statsState,
    onDateChange,
    updateQuery,
    statsProfileId
  };
};

export default useDataRefs;
