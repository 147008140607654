<template>
  <div>
    <div>
      <slot></slot>
      <!-- CONTROLS -->
      <div class="text-2xl" :class="controlStyling('xl')">
        <v-auto-complete
          class="mr-6 w-full mb-10 xl:mb-0 xl:w-4/12"
          placeholder="Select Division"
          labelKey="description"
          valueKey="id"
          :multiple="true"
          :suggestions="suggestedCompanies"
          :value="userWrapper.currentFilteredDivision.id"
          @update="handleDivisionFilter"
          @onSelect="filterStatsByDivision"
          @onCancel="filterStatsByDivision()"
        />
        <v-search-input
          v-model="searchString"
          class="mr-6 w-full xl:w-4/12"
          title="Search Approvals"
        />
      </div>
      <div class="lg:bg-transparent pb-10 lg:pb-4 lg:mt-0">
        <!-- USER TABLE DESKTOP -->
        <div class="hidden xl:grid flex-1 grid-cols-6 approvals-table-ratio">
          <v-table-sort
            fieldName="NAME"
            :direction="getSortDirection('NAME')"
            title="A-Z"
            @sort="setSort"
          />
          <v-table-sort
            fieldName="DIVISION"
            :direction="getSortDirection('DIVISION')"
            title="Division"
            @sort="setSort"
          />
          <v-table-sort
            fieldName="ALIAS"
            :direction="getSortDirection('ALIAS')"
            title="Alias"
            @sort="setSort"
          />
          <v-table-sort
            fieldName="REQUESTDATE"
            :direction="getSortDirection('REQUESTDATE')"
            title="Requested at"
            @sort="setSort"
          />
          <div
            class="text-gray font-normal xl:p-5 xl:pl-0 text-left flex lg:text-2xl text-xl mr-6 lg:relative lg:left-8 justify-start"
          >
            <span> Preview profile </span>
          </div>
          <div
            class="text-gray font-normal xl:p-5 xl:pl-0 text-left flex lg:flex-1 lg:text-2xl text-xl mr-6 lg:relative lg:left-8 justify-start"
          >
            <span> Approve / Reject profile </span>
          </div>
          <template v-for="profile in approvalsOnPage" :key="profile.id">
            <!-- User Column -->
            <div :class="cellStyling(true)">
              <v-profile-card
                :fullName="profile.fullName"
                :jobTitle="profile.jobTitle"
                :mockImage="logo"
                :profileImage="formatImageUrl(profile.profileThumbnail)"
                :url="profile.url === 'No Url' ? '#' : profile.url"
                @clickProfile="goToProfile(formatPathToSS(profile))"
              />
            </div>
            <!-- Division Column -->
            <div :class="cellStyling(true)">
              {{ profile.companyName }}
            </div>
            <!-- Alias Column -->
            <div :class="cellStyling(true)">
              {{ profile.alias }}
            </div>
            <!-- Request Date Column -->
            <div :class="cellStyling(true)">
              {{ timenow(profile.publishedAt) }}
            </div>
            <!-- View Stats button column -->
            <div :class="cellStyling(true)">
              <pm-button class="w-full" @click="showPreviewFor(profile)">
                Preview
              </pm-button>
            </div>
            <div :class="cellStyling(true)">
              <div
                @click="
                  () => {
                    profileToBeApproved = profile;
                    openModal('confirm-approve');
                  }
                "
                class="cursor-pointer mr-8"
                ><i
                  class="fa-light fa-circle-check text-green-600 text-5xl mr-2"
                ></i
              ></div>
              <div
                @click="
                  () => {
                    profileToBeApproved = profile;
                    showApproveModal = true;
                    answer = false;
                    openModal('deny-approve');
                  }
                "
                class="cursor-pointer"
                ><i class="fa-light fa-circle-xmark text-5xl text-red-600"></i
              ></div>
            </div>
          </template>
        </div>
        <div
          class="text-2xl bg-white border flex justify-between items-center rounded-xl p-4 mb-6 w-32 cursor-pointer xl:hidden"
          @click="openModal('mobile-sort')"
        >
          <span>Sort</span>
          <i class="fa-sharp fa-solid fa-caret-down"></i>
        </div>
        <!-- USER TABLE MOBILE -->
        <div class="block xl:hidden">
          <v-table-mobile-cell
            v-for="profile in approvalsOnPage"
            class="mb-6"
            :key="profile.id"
          >
            <template #profile-card>
              <v-profile-card
                :fullName="profile.fullName"
                :jobTitle="profile.jobTitle"
                :profileImage="formatImageUrl(profile.profileThumbnail)"
                :url="profile.url === 'No Url' ? '#' : profile.url"
                @clickProfile="goToProfile(formatPathToSS(profile))"
              />
            </template>

            <template #preview-buttons>
              <pm-button
                size="small"
                class="ml-5"
                style="width: inherit; width: 100px"
                @click="showPreviewFor(profile)"
              >
                Preview
              </pm-button>
            </template>

            <template #content>
              <div class="flex col-span-2 justify-between">
                <div class="text-xl pr-4">
                  <p class="text-gray-800 mb-4">Division</p>
                  <p class="text-gray-500 whitespace-nowrap">{{
                    profile.companyName
                  }}</p>
                </div>
                <div class="text-xl">
                  <p class="text-gray-800 mb-4">Alias</p>
                  <p class="text-gray-500 whitespace-nowrap"
                    >/{{ profile.alias }}</p
                  >
                </div>
                <div class="text-xl">
                  <p class="text-gray-800 mb-4">Requested at</p>
                  <p class="text-gray-500 whitespace-nowrap">{{
                    timenow(profile.publishedAt)
                  }}</p>
                </div>
              </div>
            </template>

            <template #action-buttons>
              <div class="text-xl flex-1 relative bottom-1">
                <p class="text-gray-800 mb-6 text-center">Approve / Reject</p>
                <div class="flex justify-center items-center">
                  <div
                    @click="
                      () => {
                        profileToBeApproved = profile;
                        openModal('confirm-approve');
                      }
                    "
                    class="cursor-pointer mr-8"
                    ><i
                      class="fa-light fa-circle-check text-green-600 text-4xl"
                    ></i
                  ></div>
                  <div
                    @click="
                      () => {
                        profileToBeApproved = profile;
                        showApproveModal = true;
                        answer = false;
                        openModal('deny-approve');
                      }
                    "
                    class="cursor-pointer"
                    ><i
                      class="fa-light fa-circle-xmark text-4xl text-red-600"
                    ></i
                  ></div>
                </div>
              </div>
            </template>
          </v-table-mobile-cell>
        </div>

        <p class="text-2xl lg:p-5 mt-6 pl-4 pb-2 lg:pl-0">
          {{ approvalsFiltered.length ? page * pageSize + 1 : 0 }} to
          {{ Math.min((page + 1) * pageSize, approvalsFiltered.length) }} of
          {{
            approvalsFiltered.length + (searchString !== '' ? ' filtered' : '')
          }}
          results
        </p>
        <div class="pl-4 lg:pl-0" v-if="approvalsFiltered.length > pageSize">
          <div class="pl-4 lg:pl-0" v-if="profilesFiltered.length > pageSize">
            <v-pagnate
              :maxOptions="5"
              :pageNumber="page"
              :totalItems="allApprovals.length"
              :splitBy="pageSize"
              @pageSelected="(p) => pageTo(p)"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Profile Preview -->
    <vue-final-modal
      :fit-parent="false"
      :name="'modal-preview'"
      v-model="showPreview"
      :esc-to-close="false"
    >
      <preview
        v-if="showPreview"
        @closePreview="
          () => {
            showPreview = false;
            previewProfile = null;
          }
        "
        :profileChanges="profileChanges"
        :profile="previewProfile.fullProfile"
        :globalPresets="globalPresets"
        :showPreview="showPreview"
      />
    </vue-final-modal>
    <v-modal modalName="confirm-approve" :title="'Approve Profile Changes'">
      <template #content>
        <div class="bg-white p-6 flex flex-col">
          <div class="text-2xl">
            <div class="text-left text-2xl p-12">
              <p style="color: gray">{{
                `You are about to approve the changes to ${profileToBeApproved.fullName}.
                `
              }}</p>
              <p class="text-brand-color font-bold mt-4"
                >This profile will be published as soon as the approval is
                confirmed.</p
              >
              <div class="h-16" />
              <div class="w-96">
                <pm-button @click="approveSubmit(true)"
                  >{{ approvingProfile ? 'Please wait...' : 'Approve' }}
                </pm-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-modal>
    <v-modal modalName="deny-approve" :title="'Decline Profile Changes'">
      <template #content>
        <div class="bg-white p-6 flex flex-col">
          <div class="text-2xl">
            <div class="text-left text-2xl p-12">
              <p style="color: gray">{{
                `You are about to decline the changes to ${profileToBeApproved.fullName}.
                `
              }}</p>
              <p class="text-brand-color font-bold mt-4"
                >The profile owner will get notified via email and will have to
                submit a new approval.</p
              >
              <div class="h-16" />
              <textarea
                placeholder="Feedback"
                v-model="feedback"
                class="h-64 p-6 text-black text-3xl border focus:border"
              />
              <div class="h-16" />
              <div class="w-96">
                <pm-button
                  :background="'outline'"
                  :danger="true"
                  @click="approveSubmit(false)"
                  >{{ approvingProfile ? 'Please wait...' : 'Decline' }}
                </pm-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-modal>
    <v-modal
      modalName="mobile-sort"
      :preventClose="true"
      :closeOnOutsideClick="true"
      classes="modal-content-full flex flex-col h-full justify-center"
    >
      <template #content>
        <v-mobile-sorting-panel
          :config="SORTING_CONFIG"
          :modelValue="getSortName()"
          modalName="mobile-sort"
          @sort="setSort"
        />
      </template>
    </v-modal>
  </div>
</template>

<script>
import {
  formatPath,
  formatImageUrl,
  controlStyling,
  cellStyling
} from '~/functions/utils-functions';
import pmButton from '~/components/ui/pm-button.vue';
import sortWrapper from '~/functions/wrappers/sort-wrapper';
import vProfileCard from '~/components/ui/v-profile-card.vue';
import vTableMobileCell from '~/components/ui/v-table-mobile-cell.vue';
import vMobileSortingPanel from '~/components/ui/v-mobile-sorting-panel.vue';
import vTableSort from '~/components/ui/v-table-sort.vue';
import { $vfm, VueFinalModal } from 'vue-final-modal';
import preview from '~/views/self-service/preview.vue';
import { computed, getCurrentInstance, reactive, ref, toRef, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import vModal from '~/components/functional/v-modal.vue';
import vPagnate from '~/components/functional/v-pagnate.vue';
import VSearchInput from '~/components/ui/v-search-input.vue';
import userState from '~/store/state-managers/user';
import vAutoComplete from '~/components/functional/v-autocomplete.vue';

const SORTING_CONFIG = [
  { name: 'NAME', key: 'fullName', title: 'A-Z' },
  { name: 'DIVISION', key: 'companyName', title: 'Division' },
  { name: 'ALIAS', key: 'alias', title: 'Alias' },
  { name: 'REQUESTDATE', key: 'publishedAt', title: 'Requested' }
];

export default {
  emits: ['fetchApprovals', 'toggleApprovalStatus', 'setApprovalFilters'],
  components: {
    pmButton,
    VueFinalModal,
    preview,
    vModal,
    vPagnate,
    vTableSort,
    vProfileCard,
    vTableMobileCell,
    vMobileSortingPanel,
    VSearchInput,
    vAutoComplete
  },
  props: {
    selfServiceFunctions: {
      type: Object,
      default: () => {}
    },
    userManagementFunctions: {
      type: Object,
      default: () => {}
    },
    allApprovals: {
      type: Array,
      default: () => []
    },
    useActiveApprovals: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object,
      default: () => {}
    },
    suggestedCompanies: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    // refs
    const usersApi = toRef(props, 'userManagementFunctions');
    const approvals = toRef(props, 'allApprovals');
    const router = useRouter();

    const userWrapper = reactive(userState());
    const profileChanges = ref({});
    const previewProfile = ref({});
    const showPreview = ref(false);
    const profileToBeApproved = ref({});
    const showApproveModal = ref(false);
    const feedback = ref('');
    const pageSize = ref(25);
    const page = ref(0);
    const filter = ref({
      company: ''
    });
    const store = useStore();
    const internalInstance = getCurrentInstance();
    const approvingProfile = ref(false);
    const searchString = ref('');
    const divisionFilter = ref('');
    const toast = internalInstance.appContext.config.globalProperties.$toast;
    // computed
    function timenow(date) {
      let now = new Date(date),
        h = now.getHours(),
        m = now.getMinutes(),
        s = now.getSeconds();

      if (m < 10) m = '0' + m;
      if (s < 10) s = '0' + s;
      return now.toLocaleDateString() + ' @ ' + h + ':' + m;
    }
    const availableCompanies = computed(() => {
      return store.state.supervisor.supervisorCompanies || [];
    });

    const approvalsFiltered = computed(() => {
      let filtered = approvalsSorted();
      if (searchString.value.trim()) {
        filtered = filtered.filter((p) =>
          p.fullName.toLowerCase().includes(searchString.value.toLowerCase())
        );
      }

      if (divisionFilter.value) {
        filtered = filtered.filter((p) => p.companyId == divisionFilter.value);
      }

      return filtered;
    });
    const approvalsOnPage = computed(() => {
      const onPage = approvalsFiltered.value.slice(
        page.value * pageSize.value,
        (page.value + 1) * pageSize.value
      );
      return onPage;
    });

    // methods
    async function approveSubmit(answer) {
      approvingProfile.value = true;

      const { success, message } = await usersApi.value.updateApproval({
        id: profileToBeApproved.value.approvalId,
        answer: answer,
        message: feedback.value || ''
      });
      if (success) {
        toast({
          success: true,
          message: `Profile ${answer ? 'Aproved!' : 'Declined!'}`
        });
      } else {
        toast({ success: false, message });
      }
      profileToBeApproved.value = {};
      approvingProfile.value = false;

      $vfm.hide('confirm-approve');
      $vfm.hide('deny-approve');

      reloadApprovals();
    }
    function showPreviewFor(approval) {
      previewProfile.value = approval;
      profileChanges.value = approval.profileChanges;
      showPreview.value = true;
    }

    function reloadApprovals(query = {}) {
      emit('fetchApprovals', query);
    }

    function pageTo(pageNum) {
      page.value = pageNum;
      window.scrollTo(0, 0);
    }
    function formatPathToSS(approval) {
      return formatPath('/self-service/', approval.fullProfile);
    }

    function goToProfile(path) {
      router.push(path);
    }
    function openModal(name) {
      $vfm.show(name);
    }
    function hideModal(name) {
      $vfm.hide(name);
    }
    const {
      getSortDirection,
      getSortedData: approvalsSorted,
      setSort,
      getSortName,
      onSort
    } = sortWrapper(SORTING_CONFIG, approvals, reactive);
    setSort('REQUESTDATE');
    onSort(() => pageTo(0));
    // events
    watch(searchString, () => {
      pageTo(0);
    });

    return {
      handleDivisionFilter(value) {
        emit('fetchCompanySuggestions', {
          'filters[description][$containsi]': value.filter,
          'pagination[page]': value.page
        });
      },
      filterStatsByDivision(division) {
        store.commit('supervisor/setDivisionFilter', division);
        reloadApprovals({ division });
      },
      userWrapper,
      formatPathToSS,
      SORTING_CONFIG,
      openModal,
      hideModal,
      goToProfile,
      getSortDirection,
      getSortName,
      setSort,
      previewProfile,
      showPreview,
      formatImageUrl,
      profileChanges,
      showApproveModal,
      filter,
      approvingProfile,
      page,
      pageSize,
      feedback,
      availableCompanies,
      approvals,
      approvalsOnPage,
      approveSubmit,
      profileToBeApproved,
      showPreviewFor,
      timenow,
      searchString,
      approvalsFiltered,
      divisionFilter,
      controlStyling,
      cellStyling
    };
  }
};
</script>

<style scoped>
@media screen and (min-width: 768px) {
  .approvals-table-ratio {
    grid-template-columns:
      minmax(250px, 2fr) minmax(10%, auto) minmax(10%, auto)
      minmax(10%, auto) 180px 180px;
  }
}
</style>
