import axios from 'axios';

const BASE_URL = process.env.VUE_APP_STRAPI_URL;

export default function manageUsersApi(jwt) {
  const addUser = async (data) => {
    try {
      const result = await axios({
        method: 'POST',
        url: `${BASE_URL}/profiles/create-profile`,
        headers: {
          Authorization: `Bearer ${jwt}`
        },
        data: {
          subscriptionType: 'enterprise',
          company: data.company,
          masterProfile: data.masterProfile,
          person: {
            first_name: data.name
          },
          profileAlias: data.profileAlias,
          contacts: [
            {
              contact_type: 'email',
              name: 'Email Address',
              value: data.email.toLowerCase(),
              key: 'email',
              icon: 'fa-{type} fal fa-envelope',
              enabled: true
            }
          ],
          userAccountEmail: data.email.toLowerCase(),
          profileName: data.name
        }
      });
      if (result?.data?.success)
        return {
          success: true,
          message: result.data.message
        };
      return { success: false, message: result.data.message };
    } catch (err) {
      return { success: false, message: err.message };
    }
  };
  const unpublish = async (profileId) => {
    try {
      const result = await axios({
        method: 'PUT',
        url: `${BASE_URL}/profiles/${profileId}/unpublish`,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      if (result.data)
        return {
          success: true
        };
      return { success: false, message: 'Unknown error' };
    } catch (err) {
      return { success: false, message: err.message };
    }
  };
  const deleteProfile = async (profileId) => {
    try {
      const result = await axios({
        method: 'DELETE',
        url: `${BASE_URL}/profiles/${profileId}`,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      if (result.data)
        return {
          success: true
        };
      return { success: false, message: 'Unknown error' };
    } catch (err) {
      return { success: false, message: err.message };
    }
  };
  const createApproval = async ({ profileId, companyId, update }) => {
    if (update) {
      try {
        await axios({
          method: 'PUT',
          url: `${BASE_URL}/approvals/single/${update}`,
          headers: {
            Authorization: `Bearer ${jwt}`
          },
          data: {
            approved: null,
            requestedAt: new Date().toISOString()
          }
        });
        return { success: true, id: update };
      } catch (err) {
        return { success: false, message: err.message };
      }
    } else {
      try {
        const approval = await axios({
          method: 'POST',
          url: `${BASE_URL}/approvals`,
          headers: {
            Authorization: `Bearer ${jwt}`
          },
          data: {
            targetProfile: profileId,
            requestedAt: new Date().toISOString(),
            company: companyId,
            profileId: profileId.toString()
          }
        });
        return { success: true, id: approval.data.id };
      } catch (err) {
        return { success: false, message: err.message };
      }
    }
  };

  const updateApproval = async ({
    id,
    answer,
    message = '',
    rebuild = true
  }) => {
    try {
      const result = await axios({
        method: 'PUT',
        url: `${BASE_URL}/approvals/respond/${id}`,
        headers: {
          Authorization: `Bearer ${jwt}`
        },
        data: {
          approved: answer,
          message,
          rebuild
        }
      });
      if (result.data.success) {
        return { success: true };
      } else {
        return { success: false, message: result.data.message };
      }
    } catch (err) {
      return { success: false, message: err.message };
    }
  };

  return {
    addUser,
    updateApproval,
    createApproval,
    unpublish,
    deleteProfile
  };
}
