<template>
  <div
    class="
      text-gray
      font-normal
      flex
      text-xl
      mr-6
      justify-start
      cursor-pointer
      md:p-5 md:pl-0
      lg:text-2xl
    "
    @click="sortEvent"
  >
    <span class="inline"> {{ title }} </span>

    <span class="float-right">
      <i
        class="fas ml-2"
        :class="
          direction == 'desc'
            ? iconDown
            : direction == 'asc'
            ? iconUp
            : iconNeutral
        "
    /></span>
  </div>
</template>

<script>
export default {
  emits: ['sort'],
  props: {
    title: {
      type: String,
      default: ''
    },
    direction: {
      type: String,
      default: ''
    },
    fieldName: {
      type: String,
      default: ''
    },
    iconDown: {
      type: String,
      default: 'fa-sort-alpha-down'
    },
    iconUp: {
      type: String,
      default: 'fa-sort-alpha-up-alt'
    },
    iconNeutral: {
      type: String,
      default: 'fa-sort-alt'
    }
  },
  methods: {
    sortEvent() {
      this.$emit('sort', this.fieldName);
    }
  }
};
</script>
