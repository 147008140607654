import {
  chain,
  findIndex,
  isEmpty,
  isNil,
  set,
  find,
  remove,
  cloneDeep,
  forEach,
  filter,
  map
} from 'lodash';

const getArrayByKeyPathAndFilter = (arr, keyPath, filterKey) =>
  filterKey
    ? chain(arr).get(keyPath).filter(filterKey).value()
    : chain(arr).get(keyPath).value();

const isSelected = (childArray, id, groupKey = 'id') =>
  chain(childArray).map(groupKey).includes(id).value();

const updateAt = (childArray, indexOrKeyFilter, value, valueKey = 'value') =>
  set(
    childArray[
    typeof indexOrKeyFilter == 'number'
      ? indexOrKeyFilter
      : findIndex(childArray, indexOrKeyFilter)
    ],
    valueKey,
    value
  );

function arrayMergeWrapper(
  child,
  master = null,
  keyPath = '',
  filterKey = { key: '' },
  groupKey = 'id',
  reactive = (s) => s,
  onChange = () => { }
) {
  // eslint-disable-next-line no-undef
  const profile_me_utils = ProfileMeUtils;
  const settings = {
    onChange: [onChange]
  };
  const state = reactive({
    childArray: getArrayByKeyPathAndFilter(child, keyPath, filterKey),
    masterArray: getArrayByKeyPathAndFilter(master, keyPath, filterKey)
  });
  const hasPlaceholder = () =>
    find(state.masterArray, { contactType: 'placeholder' }) != undefined;
  const isSelectedById = (id) => isSelected(state.childArray, id, groupKey);

  const triggerOnChange = (value, type, key) =>
    forEach(settings.onChange, (f) => f(value, type, key));

  const updateAtKey = (key, value, valueKey = 'value') => {
    updateAt(state.childArray, { [groupKey]: key }, value, valueKey);
    triggerOnChange(state.childArray, 'update', key);
  };

  const updateAtIndex = (index, value, valueKey = 'value') => {
    updateAt(state.childArray, index, value, valueKey);
    triggerOnChange(state.childArray, 'update', index);
  };

  const reOrderChild = () => {
    triggerOnChange(state.childArray, 'reorder');
  };
  const updateArrays = (child, master = null) => {
    state.childArray = getArrayByKeyPathAndFilter(child, keyPath, filterKey);
    state.masterArray = getArrayByKeyPathAndFilter(master, keyPath, filterKey);
  };
  const addFromParentToChild = (id) => {
    const item = find(state.masterArray, { [groupKey]: id });
    if (item)
      if (find(state.childArray, { [groupKey]: item[groupKey] }))
        remove(state.childArray, (i) => i[groupKey] == item[groupKey]);
      else state.childArray.push(item);
    triggerOnChange(state.childArray, 'inherited', item[groupKey]);
  };
  const addAllFromParentToChild = (
    mergeKeys,
    parentOverwriteChildValue = () => true,
    filterParent = () => true,
    filterChild = () => true,
    alwaysOverwriteKeys,
    keepChildEntries,
    prependMode = 'prepend',
    masterOrder = false
  ) => {
    if (state.masterArray) {
      const masterArray = cloneDeep(state.masterArray);
      const childArray = profile_me_utils.mergeArrayByKey(
        groupKey,
        profile_me_utils.removeNonInterceptedEntries(
          filter(state.childArray, ({ key }) => filterChild(key)),
          masterArray,
          keepChildEntries
        ),
        masterArray,
        {
          useMasterValue: parentOverwriteChildValue,
          filterMasterBy: filterParent,
          valueKeys: mergeKeys,
          alwaysOverwriteKeys,
          appendType: prependMode
        }
      );
      state.childArray = masterOrder
        ? profile_me_utils.reorderArrayByKey(childArray, masterArray, groupKey)
        : childArray;
      triggerOnChange(state.childArray, 'inherited');
    }
  };
  const addToChild = (item) => {
    if (item)
      if (hasPlaceholder())
        state.childArray.splice(
          findIndex(state.masterArray, { contactType: 'placeholder' }) +
          filter(
            state.childArray,
            (item) => !map(state.masterArray, 'key').includes(item.key)
          ).length,
          0,
          item
        );
      else state.childArray.push(item);

    triggerOnChange(state.childArray, 'add', item[groupKey]);
  };
  const updateWholeChildAtId = (item, id) => {
    if (item && id) {
      const updateId = findIndex(state.childArray, { [groupKey]: id });
      if (updateId >= 0) state.childArray[updateId] = item;
    }
    triggerOnChange(state.childArray, 'update', id);
  };
  const removeFromChild = (id) => {
    remove(state.childArray, { [groupKey]: id });
    triggerOnChange(state.childArray, 'remove', id);
  };
  const getDefaultFromMasterByKey = (
    key,
    valueKey,
    defaultPlaceholder = ''
  ) => {
    if (isEmpty(state.masterArray) || isNil(state.masterArray))
      return defaultPlaceholder;
    return chain(state.masterArray)
      .find({ key })
      .defaultTo({ [valueKey]: defaultPlaceholder })
      .value()[valueKey];
  };
  const getFromChildOrMaster = (key, valueKey, defaultPlaceholder = '') => {
    return chain(state.childArray)
      .find({ key })
      .defaultTo({
        [valueKey]: getDefaultFromMasterByKey(key, valueKey, defaultPlaceholder)
      })
      .value()[valueKey];
  };

  return {
    isSelectedById,
    reOrderChild,
    updateAtKey,
    updateAtIndex,
    updateWholeChildAtId,
    addToChild,
    removeFromChild,
    addFromParentToChild,
    getDefaultFromMasterByKey,
    getFromChildOrMaster,
    updateArrays,
    addAllFromParentToChild,
    triggerOnChange: (type, id) => triggerOnChange(state.childArray, type, id),
    keyPath,
    filterKey,
    setOnChange: (callback) => {
      settings.onChange = [callback];
    },
    addOnChange: (callback) => {
      settings.onChange.push(callback);
    },
    get hasParent() {
      return !isEmpty(state.masterArray) && !isNil(state.masterArray);
    },
    get hasChild() {
      return !isEmpty(state.childArray) && !isNil(state.childArray);
    },
    get isEmpty() {
      return isEmpty(state.childArray);
    },
    get childArray() {
      return state.childArray;
    },
    get masterArray() {
      return state.masterArray;
    },
    get hasPlaceholder() {
      return hasPlaceholder;
    },
    isInherited(key) {
      return find(state.masterArray, { key }) != undefined;
    },
    allowOrdering(key) {
      if (find(state.masterArray, { contactType: 'placeholder' }) == undefined)
        return true;
      return find(state.masterArray, { key }) == undefined;
    },
    getChildItem(filterObject) {
      return find(state.childArray, filterObject);
    },
    getMasterItem(filterObject) {
      return find(state.masterArray, filterObject);
    },
    state
  };
}

export { arrayMergeWrapper, isSelected, updateAt };
