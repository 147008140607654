<template>
  <div class="z-50 bg-white flex flex-col justify-center w-full h-full">
    <div class="flex-initial">
      <div class="loadingscreen-loader mx-auto w-72 h-72 md:w-96 md:h-96">
        <svg
          viewBox="0 0 120 120"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle class="load one" cx="60" cy="60" r="40" />
          <circle class="load two" cx="60" cy="60" r="40" />
          <circle class="load three" cx="60" cy="60" r="40" />
          <g>
            <circle class="point one" cx="45" cy="70" r="5" />
            <circle class="point two" cx="60" cy="70" r="5" />
            <circle class="point three" cx="75" cy="70" r="5" />
          </g>
        </svg>
      </div>
      <p
        v-if="!hideText"
        class="
          loadingscreen-text
          text-center
          font-bold
          text-5xl
          md:text-5xl
          mb-3
        "
        >Just a moment...</p
      >
      <p
        v-if="!hideText"
        class="loadingscreen-text text-center text-3xl md:text-5xl"
        >While we fetch your data</p
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideText: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss">
p.loadingscreen-text {
  color: #1f89a9;
}

$color1: #2389a9;
$color2: #41b1d4;
$color3: #157188;

.loadingscreen-loader {
  svg {
    width: 100%;
    fill: none;
  }

  .load {
    transform-origin: 50% 50%;
    stroke-dasharray: 570;
    stroke-width: 15;
    &.one {
      stroke: $color3;
      animation: load 1.5s infinite;
    }
    &.two {
      stroke: $color2;
      animation: load 1.5s infinite;
      animation-delay: 0.1s;
    }
    &.three {
      stroke: $color1;
      animation: load 1.5s infinite;
      animation-delay: 0.2s;
    }
  }

  .point {
    animation: bounce 1s infinite ease-in-out;
    &.one {
      fill: $color1;
      animation-delay: 0s;
    }
    &.two {
      fill: $color2;
      animation-delay: 0.1s;
    }
    &.three {
      fill: $color3;
      animation-delay: 0.2s;
    }
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15%);
  }
}

@keyframes load {
  0% {
    stroke-dashoffset: 570;
  }
  50% {
    stroke-dashoffset: 530;
  }
  100% {
    stroke-dashoffset: 570;
    transform: rotate(360deg);
  }
}
</style>