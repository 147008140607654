<template>
  <div
    class="bg-white flex flex-col"
    @click="hideDatePickerForOffer"
  >
    <div class="text-2xl">
      <div class="grid gap-4 p-6">
        <permission-item
          :permissions="permissions"
          :groupId="`${identifier}GroupImage`"
          :itemid="`${identifier}.${item.key}.image`"
        >
          <v-input-wrapper class="border">
            <div
              :style="`${item.imageUrl || item.url
                ? `background-image: url(${formatImageUrl(
                  item?.imageUrl || item?.url
                )});background-repeat: no-repeat; background-position: center;background-size: contain`
                : ''
                }`"
              class="h-72 flex justify-center align-center items-center"
            >
              <label
                for="image-gallery-input"
                :class="`bg-white p-4 text-4xl ${classes.blueHeader}`"
              >Upload<span
                  v-show="itemIsRequired(`${identifier}GroupImage`)"
                  class="ml-1 text-3xl text-red-600"
                >*
                </span></label>
              <input
                id="image-gallery-input"
                name="image-gallery-input.image"
                @change="(e) => {
                  item.imageUrl = createObjectURL(e.target.files[0]);
                  item.file = e.target.files[0];
                  setValueToNull('image-gallery-input');
                }
                  "
                accept="image/*"
                hidden
                type="file"
              />
            </div>
          </v-input-wrapper>
        </permission-item>
        <div class="px-4 border">
          <div class="h-12" />
          <permission-item
            :permissions="permissions"
            :groupId="`${identifier}GroupTitle`"
            :itemid="`${identifier}.${item.key}.title`"
          >
            <edit-text
                v-permissions="permissions"
                v-model="item.title"
                emptyString="Title"
                name="image-gallery-input.title"
                id="image-gallery-input.title"
                :isRequired="itemIsRequired(`${identifier}GroupTitle`)"
                :header="true"
                @change="(value) => changeEvent(value, 'title')"
              />
            </permission-item>
          <div class="h-8" />
          <permission-item
          :permissions="permissions"
          :groupId="`${identifier}GroupLink`"
            :itemid="`${identifier}.${item.key}.link`"
            >
            <edit-text
              v-model="item.link"
              v-permissions="permissions"
              id="image-gallery-input.link"
              type="url"
              emptyString="Add Url"
              placeholder="Link"
              @change="(value) => changeEvent(value, 'url')"
            />
            <p class="text-md text-gray-500">
              You can hyperlink your image to go to a specific URL when
              clicked/tapped.</p>
          </permission-item>
          <div class="h-8" />
          <permission-item
            v-show="item.type == 'offers'"
            :permissions="permissions"
            :groupId="`${identifier}GroupExpireAt`"
            :itemid="`${identifier}.${item.key}.expireAt`"
          >
            <p class="text-3xl">Expiry Date</p>
            <p class="text-md text-gray-500">
              You can set an expiry date for products and special offers, that will disappear on the date selected.</p>
            <v-input-wrapper class="mb-8 flex align-center items-center relative">
              <pm-button
                @click="toggleDatePickerForOffer"
                :compact="true"
                class="mt-4"
              ><i class="fa fa-calendar" /></pm-button>
              <div class="text-gray-600 mt-8 ml-6 text-2xl">{{
                expirationDateFormatted
              }} <span
                  v-if="item.expireAt"
                  @click="item.expireAt = undefined"
                  class="text-brand-color"
                >(clear)</span></div>
              <div
                @click="e => e.stopPropagation()"
                v-if="showCalender"
                class="absolute left-32 bottom-0"
              >
                <date-picker
                  v-model="item.expireAt"
                  mode="dateTime"
                  is24hr
                  @change="(value) => changeEvent(value, 'date')"
                ></date-picker>
              </div>
            </v-input-wrapper>
          </permission-item>
        </div>
        <pm-button
          class="my-8 w-1/2 md:w-1/4"
          compact
          @click="handleItem"
          :disabled="loading"
        >
          {{
            loading ? 'Please wait...' : item.id ? 'Save Image' : 'Create Image'
          }}
        </pm-button>
      </div>
    </div>
  </div>
</template>

<script>
import PmButton from '~/components/ui/pm-button.vue';
import vInputWrapper from '~/components/ui/v-input-wrapper.vue';
import { formatImageUrl } from '~/functions/utils-functions';
import PermissionItem from '~/components/functional/permission-item.vue';
import { DateTime } from 'luxon';
import EditText from '~/components/functional/edit-text.vue';

const defaultImageValue = (overwrite = {}) => ({
  id: '',
  title: '',
  image: '',
  url: '',
  expireAt: '',
  link: '',
  imageUrl: '',
  file: null,
  type: '',
  key: Date.now().toString(),
  ...overwrite,
});

export default {
  emits: ['handle', 'change'],
  components: { vInputWrapper, PmButton, PermissionItem, EditText },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    identifier: {
      type: String,
      default: ''
    },
    classes: {
      type: Object,
      default: () => { }
    },
    permissions: {
      type: Array,
      default: () => []
    },
    image: {
      type: Object,
      default: defaultImageValue
    }
  },
  data() {
    return {
      item: defaultImageValue(this.image),
      showCalender: false
    };
  },
  watch: {
    image() {
      this.item = defaultImageValue(this.image);
    }
  },
  computed: {
    expirationDateFormatted() {
      if (this.item.expireAt) {
        return DateTime.fromJSDate(this.item.expireAt).toFormat(
          'yyyy-MM-dd @ HH:mm'
        );
      }
      return 'No expiration date';
    }
  },
  methods: {
    itemIsRequired(itemid) {
      return !this.permissions.filter((e) => e.key === itemid)?.[0]?.optional;
    },
    handleItem() {
      this.$emit('handle', this.item);
      this.changeEvent(this.item, 'image');
    },
    setValueToNull(id) {
      document.getElementById(id).value = null;
    },
    toggleDatePickerForOffer(e) {
      e.stopPropagation();
      this.showCalender = !this.showCalender;
    },
    hideDatePickerForOffer() {
      this.showCalender = false;
    },
    changeEvent(value, type) {
      this.$emit('change', { value, type });
    },
    formatImageUrl,
    createObjectURL(object) {
      return window.URL
        ? window.URL.createObjectURL(object)
        : window.webkitURL.createObjectURL(object);
    }
  }
};
</script>

<style></style>