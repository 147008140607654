<template>
  <div
    class="border pt-10 bg-white flex flex-col"
    :class="[{ 'pb-10 px-8': !compact }, classes]"
  >
    <div>
      <div :class="{ 'px-8': compact }">
        <slot name="heading">
          <h2>{{ title }}</h2>
        </slot>
      </div>
      <div class="border-b mt-9" :class="{ 'mb-8 -mx-8': !compact }"></div>
    </div>
    <div class="flex-1">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    },
    compact: {
      type: Boolean,
      default: false
    }
  }
};
</script>
