<script setup>
import HrEmbed from '~/api/quill/hr-blot.class';
import axios from 'axios';
import Quill from 'quill';
const Delta = Quill.import('delta');
import Emitter from 'quill/core/emitter';
import Module from 'quill/core/module';
import isBase64String from '~/regex/base64-string.regex';
import { onMounted, ref, defineProps, computed, watch } from 'vue';
import SpecialClipboard from '~/api/quill/special-clipboard.class';
import { chain } from 'lodash';
const emits = defineEmits(['update:modelValue', 'change']);
const props = defineProps({
  initial: {
    type: String,
    default: ''
  },
  modelValue: {
    type: String,
    default: ''
  },
  profileId: { type: String, default: '' },
  enableScrollContainerHtml: {
    type: Boolean,
    default: true
  },
  placeholder: {
    type: String,
    default: 'Type here....'
  },
  toolbar: {
    type: Array,
    default: () => [
      'bold',
      'italic',
      'underline',
      { align: [] },
      { list: 'ordered' },
      { list: 'bullet' },
      'link',
      'image',
      'video',
      { color: [] },
      { background: [] },
      { script: 'super' },
      { script: 'sub' },
      { indent: '+1' },
      { indent: '-1' },
      { size: ['small', false, 'large', 'huge'] },
      { font: [] }
    ]
  }
});

const SESSION_JWT = `Bearer ${sessionStorage.getItem('_token')}`;
const CMS_TRACK_UPLOAD_URL = process.env.VUE_APP_DISABLE_UPLOAD
  ? null
  : `${process.env.VUE_APP_STRAPI_URL}/profile-mod-imgs`;
const CMS_AXIOS_OPTIONS = (contentType = null) => {
  let option = {
    headers: {
      Authorization: SESSION_JWT
    }
  };
  if (contentType) {
    option.headers['Content-Type'] = contentType;
  }
  return option;
};
async function sendCloudinaryRichTextEdit(_storeCloudinaryRichTextEdit) {
  return axios
    .post(
      CMS_TRACK_UPLOAD_URL,
      _storeCloudinaryRichTextEdit,
      CMS_AXIOS_OPTIONS('application/json')
    )
    .then((res) => res.data);
}
async function removeCloudinaryRichTextEdit(img_url) {
  return axios
    .delete(
      CMS_TRACK_UPLOAD_URL,
      { img_url, profile: props.profileId },
      CMS_AXIOS_OPTIONS('application/json')
    )
    .then((res) => res.data);
}
/**
 * @param {any[]} files
 * @returns {Promise<string[]>} list of urls
 */
async function uploadImageToCms(files) {
  uploading.value = {
    index: 0,
    total: files.length
  };
  const formData = new FormData();
  for (const file of files) {
    formData.append('files', file, file.name);
  }
  const uploadFile = await axios
    .post(
      `${process.env.VUE_APP_STRAPI_URL}/upload`,
      formData,
      CMS_AXIOS_OPTIONS()
    )
    .then((res) => res.data)
    .finally();
  let urls = [];
  for (const f of uploadFile) {
    uploading.value.index++;
    await sendCloudinaryRichTextEdit({
      profile: props.profileId,
      img_url: f.url,
      img_id: String(f.id)
    })
      .then(() => urls.push(f.url))
      .catch((e) => console.warn(e));
  }
  return urls;
}
function imageUploadHandler() {
  {
    let fileInput = this.container.querySelector('input.ql-image[type=file]');
    if (fileInput == null) {
      fileInput = document.createElement('input');
      fileInput.setAttribute('type', 'file');
      fileInput.setAttribute('multiple', 1);
      fileInput.setAttribute(
        'accept',
        this.quill.uploader.options.mimetypes.join(', ')
      );
      fileInput.classList.add('ql-image');
      fileInput.addEventListener('change', () => {
        const range = this.quill.getSelection(true);
        this.quill.uploader.upload(range, fileInput.files);
        fileInput.value = '';
      });
      this.container.appendChild(fileInput);
    }
    fileInput.click();
  }
}

class Uploader extends Module {
  constructor(quill, options) {
    super(quill, options);
    quill.root.addEventListener('drop', (e) => {
      e.preventDefault();
      let native = null;
      if (document.caretRangeFromPoint) {
        native = document.caretRangeFromPoint(e.clientX, e.clientY);
        // @ts-expect-error
      } else if (document.caretPositionFromPoint) {
        // @ts-expect-error
        const position = document.caretPositionFromPoint(e.clientX, e.clientY);
        native = document.createRange();
        native.setStart(position.offsetNode, position.offset);
        native.setEnd(position.offsetNode, position.offset);
      }
      const normalized = native && quill.selection.normalizeNative(native);
      if (normalized) {
        const range = quill.selection.normalizedToRange(normalized);
        if (e.dataTransfer?.files) {
          this.upload(range, e.dataTransfer.files);
        }
      }
    });
  }
  upload(range, files) {
    const uploads = [];
    Array.from(files).forEach((file) => {
      if (file && this.options.mimetypes?.includes(file.type)) {
        uploads.push(file);
      }
    });
    if (uploads.length > 0) {
      // @ts-expect-error Fix me later
      this.options.handler.call(this, range, uploads);
    }
  }
}

Uploader.DEFAULTS = {
  mimetypes: [
    'image/png',
    'image/jpeg',
    'image/webp',
    'image/gif',
    'image/svg+xml'
  ],
  handler(range, files) {
    const promises = uploadImageToCms(files).finally(
      () => (uploading.value = null)
    );
    promises.then((images) => {
      const update = images.reduce((delta, image) => {
        return delta.insert({
          image
        });
      }, new Delta().retain(range.index).delete(range.length));
      this.quill.updateContents(update, Emitter.sources.USER);
      this.quill.setSelection(
        range.index + images.length,
        Emitter.sources.SILENT
      );
    });
  }
};
function convertV1ToV2(text) {
  // Convert text to HTML element
  const div = document.createElement('div');
  div.innerHTML = text;

  // Find all <li> elements under <ul> tags
  const liElements = div.querySelectorAll('ul > li');

  // Add data-list="bullet" attribute to <li> elements under <ul> tags
  liElements.forEach((li) => {
    li.setAttribute('data-list', 'bullet');
  });

  const convertedText = chain(div.innerHTML)
    .replace(/<ul/g, '<ol')
    .replace(/<\/ul/g, '</ol')
    .value();

  return convertedText;
}

function assignModelValueToInnerHtml() {
  firstTimeLoad.value = true;
  quillEditor.value.root.innerHTML = convertV1ToV2(
    props.modelValue || props.initial
  );
}
const firstTimeLoad = ref(false);
const savingTimeoutId = ref();
const quillEditorRef = ref();
const quillEditor = ref();
const uploading = ref(null);
const val = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emits('update:modelValue', val);
  }
});
watch(
  () => props.modelValue,
  () => {
    if (quillEditor.value.root.innerHTML != props.modelValue) {
      assignModelValueToInnerHtml();
    }
  }
);
function initQuill() {
  try {
    Quill.register(
      {
        'modules/clipboard': SpecialClipboard,
        'modules/uploader': Uploader,
        'formats/hr': HrEmbed
      },
      true
    );
    const options = {
      placeholder: props.placeholder,
      modules: {
        toolbar: toolbarOptions
      },
      theme: 'snow',
      scrollingContainer: 'html'
    };
    if (!props.enableScrollContainerHtml) {
      delete options.scrollingContainer;
    }
    quillEditor.value = new Quill(quillEditorRef.value, options);
    if (props.modelValue || props.initial) {
      assignModelValueToInnerHtml();
    }
    quillEditor.value.on('text-change', async (_, oldDelta, source) => {
      if (source == 'user') {
        let currentContents = quillEditor.value.getContents();
        const diff = currentContents.diff(oldDelta);
        for (const op of diff.ops) {
          if (op && op.insert?.image && !isBase64String.test(op.insert.image)) {
            await removeCloudinaryRichTextEdit(op.insert.image);
          }
        }
      }
    });
    quillEditor.value.on('editor-change', (...arg) => {
      if (arg[0] != 'selection-change' && !firstTimeLoad.value) {
        saveTimeout();
      } else {
        firstTimeLoad.value = false;
      }
    });
  } catch (error) {
    console.warn(error);
  }
}
async function saveTimeout() {
  if (savingTimeoutId.value) {
    clearTimeout(savingTimeoutId.value);
  }
  savingTimeoutId.value = setTimeout(() => {
    val.value = quillEditor.value.container.firstChild.innerHTML;
    emits('change', { target: { name: 'rte' } });
  }, 400);
}
const toolbarOptions = {
  container: props.toolbar,
  handlers: {
    image: imageUploadHandler,
    hr: () => {
      if (!quillEditor.value) {
        return;
      }
      quillEditor.value.format('hr', true);
    }
  }
};

onMounted(initQuill);
</script>

<template>
  <div
    class="rte-select"
    style="width: 95%; max-width: 420px; position: relative"
  >
    <div ref="quillEditorRef"></div>
    <div
      v-if="uploading"
      class="z-50 fixed bottom-0 left-0 right-0 h-12 bg-black opacity-60"
    >
      <div class="text-lg text-white text-center">
        <span v-if="uploading.index == 0"
          >Preparing {{ uploading.total }}
          {{ uploading.total == 1 ? 'upload' : 'uploads' }}</span
        >
        <span v-else
          >Busy Uploading {{ uploading.index }} of {{ uploading.total }}</span
        >
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
@import url('https://cdn.jsdelivr.net/npm/quill@2.0.0-rc.4/dist/quill.snow.css');
@import url('../../quill-js.css');
</style>
