
<template>
  <!-- <div class="relative">
    <p
      :ref="fieldKey"
      class="
        absolute
        break-all
        pointer-events-none
        text_style
        top-0
        left-0
        text-3xl
        p-6
        opacity-0
      "
      >{{ value }}</p
    >
    <textarea
      :placeholder="placeholder || 'Type Here...'"
      :name="fieldKey"
      class="text_style border p-6 text-black text-3xl"
      @input="handelChange"
      :style="{ height }"
      :id="fieldKey"
      v-model="value"
    />
  </div> -->
  <textarea
    :name="fieldKey"
    :id="fieldKey"
    :placeholder="placeholder"
    v-model="value"
    @input="handelChange"
    class="text_style border p-6 text-black text-3xl resize-none"
  ></textarea>
</template>

<script>
export default {
  props: {
    fieldKey: {
      default: Date.now().toString(),
      type: String
    },
    placeholder: {
      default: '',
      type: String
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      value: this.modelValue,
      height: '100px'
    };
  },
  mounted() {
    // const h = this.$refs[this.fieldKey]?.clientHeight;
    // if (h < 50) {
    //   this.height = '50px';
    // } else {
    //   this.height = h + 'px';
    // }
  },
  watch: {
    modelValue(newValue) {
      this.value = newValue;
    }
  },
  methods: {
    handelChange() {
      this.$emit('update:modelValue', this.value);
      this.$emit('change');
    }
  }
};
</script>

<style>
.text_style {
  max-height: 300px;
  min-height: 150px;
  height: min-content;
  overflow: auto;
  resize: none;
}
</style>