<template>
  <div>
    <div
      class="array-merge-items relative w-full grid grid-cols-12 items-center border box-border min-h-52"
      :class="{ 'no-image': !image }"
    >
      <div
        class="col-span-1 py-2 h-full border-r escape-permission-no-edit flex justify-center items-center"
        :class="{ 'drag-handel cursor-pointer': dragHandel }"
      >
        <VerticalDots v-if="dragHandel" class="w-8 h-8 self-center" />
        <VerticalDots
          v-else
          class="w-8 h-8 self-center"
          color="text-gray-300"
        />
      </div>
      <div class="col-span-10 mx-4 py-2">
        <slot>
          <h3
            v-if="title"
            class="text-4xl md:text-3xl font-bold mb-6 overflow-ellipsis overflow-x-hidden"
            @click="stopProp"
          >
            <span class="truncate block">
              {{ title }}
            </span>
          </h3>
          <a
            v-if="isURL(link)"
            @click="stopProp"
            class="escape-permission-no-edit text-4xl md:text-3xl w-52"
            :href="link"
            target="_blank"
          >
            <span class="truncate block">
              {{ subtitle }}
            </span>
          </a>
        </slot>
      </div>
      <div v-if="image" class="h-full max-w-200px thumbnail-size">
        <img :src="image" class="thumbnail" />
      </div>
      <div
        class="col-span-1 flex flex-col items-center justify-around py-4 border-l h-full text-3xl md:text-2xl row-span-2 md:row-span-1"
      >
        <template v-if="allowEdit">
          <eye-switch
            :modelValue="enabled"
            @click="stopProp"
            @on-change="changeEnabled"
          />
          <v-modal
            :modalName="itemKey"
            v-if="allowDelete"
            :title="'Are you sure you want to delete this item?'"
          >
            <template #trigger="{ open }">
              <i
                @click="open"
                class="fa-light fa-trash-can cursor-pointer icon-gray hover-icon-red"
              ></i>
            </template>
            <template #content>
              <confirm-dialog
                message="Are you sure you want to delete"
                @confirm="removeItem"
                @cancel="closeModal('modal-wrapper')"
              />
            </template>
          </v-modal>
        </template>
        <span v-else class="text-3xl md:text-2xl fa-regular fa-lock"></span>
      </div>
    </div>
    <div @click="stopProp">
      <slot name="permissions"></slot>
    </div>
  </div>
</template>

<script>
import VerticalDots from '~/components/icons/vertical-dots.vue';
import VModal from '~/components/functional/v-modal.vue';
import confirmDialog from '~/components/ui/confirm-dialog.vue';
import eyeSwitch from '~/components/ui/eye-switch.vue';
import { $vfm } from 'vue-final-modal';
import { uniqueId } from 'lodash';

export default {
  components: {
    VerticalDots,
    VModal,
    eyeSwitch,
    confirmDialog
  },
  emits: ['removeItem', 'changeEnabled', 'change'],
  props: {
    itemKey: {
      type: String,
      default: uniqueId('array-item')
    },
    enabled: {
      type: Boolean,
      default: false
    },
    allowDelete: {
      type: Boolean,
      default: true
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    dragHandel: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    stopProp(e) {
      e.stopPropagation();
    },
    closeModal(name) {
      $vfm.hide(name);
    },
    removeItem() {
      this.$emit('removeItem');
      this.$emit('change');
      this.closeModal(this.itemKey);
    },
    changeEnabled() {
      this.$emit('changeEnabled');
      this.$emit('change');
    },
    isURL(url = '') {
      if (!url) return false;
      return /https:\/\/[\s\S]/g.test(url);
    }
  }
};
</script>

<style scoped>
@import url('../../self-service.css');

.min-h-52 {
  min-height: 150px;
}

.max-w-200px {
  max-width: 200px;
}

.permissions-no-edit:not(.escape-permission-no-edit) .text-brand-color {
  color: var(--brand-color);
}

.thumbnail-size {
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
  width: 168px;
  height: 94px;
  overflow: hidden;
  order: 1;
  align-self: self-start;
  justify-self: center;
}

@media screen and (min-width: 768px) {
  .thumbnail-size {
    order: unset;
    align-self: center;
    justify-self: self-end;
  }
}

.thumbnail-size img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
