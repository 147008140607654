<template>
  <div class="overflow-y-auto max-h-screen">
    <div class="grid grid-cols-2 md:grid-cols-3 gap-8 p-8">
      <div
        v-for="option in socialOptions"
        :key="option.title"
        :style="`height: fit-content; opacity: ${haveBeenSelected(option.title) ? '0.5' : '1'
          }; pointer-events: ${haveBeenSelected(option.title) ? 'none' : 'auto'}`"
        class="border flex p-6 hover-border cursor-pointer items-center"
        @click="onSelect(option)"
      >
        <i :class="option.icon + ' mx-6 text-3xl'" />
        <p class="text-4xl">{{ option.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { toRef } from '@vue/reactivity';
import { filter } from 'lodash';
export default {
  emits: ['close'],
  props: {
    socialOptions: {
      type: Array,
      default: () => [
        { title: 'Facebook', icon: 'fab fa-facebook-f' },
        { title: 'Youtube', icon: 'fa fa-youtube' },
        { title: 'X', icon: 'fab fa-x-twitter' },
        { title: 'Instagram', icon: 'fab fa-instagram' },
        { title: 'LinkedIn', icon: 'fab fa-linkedin-in' },
        { title: 'Snapchat', icon: 'fab fa-snapchat-ghost' },
        { title: 'Tumblr', icon: 'fab fa-tumblr' },
        { title: 'Pinterest', icon: 'fab fa-pinterest' },
        { title: 'Reddit', icon: 'fab fa-reddit-alien' },
        { title: 'TikTok', icon: 'fab fa-tiktok' },
        { title: 'Flickr', icon: 'fab fa-flickr' },
        { title: 'Spotify', icon: 'fab fa-spotify' },
        { title: 'SoundCloud', icon: 'fa-brands fa-soundcloud' },
        { title: 'Buzzsprout', icon: 'fak fa-buzzsprout' },
        { title: 'Vimeo', icon: 'fa-brands fa-vimeo-v' },
        { title: 'Clubhouse', icon: 'fa-solid fa-hand-wave' }
      ]
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const items = toRef(props, 'items');
    const close = () => {
      emit('close');
    };

    function onSelect(item) {
      emit('selected', item);
    }

    function haveBeenSelected(title) {
      if (filter(items.value, (i) => i.title == title).length > 0) {
        return true;
      }
      return false;
    }

    return {
      onSelect,
      haveBeenSelected,
      close
    };
  }
};
</script>

<style scoped>
.hover-border:hover {
  border-color: var(--brand-color);
}
</style>