import {
  hasIn,
  isEmpty,
  isNil,
  replace,
  template,
  omit,
  isUndefined
} from 'lodash';

const formatForURL = (obj) =>
  replace(
    replace(JSON.stringify(obj), new RegExp('{|}', 'g'), ''),
    new RegExp(':', 'g'),
    '='
  );
const parseForParams = (str) => {
  const params = JSON.parse(`{
    ${replace(str, new RegExp('=', 'g'), ':')}
  }`);
  if (params.alias) return template('/<%= report %>/<%= alias %>')(params);
  return template('/<%= report %>')(params);
};
const isFalse = (obj) => isNil(obj) && isEmpty(obj);
const authenticateWithStore = (store, session) =>
  hasIn(store, 'state.auth') &&
  !isFalse(store.state.auth.user) &&
  !isFalse(session._token) &&
  hasIn(store, 'state.auth.user.confirmed') &&
  store.state.auth.user.confirmed == true;
// Hide premium hardcode

const logoutRoute = (to) => ({
  name: 'Login',
  query: omit(
    {
      ...to.query,
      params: !isEmpty(to.params) ? formatForURL(to.params) : undefined
    },
    isUndefined
  )
});

export {
  isFalse,
  authenticateWithStore,
  parseForParams,
  formatForURL,
  logoutRoute
};
