<template>
  <div>
    <div
      v-show="title"
      class="py-10 flex items-center cursor-pointer"
      :class="titleBackground"
      @click="toggleAccordian"
    >
      <slot name="pre-title"></slot>
      <span class="summary-title text-4xl mx-16">
        <slot name="title">{{ title }}</slot>
      </span>
      <div class="flex-1"></div>

      <div
        class="summary-chevron-up flex mr-1 items-center"
        :class="{
          'summary-chevron-down': !inputValue,
          'summary-chevron-up': inputValue
        }"
      >
        <slot name="chevron">
          <div @click="stopProp">
            <slot name="controls"></slot>
          </div>
          <i
            class="fa-regular text-2xl font-bold pr-10"
            :class="{
              'fa-chevron-down down': !inputValue,
              'fa-chevron-up up': inputValue
            }"
          ></i>
        </slot>
      </div>
    </div>
    <details ref="accordianRef" :open="inputValue">
      <summary />
      <div st :class="`summary-content ${padding || 'p-8'}`">
        <slot></slot>
      </div>
    </details>
  </div>
</template>

<script>
export default {
  emits: ['accordianClose', 'update:modelValue'],
  props: {
    modelValue: Boolean,
    title: {
      type: String,
      default: ''
    },
    open: {
      type: Boolean,
      default: false
    },
    padding: {
      type: String,
      default: 'p-8'
    },
    titleBackground: {
      type: String,
      default: 'bg-gray-200'
    }
  },
  data() {
    return {
      inputValue: this.modelValue || this.open
    };
  },
  methods: {
    stopProp(e) {
      e.stopPropagation();
    },
    closeAccordian() {
      this.$refs['accordianRef'].open = false;
    },
    toggleAccordian(e) {
      e.stopPropagation();
      this.inputValue = !this.inputValue;
      this.$emit('update:modelValue', this.inputValue);
    }
  },
  watch: {
    modelValue() {
      this.inputValue = this.modelValue;
    },
    open() {
      this.inputValue = this.open;
    }
  },
  expose: ['closeAccordian']
};
</script>

<style lang="scss" scoped>
details {
  .summary-title {
    user-select: none;
  }

  &:hover {
    cursor: pointer;
  }

  .summary-content {
    cursor: default;
  }

  summary {
    position: relative;
    list-style: none;

    &:focus {
      outline: none;
    }

    &:hover .summary-chevron-up .up {
      opacity: 0.5;
    }
  }

  .summary-chevron-up .up {
    opacity: 1;
  }

  .summary-chevron-up,
  .summary-chevron-down {
    .up,
    .down {
      display: block;
    }
  }

  summary::-webkit-details-marker {
    display: none;
  }
}
</style>
