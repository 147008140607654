<template>
  <div class="bg-white flex flex-col" @click="closeThings">
    <div class="text-2xl">
      <div class="px-16 pt-6">
        <p class="text-gray-500 my-4"
          >The user will receive an email link, and once they complete the sign
          up process their profile can be managed in the user section.</p
        >
        <v-input-wrapper class="my-6 border-b">
          <input
            type="text"
            v-model="newUser.name"
            :class="styles.inputs"
            name="firstname"
            id="firstname"
            placeholder=" "
          />
          <label
            for="firstname"
            @click="focusElement('firstname')"
            :class="`floating-label ${styles.placeholder}`"
            >Full Name <span class="text-red-600">*</span></label
          >
        </v-input-wrapper>

        <v-input-wrapper class="my-8 border-b">
          <input
            type="text"
            v-model="newUser.email"
            :class="styles.inputs"
            name="emailaddress"
            id="emailaddress"
            placeholder=" "
          />
          <label
            for="emailaddress"
            @click="focusElement('emailaddress')"
            :class="`floating-label ${styles.placeholder}`"
            >Email Address <span class="text-red-600">*</span></label
          >
        </v-input-wrapper>

        <!-- @keydown="isLetter" -->
        <v-input-wrapper class="my-8 border-b">
          <input
            v-model="profileAlias"
            @input="checkProfileAliasEvent"
            @paste="pasteEvent"
            type="text"
            :class="styles.inputs"
            name="profilealias"
            id="profilealias"
            placeholder=" "
          />
          <label
            for="profilealias"
            @click="focusElement('profilealias')"
            :class="`floating-label ${styles.placeholder}`"
            >Profile Alias</label
          >
        </v-input-wrapper>
        <v-input-wrapper v-if="availableCompanies.length" class="my-6 border-b">
          <select
            :value="newUser.company"
            @change="changeCompany"
            :class="styles.inputs"
            class="bg-white"
            name="company"
            id="company"
            placeholder=" "
          >
            <option class="text-slate-500" key="000" value=""
              >Select option</option
            >
            <option
              v-for="comp of availableCompanies"
              :key="comp.id"
              :value="comp.id"
              >{{ comp.description }}</option
            >
          </select>
          <label
            for="company"
            @click="focusElement('company')"
            :class="`floating-label ${styles.placeholder}`"
            >Division <span class="text-red-600">*</span></label
          >
        </v-input-wrapper>
        <v-auto-complete
          v-else
          class="mb-4 flex-1 xl:mr-6 xl:mb-0"
          placeholder="Select Division"
          labelKey="description"
          valueKey="id"
          position="top"
          :suggestions="suggestedCompaniesLimit"
          :value="newUser.company"
          :open="companiesListPopup"
          @update="handleDivisionFilter"
          @onSelect="filterStatsByDivision"
          @onCancel="filterStatsByDivision()"
        />
      </div>
      <div class="px-16 pb-6">
        <p
          class="text-red-500 my-4"
          v-if="!aliasTest(profileAlias) && profileAlias"
        >
          The alias can only have lowercase letters, numbers, a dash or an
          underscore.
        </p>
      </div>
      <div class="pb-16 pl-16 flex items-center">
        <pm-button
          class="w-96 inline"
          compact
          :disabled="
            !(
              aliasTest(profileAlias) &&
              !profileAliasTesting &&
              profileAliasApproved &&
              newUser.name &&
              newUser.email
            )
          "
          @click="createNewUser"
          >{{
            profileAliasTesting
              ? 'Checking Alias'
              : addingUser
              ? 'Please wait...'
              : 'Invite'
          }}
        </pm-button>

        <p class="my-4 ml-5" v-if="customDomain">
          URL:
          <span class="text-gray-500">
            https://{{ customDomain }}/{{ profileAlias || '[alias]' }}
          </span>
        </p>
        <p class="my-4 ml-5" v-else>
          URL:
          <span class="text-gray-500">
            https://{{ companyName || '[company subdomain]' }}.profileme.app/{{
              profileAlias || '[alias]'
            }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import vInputWrapper from '~/components/ui/v-input-wrapper.vue';
import manageUsersApi from '~/api/manageUsers';
import selfServiceApi from '~/api/self-service-v4';
import pmButton from '~/components/ui/pm-button.vue';
import vAutoComplete from '~/components/functional/v-autocomplete.vue';
import { get, take, sortBy, chain } from 'lodash';

export default {
  props: {
    suggestedCompanies: {
      type: Array,
      default: () => []
    }
  },
  components: { vInputWrapper, pmButton, vAutoComplete },
  emits: ['onComplete'],
  data() {
    return {
      showAddUser: false,
      showAddedUser: false,
      newUser: {
        email: '',
        name: '',
        company: '',
        masterProfile: '',
        profileAlias: ''
      },
      companyName: '',
      profileAlias: '',
      profileAliasTesting: false,
      profileAliasApproved: false,
      styles: {
        inputs: `p-6 text-black mr-32 text-4xl md:text-3xl placeholder:text-black active:border-none active:outline-none`,
        placeholder: `p-6 text-gray-600 mr-32 text-4xl mb-4 md:text-3xl placeholder:text-black active:border-none active:outline-none`,
        header: 'font-bold text-4xl md:text-3xl text-black py-2',
        subHeader: 'text-4xl md:text-3xl text-black',
        blueHeader: 'text-brand-color bg-transparent text-3xl  cursor-pointer',
        placeHolder: 'text-4xl md:text-3xl text-black mb-4',
        buttonSize: `md:w-max fill-available px-24 py-10 md:px-16 md:py-6 mt-6 text-4xl`
      },
      addedUserPath: '',
      addingUser: false,
      selfServiceApi: selfServiceApi(sessionStorage.getItem('_token')),
      usersApi: manageUsersApi(sessionStorage.getItem('_token'))
    };
  },
  computed: {
    availableCompanies() {
      return sortBy(
        this.$store.state.supervisor.supervisorCompanies || [],
        'description'
      );
    },
    suggestedCompaniesLimit() {
      return take(this.suggestedCompanies, 10);
    },
    customDomain() {
      return this.$store.state.whiteLabel?.profileDomain;
    }
  },
  methods: {
    aliasTest() {
      return /^(?!.*[-_.]{2})[a-z0-9_.-]+$/.test(this.profileAlias);
    },
    closeThings() {
      document.getElementsByTagName('body')[0].click();
    },
    handleDivisionFilter(value) {
      this.$emit('fetchCompanySuggestions', value);
    },
    filterStatsByDivision(division) {
      this.newUser.company = division.id;
      this.companyName = division.name;
      this.newUser.masterProfile = get(division, 'masterProfiles[0].id');
    },
    changeCompany(e) {
      this.newUser.company = e.target.value;
      const company = this.$store.state.supervisor.supervisorCompanies.filter(
        (c) => c.id == e.target.value
      );
      this.companyName =
        get(company, '[0].customDomainUrl') ||
        get(company, '[0].name', '[company subdomain]');
      this.newUser.masterProfile = get(company, '[0].masterProfiles[0].id');
    },
    focusElement(el) {
      document.getElementById(el).focus();
    },
    // make subscription plan according to add-user
    async createNewUser() {
      if (!this.newUser.email.trim()) {
        return this.$showError('Please enter an email address');
      }
      if (!this.newUser.name.trim()) {
        return this.$showError('Please enter a full name');
      }
      if (!this.newUser.company) {
        return this.$showError('Please select a devision');
      }
      this.addingUser = true;
      this.newUser.profileAlias = this.profileAlias;
      const result = await this.usersApi.addUser(this.newUser);

      if (!result.success) {
        this.addingUser = false;
        return this.$showError(result.message);
      }

      this.addingUser = false;
      this.newUser = {
        email: '',
        name: '',
        company: '',
        masterProfile: '',
        profileAlias: ''
      };
      this.companyName = '';
      this.$emit('onComplete', result.message);
    },
    cleanAlias(alias) {
      return chain(alias)
        .trim()
        .toLower()
        .replace(/\s+/g, '-')
        .replace(/[-_]{2,}/g, '-')
        .replace(/([-_])+/g, '$1')
        .trim()
        .value();
    },
    pasteEvent(e) {
      const value = e.clipboardData.getData('text');
      e.preventDefault();
      this.profileAlias = this.cleanAlias(value);
      this.checkProfileAliasEvent({ target: { value } });
    },
    checkProfileAliasEvent(e) {
      const { value } = e.target;
      clearTimeout(this.debounceAlias);
      const clean = this.cleanAlias(value);
      if (clean && this.aliasTest(clean)) {
        this.profileAliasTesting = true;
        this.debounceAlias = setTimeout(
          () =>
            this.checkProfileAlias(
              clean,
              (profileAliasTesting, profileAliasApproved) => {
                this.profileAliasTesting = profileAliasTesting;
                this.profileAliasApproved = profileAliasApproved;
              },
              () => {
                this.profileAliasApproved = false;
              }
            ),
          900
        );
      } else {
        this.profileAliasTesting = false;
      }
    },
    async checkProfileAlias(
      value,
      callBack = () => {},
      failCallback = () => {}
    ) {
      try {
        const test = await this.selfServiceApi.testAlias(value);
        if (test.success) {
          if (test.message) {
            callBack(false, true);
          } else {
            this.$showError(`The alias \`${value}\` has already been taken.`);
            callBack(false, false);
          }
        } else {
          this.$showError(test.message);
          failCallback();
        }
      } catch (err) {
        this.$showError(err.message);
        failCallback();
      }
    }
  }
};
</script>

<style scoped lang="css">
.fit-content {
  width: max-content;
}

td {
  border-color: #dadee7;
}

.search-input-icon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-42%);
}

.restrict-w {
  max-width: 50px;
  min-width: 50px;
}

@media screen and (min-width: 1024px) {
  .flex-2 {
    flex: 2 1 0%;
  }
}

.btn-position {
  right: 30px;
  bottom: 30px;
  width: 30%;
}

.hide {
  width: 0 !important;
  opacity: 0;
}

input[type='checkbox'] {
  transform: scale(1.5);
}

.gallery-width {
  width: 20%;
}

@media screen and (max-width: 1200px) {
  .gallery-width {
    width: 25%;
  }
}

@media screen and (max-width: 900px) {
  .gallery-width {
    width: 40%;
  }
}

@media screen and (max-width: 768px) {
  .btn-position {
    left: 0;
    right: 0;
    bottom: 0;
    width: inherit;
  }

  .fill-available {
    width: -webkit-fill-available;
    width: -moz-available;
  }
}

@media screen and (max-width: 600px) {
  .gallery-width {
    width: 100%;
  }
}

.overlay-trigger .overlay-action {
  display: none;
}

.overlay-trigger:hover .overlay-action {
  display: initial;
}

input,
textarea,
select {
  width: -webkit-fill-available;
  width: -moz-available;
}

input::placeholder select::placeholder {
  font-size: 1.875rem;
  color: black;
  position: relative;
}

input:focus,
input:active,
input:focus-within {
  border: none;
  outline: none;
}

select:focus,
select:active,
select:focus-within {
  border: none;
  outline: none;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

textarea:focus,
textarea:active,
textarea:focus-within {
  outline: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

svg {
  fill: #999999;
}

.icon-selector-icon {
  position: relative;
  top: 2px;
  color: #999999;
  font-size: 30px;
  margin-right: 6px;
}

.icon-selector-icon:hover {
  color: #bf9413;
}

@media screen and (max-width: 768px) {
  .divider-shadow {
    box-shadow: inset 1px 1px 6px #00000029;
  }
}

.divider-shadow {
  box-shadow: inset 1px 1px 6px #00000029;
}

@media screen and (min-width: 768px) {
  .divider-shadow {
    box-shadow: inset 0 0 1px #00000029;
  }
}
</style>
