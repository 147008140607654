<template>
  <div class="relative">
    <div>
      <!-- ADD USERS -->
      <div class="absolute -top-28 right-6 xl:right-0">
        <v-modal
          class="add-users-modal"
          :modalName="'modal-add-user'"
          :title="'Invite new user'"
          @open="
            () => {
              if (!availableCompanies.length) filterStatsByDivision();
            }
          "
          @close="
            () => {
              if (!availableCompanies.length) filterStatsByDivision();
            }
          "
        >
          <template #trigger="{ open }">
            <div
              @click="open"
              class="text-center cursor-pointer flex justify-start items-center xl:justify-center"
            >
              <span class="mr-4 text-3-5xl font-bold">Add New User</span>
              <span
                class="rounded-full p-6 w-20 h-20 flex justify-center items-center text-center bg-brand-color"
              >
                <i class="fa-solid fa-plus text-white text-5xl"></i>
              </span>
            </div>
          </template>

          <template #content>
            <add-users-modal
              :suggestedCompanies="suggestedCompanies"
              @onComplete="(newUser) => reloadAfterNewUser(newUser)"
              @fetchCompanySuggestions="handleDivisionFilter"
            />
          </template>
        </v-modal>
        <v-modal :title="'Success!'" :modalName="'addedUserModal'">
          <template #content>
            <div class="p-12">
              <p class="text-2xl" style="color: gray"
                >The user has successfully been invited! An email has been sent
                to {{ newUser.email }} to sign up.</p
              >

              <div class="h-12" />
              <div class="w-96">
                <router-link
                  class="w-max p-3"
                  style="width: inherit; width: 50px"
                  :to="formatPathForEdit(newUser)"
                  @click="clickOnSuccess"
                >
                  <pm-button>Manage User </pm-button>
                </router-link>
              </div>
            </div>
          </template>
        </v-modal>
      </div>
      <slot></slot>
      <div
        v-if="profilesLoading"
        class="w-full z-10 xl:mr-6 xl:mb-0"
        :style="{ height: '500px' }"
      >
        <v-loader :hideText="true"></v-loader>
      </div>
      <div
        class="pb-10 xl:pb-4"
        :class="{ 'h-0 overflow-hidden': profilesLoading }"
      >
        <!-- Controls -->
        <div class="text-2xl" :class="controlStyling('xl')">
          <v-auto-complete
            class="mb-4 flex-1 xl:mr-6 xl:mb-0"
            placeholder="Select Division"
            labelKey="description"
            valueKey="id"
            :multiple="true"
            :suggestions="suggestedCompanies"
            :value="userWrapper.currentFilteredDivision.id"
            @update="handleDivisionFilter"
            @onSelect="filterStatsByDivision"
            @onCancel="filterStatsByDivision()"
          />
          <div class="w-auto items-center mr-6 justify-center hidden xl:flex">
            <button
              @click="togglePublicationStatus('published')"
              :class="`cursor-pointer p-4 border rounded-l-2xl ${
                publicationStatus == 'published'
                  ? 'bg-brand-color text-white'
                  : 'bg-gray-100'
              }`"
            >
              Published ({{ publishedProfilesCount }})
            </button>
            <button
              @click="togglePublicationStatus('unpublished')"
              :class="`cursor-pointer p-4 border ${
                publicationStatus == 'unpublished'
                  ? 'bg-brand-color text-white'
                  : 'bg-gray-100'
              }`"
            >
              Unpublished ({{ unpublishedProfilesCount }})
            </button>
            <button
              @click="togglePublicationStatus('all')"
              :class="`cursor-pointer p-4 border rounded-r-2xl ${
                publicationStatus == 'all'
                  ? 'bg-brand-color text-white'
                  : 'bg-gray-100'
              }`"
            >
              All ({{ allProfilesCount }})
            </button>
          </div>
          <v-search-input
            v-model="fullNameFilter"
            class="flex-1 xl:mr-6"
            title="Search users"
          />
          <pm-button
            size="small"
            :compact="true"
            class="w-60 rounded-2xl hidden xl:block"
            @click="download"
            >Download CSV
          </pm-button>
        </div>
        <!-- USER TABLE DESKTOP -->
        <div class="hidden relative xl:grid grid-cols-7 user-table-ratio">
          <v-table-sort
            fieldName="NAME"
            class="self-end"
            iconUp="fa-sort-alpha-down"
            iconDown="fa-sort-alpha-up-alt"
            :direction="getOrder('NAME')"
            title="A-Z"
            @sort="setOrder"
          />
          <v-table-sort
            fieldName="DIVISION"
            :direction="getOrder('DIVISION')"
            title="Division"
            class="self-end"
            iconUp="fa-sort-alpha-down"
            iconDown="fa-sort-alpha-up-alt"
            @sort="setOrder"
          />
          <v-table-sort
            fieldName="TEMPLATE"
            :direction="getOrder('TEMPLATE')"
            title="Template"
            class="self-end"
            iconUp="fa-sort-alpha-down"
            iconDown="fa-sort-alpha-up-alt"
            @sort="setOrder"
          />
          <v-table-sort
            fieldName="UPDATED_AT"
            :direction="getOrder('UPDATED_AT')"
            title="Last edited"
            class="self-end"
            iconUp="fa-sort-numeric-up-alt"
            iconDown="fa-sort-numeric-down-alt"
            @sort="setOrder"
          />
          <v-table-sort
            fieldName="VIEWS"
            :direction="getOrder('VIEWS')"
            title="Views (Lifetime)"
            class="self-end"
            iconUp="fa-sort-numeric-up-alt"
            iconDown="fa-sort-numeric-down-alt"
            @sort="setOrder"
          />

          <div></div>
          <div></div>

          <template v-for="profile in currentProfile.data" :key="profile.id">
            <!-- User Column -->
            <!-- Hide premium hardcode -->
            <div :class="cellStyling(true)">
              <v-profile-card
                :fullName="profile.fullName"
                :jobTitle="profile.jobTitle"
                :profileImage="formatImageUrl(profile.profileThumbnail)"
                :url="profile.url === 'No Url' ? '#' : profile.url"
                @clickProfile="goToProfile(formatPathForEdit(profile))"
              />
            </div>
            <!-- Division Column -->
            <div :class="cellStyling()">
              {{ profile.companyName }}
            </div>
            <!-- Template Column -->
            <div :class="cellStyling()">
              {{ profile.templateName || '' }}
            </div>
            <!-- Last Updated Column -->
            <div :class="cellStyling(true)">
              {{ timenow(profile.updatedAt) }}
            </div>
            <!-- ProfileView Column -->
            <div :class="cellStyling(true)">
              {{ profile.count }}
            </div>

            <!-- View Stats button column -->
            <div class="flex-wrap" :class="cellStyling(true)">
              <router-link class="w-max mb-4 mr-4" :to="formatPath(profile)">
                <pm-button size="slim">View stats</pm-button>
              </router-link>
              <!-- Hide premium hardcode -->
              <router-link class="w-max mb-4" :to="formatPathForEdit(profile)">
                <pm-button size="slim" :background="'outline'">Edit</pm-button>
              </router-link>
              <pm-button
                class="mx-3"
                size="slim"
                :background="'outline'"
                @click="buildProfile(profile.id)"
                >Publish Profile</pm-button
              >
            </div>

            <div :class="cellStyling(true, false)">
              <div
                class="cursor-pointer ml-4 px-12 xl:px-0"
                @click="
                  () => {
                    profileToBeDeleted = profile;
                    showModal('modal-confirm-delete');
                  }
                "
              >
                <i
                  class="fa-solid fa-trash-can text-3xl hover:text-red-600"
                ></i>
              </div>
              <router-link
                v-if="false"
                :to="formatPathForEditPermissions(profile)"
              >
                <div class="cursor-pointer ml-8 px-12 xl:px-0">
                  <i
                    class="fa-solid fa-lock-keyhole text-3xl hover:text-green-600"
                  ></i>
                </div>
              </router-link>
            </div>
          </template>
        </div>

        <div class="w-full items-center mb-6 justify-center flex xl:hidden">
          <button
            @click="togglePublicationStatus('published')"
            :class="`flex-1  cursor-pointer p-4 border rounded-l-2xl ${
              publicationStatus == 'published'
                ? 'bg-brand-color text-white'
                : 'bg-white'
            }`"
          >
            Published ({{ publishedProfilesCount }})
          </button>
          <button
            @click="togglePublicationStatus('unpublished')"
            :class="`flex-1 cursor-pointer p-4 border ${
              publicationStatus == 'unpublished'
                ? 'bg-brand-color text-white'
                : 'bg-white'
            }`"
          >
            Unpublished ({{ unpublishedProfilesCount }})
          </button>
          <button
            @click="togglePublicationStatus('all')"
            :class="`flex-1 cursor-pointer p-4 border rounded-r-2xl ${
              publicationStatus == 'all'
                ? 'bg-brand-color text-white'
                : 'bg-white'
            }`"
          >
            All ({{ allProfilesCount }})
          </button>
        </div>

        <div
          class="text-2xl bg-white border flex justify-between items-center p-4 mb-6 w-32 cursor-pointer xl:hidden"
          @click="showModal('mobile-sort')"
        >
          <span>Sort</span>
          <i class="fa-sharp fa-solid fa-caret-down"></i>
        </div>
        <!-- USER TABLE MOBILE -->
        <div class="block xl:hidden">
          <v-table-mobile-cell
            v-for="profile in currentProfile.data"
            class="mb-6"
            :key="profile.id"
          >
            <template #profile-card>
              <!-- Hide premium hardcode -->
              <v-profile-card
                :fullName="profile.fullName"
                :jobTitle="profile.jobTitle"
                :profileImage="formatImageUrl(profile.profileThumbnail)"
                :url="profile.url === 'No Url' ? '#' : profile.url"
                @clickProfile="goToProfile(formatPathForEdit(profile))"
              />
            </template>

            <template #preview-buttons>
              <router-link
                class="w-max p-3"
                style="width: inherit; width: 50px"
                :to="formatPathForEdit(profile)"
              >
                <pm-button
                  size="small"
                  style="width: inherit; width: 50px"
                  :background="'outline'"
                  >Edit</pm-button
                >
              </router-link>
              <router-link class="w-max my-4 pl-5" :to="formatPath(profile)">
                <pm-button size="small" @click="gotoStats">Stats</pm-button>
              </router-link>
              <pm-button
                class="ml-3"
                size="small"
                :background="'outline'"
                @click="buildProfile(profile.id)"
                >Publish</pm-button
              >
            </template>

            <template #content>
              <div class="text-xl pr-4">
                <p class="text-gray-800 mb-4">Division</p>
                <p class="text-gray-500 whitespace-nowrap">{{
                  profile.companyName
                }}</p>
              </div>
              <div class="text-xl pr-4">
                <p class="text-gray-800 mb-4">Template</p>
                <p class="text-gray-500 whitespace-nowrap">{{
                  profile.templateName || ''
                }}</p>
              </div>
              <div class="text-xl pr-4">
                <p class="text-gray-800 mb-4">Views (Lifetime)</p>
                <p class="text-gray-500 whitespace-nowrap">{{
                  profile.count
                }}</p>
              </div>
              <div class="text-xl">
                <p class="text-gray-800 mb-4">Last edited: &nbsp;</p>
                <p class="text-gray-500 whitespace-nowrap">{{
                  timenow(profile.updatedAt)
                }}</p>
              </div>
            </template>

            <template #action-buttons>
              <div
                style="width: -webkit-fill-available"
                class="row-span-2 py-1 w-full flex items-center justify-center relative left-2 cursor-pointer"
                @click="
                  () => {
                    profileToBeDeleted = profile;
                    showModal('modal-confirm-delete');
                  }
                "
              >
                <i
                  class="fa-solid fa-trash-can text-3xl hover:text-red-600"
                ></i>
              </div>
            </template>
          </v-table-mobile-cell>
        </div>

        <p class="text-2xl xl:p-5 mt-6 pl-4 pb-2 xl:pl-0"
          >{{ currentProfile.total ? page * pageSize + 1 : 0 }} to
          {{ Math.min((page + 1) * pageSize, currentProfile.total) }} of
          {{
            currentProfile.total + (fullNameFilter !== '' ? ' filtered' : '')
          }}
          results</p
        >
        <div class="pl-4 xl:pl-0" v-if="currentProfile.total > pageSize">
          <v-pagnate
            :maxOptions="5"
            :pageNumber="page"
            :totalItems="currentProfile.total"
            :splitBy="pageSize"
            @pageSelected="(p) => pageTo(p)"
          />
        </div>
      </div>
    </div>
    <v-modal
      modalName="modal-confirm-delete"
      :title="
        profileToBeDeleted.publishedAt == null
          ? 'Delete Permanently:'
          : 'Unpublish Profile'
      "
    >
      <template #content>
        <div class="bg-white flex flex-col">
          <div class="text-2xl">
            <div class="text-left text-2xl p-16">
              <p v-if="profileToBeDeleted.publishedAt != null">
                {{
                  `You are about to unpublish ${profileToBeDeleted.fullName}.
                You will still be able to republish this profile or permanently
                delete it in the unpublished section.`
                }}
              </p>

              <div class="text-left" v-else>
                <p style="color: gray" class="mb-8">
                  You are about to permanently delete
                  {{ profileToBeDeleted.fullName }}.
                </p>
                <p class="text-brand-color"
                  ><span class="text-error-color"
                    >The user linked to this profile is a user, the profile will
                    be deleted, but not the user. Please note that you will not
                    be able to recover this profile afterwards. </span
                  ><br />
                  <br />
                  If you would like the user to be deleted as well, please send
                  a request to support.
                </p>
              </div>

              <div class="h-12" />
              <div class="w-96">
                <pm-button
                  @click="deleteProfile"
                  :background="
                    profileToBeDeleted.publishedAt != null ? 'solid' : 'outline'
                  "
                  :danger="profileToBeDeleted.publishedAt == null"
                  >{{
                    deletingProfile
                      ? 'Please wait...'
                      : profileToBeDeleted.publishedAt != null
                      ? 'Confirm Unpublish'
                      : 'Delete Permanently'
                  }}
                </pm-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-modal>
    <v-modal
      modalName="mobile-sort"
      classes="modal-content-full"
      :closeOnOutsideClick="true"
    >
      <template #content>
        <v-mobile-sorting-panel
          :config="SORTING_CONFIG"
          :modelValue="orderBy"
          modalName="mobile-sort"
          @sort="setOrder"
        />
      </template>
    </v-modal>
  </div>
</template>

<script>
import {
  formatPath,
  cellStyling,
  controlStyling
} from '~/functions/utils-functions';
import {
  getNextDirectionReverse,
  getSortDirectionStatic
} from '~/functions/wrappers/sort-wrapper';
import { USERS_TABLE_PAGE_SIZE } from '~/constants/index';
import vLoader from '~/components/ui/v-loader.vue';
import pmButton from '~/components/ui/pm-button.vue';
import vTableSort from '~/components/ui/v-table-sort.vue';
import vProfileCard from '~/components/ui/v-profile-card.vue';
import vTableMobileCell from '~/components/ui/v-table-mobile-cell.vue';
import vMobileSortingPanel from '~/components/ui/v-mobile-sorting-panel.vue';

import logo from '~/assets/img/person.jpg';
import { $vfm } from 'vue-final-modal';
import manageUsersApi from '~/api/manageUsers';
import selfServiceApi from '~/api/self-service-v4';
import AddUsersModal from '~/components/functional/add-users-modal.vue';
import vModal from '~/components/functional/v-modal.vue';
import vPagnate from '~/components/functional/v-pagnate.vue';
import vAutoComplete from '~/components/functional/v-autocomplete.vue';
import userState from '~/store/state-managers/user';
// Hide premium hardcode
import { userRole, profilesXLSXFile } from '~/functions/utils-functions';
import VSearchInput from '~/components/ui/v-search-input.vue';
import { get, map, find } from 'lodash';
const SORTING_CONFIG = [
  { name: 'NAME', key: 'fullName', title: 'A-Z' },
  { name: 'DIVISION', key: 'companyName', title: 'Division' },
  { name: 'TEMPLATE', key: 'templateName', title: 'Template' },
  { name: 'VIEWS', key: 'count', title: 'Views' },
  { name: 'UPDATED_AT', key: 'updatedAt', title: 'Last edited' }
];

let debounce = null;

export default {
  emit: ['fetchCompanySuggestions', 'reloadProfiles', 'selectProfileForStats'],
  components: {
    vLoader,
    pmButton,
    vModal,
    AddUsersModal,
    vTableSort,
    vPagnate,
    vProfileCard,
    vTableMobileCell,
    vMobileSortingPanel,
    VSearchInput,
    vAutoComplete
  },
  props: {
    // Need to remove reports
    reports: {
      type: Object,
      default: () => {}
    },
    user: {
      type: Object,
      default: () => {}
    },
    profiles: {
      type: Object,
      default: () => {}
    },
    profileQueryHandler: {
      type: Object,
      default: () => {}
    },
    profilesLoading: {
      type: Boolean,
      default: false
    },
    fetchExcelData: {
      type: Function,
      default: () => []
    },
    suggestedCompanies: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ...this.reports,
      // Query values
      order: 'desc',
      orderBy: 'VIEWS',
      publicationStatus: 'published',
      pageSize: USERS_TABLE_PAGE_SIZE,
      fullNameFilter: this.profileQueryHandler.query.filter,
      page: 0,
      // User adding
      newUser: {},
      profileToBeDeleted: {},
      // Styling
      controlStyling,
      styles: {
        inputs: `p-6 text-black mr-32 text-4xl md:text-3xl placeholder:text-black active:border-none active:outline-none`,
        placeholder: `p-6 text-gray-600 mr-32 text-4xl mb-4 md:text-3xl placeholder:text-black active:border-none active:outline-none`,
        header: 'font-bold text-4xl md:text-3xl text-black py-2',
        subHeader: 'text-4xl md:text-3xl text-black',
        blueHeader: 'text-brand-color bg-transparent text-3xl  cursor-pointer',
        placeHolder: 'text-4xl md:text-3xl text-black mb-4',
        buttonSize: `md:w-max fill-available px-24 py-10 md:px-16 md:py-6 mt-6 text-4xl`
      },
      // Config
      SORTING_CONFIG,
      showConfirmDelete: false,
      deletingProfile: false,
      usersApi: manageUsersApi(sessionStorage.getItem('_token')),
      selfServiceApi: selfServiceApi(sessionStorage.getItem('_token')),
      userWrapper: userState(),
      logo
    };
  },
  computed: {
    divisionFilter() {
      return this.$store.state.supervisor.filterByDivision;
    },
    isSuperAdmin() {
      return userRole(this.$store.state.auth.user) === 'superadmin';
    },
    getJwt() {
      return sessionStorage.getItem('_token');
    },
    companyTitle() {
      return this.$store.state.whiteLabel.title;
    },
    availableCompanies() {
      return this.$store.state.supervisor.supervisorCompanies || [];
    },
    filteredCompanies() {
      return (
        map(this.divisionFilter, 'id').join(',') ||
        map(this.availableCompanies, 'id').join(',')
      );
    },
    currentProfile() {
      return (
        this.profiles[this.publicationStatus] || {
          total: 0,
          data: []
        }
      );
    },
    publishedProfilesCount() {
      return get(this.profiles, 'published.total', 0);
    },
    unpublishedProfilesCount() {
      return get(this.profiles, 'unpublished.total', 0);
    },
    allProfilesCount() {
      return get(this.profiles, 'all.total', 0);
    }
  },
  watch: {
    fullNameFilter() {
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        clearTimeout(debounce);
        console.log('filter change');
        this.profileQueryHandler.setQueryParam(
          'filter',
          this.fullNameFilter,
          this.page != 0
        );
        this.pageTo(0);
      }, 1000);
    },
    divisionFilter: {
      handler() {
        this.profileQueryHandler.setQueryParam(
          'companyId',
          this.filteredCompanies,
          this.page != 0
        );
        this.pageTo(0);
      },
      deep: true
    }
  },
  methods: {
    filterStatsByDivision(division) {
      this.$store.commit('supervisor/setDivisionFilter', division);
    },
    handleDivisionFilter(value) {
      this.$emit('fetchCompanySuggestions', {
        'filters[description][$containsi]': value.filter,
        'pagination[page]': value.page
      });
    },
    timenow(date) {
      let now = new Date(date),
        h = now.getHours(),
        m = now.getMinutes(),
        s = now.getSeconds();

      if (m < 10) m = '0' + m;
      if (s < 10) s = '0' + s;
      return now.toLocaleDateString() + ' @ ' + h + ':' + m;
    },
    cellStyling,
    setOrder(name) {
      const isNewName = this.orderBy != name;
      const reload =
        isNewName ||
        this.order != getNextDirectionReverse(this.order, isNewName);
      this.order = getNextDirectionReverse(this.order, isNewName);
      this.orderBy = name;
      this.profileQueryHandler.setQueryParam('order', this.order, true);
      this.profileQueryHandler.setQueryParam(
        'orderBy',
        find(SORTING_CONFIG, { name: this.orderBy }).key,
        true
      );
      if (reload) this.profileQueryHandler.reload();
      this.hideModal('mobile-sort');
    },
    getOrder(name) {
      return getSortDirectionStatic(name, this.orderBy, this.order);
    },
    togglePublicationStatus(status) {
      this.publicationStatus = status;
    },
    clickOnSuccess() {
      this.newUser = {};
    },
    showModal(name) {
      $vfm.show(name);
    },
    hideModal(name) {
      $vfm.hide(name);
    },
    reloadAfterNewUser(newUser) {
      this.newUser = newUser;
      this.$emit('reloadProfiles');
      $vfm.hide('modal-add-user');
      $vfm.show('addedUserModal');
    },
    async deleteProfile() {
      this.deletingProfile = true;
      if (this.profileToBeDeleted.publishedAt == null) {
        // hard delete
        const result = await this.usersApi.deleteProfile(
          this.profileToBeDeleted.id
        );

        if (result.success) {
          this.$showSuccess('Profile Deleted');
        } else {
          this.profileToBeDeleted = {};
          $vfm.hide('modal-confirm-delete');
          this.deletingProfile = false;

          this.$showError(result.message);
          return;
        }
      } else {
        // soft delete
        const result = await this.usersApi.unpublish(
          this.profileToBeDeleted.id
        );
        if (result.success) {
          this.$showSuccess('Profile Unpublished');
        } else {
          this.profileToBeDeleted = {};
          $vfm.hide('modal-confirm-delete');
          this.deletingProfile = false;

          this.$showError(result.message);
          return;
        }
      }
      this.deletingProfile = false;
      this.profileToBeDeleted = {};
      $vfm.hide('modal-confirm-delete');
      this.$emit('reloadProfiles');
    },
    focusElement(el) {
      document.getElementById(el).focus();
    },
    reloadData() {
      $vfm.hide('modal-add-user-success');
    },
    pageTo(pageNum, mute = false) {
      if (this.page != pageNum) {
        this.page = pageNum;
        this.profileQueryHandler.setQueryParam('page', this.page, mute);
        window.scrollTo(0, 0);
      }
    },
    formatPath(profile) {
      return formatPath('/statistics/', profile, this.$route.query.companyId);
    },
    gotoStats(profile) {
      this.$emit('selectProfileForStats', profile);
    },
    buildProfile(id) {
      this.selfServiceApi.buildProfile(id).then((response) => {
        if (response.success) {
          this.$showSuccess('Profile Published');
          this.$emit('reloadProfiles');
        } else this.$showError('Build error: ', response.message);
      });
    },
    formatPathForEdit(profile) {
      return formatPath('/self-service/', profile, this.$route.query.companyId);
    },
    formatPathForEditPermissions(profile) {
      return formatPath(
        '/manage-permissions/',
        profile,
        this.$route.query.companyId
      );
    },
    formatImageUrl(url) {
      if (url && !url.includes('http')) {
        return process.env.VUE_APP_STRAPI_URL + url;
      }
      return url;
    },
    goToProfile(path) {
      this.$router.push(path);
    },
    async download() {
      // TODO - call for download of profiles
      const date = new Date().toISOString().split('T')[0];
      this.fetchExcelData(
        {
          filter: this.fullNameFilter,
          companyId: this.filteredCompanies,
          isLive:
            this.publicationStatus == 'published'
              ? true
              : this.publicationStatus == 'unpublished'
              ? false
              : undefined
        },
        (profiles) => {
          const companies = profiles.data
            .map((profile) => profile.companyName)
            .filter((value, index, arr) => arr.indexOf(value) === index)
            .join(', ');
          const filename = `${this.companyTitle} Users ${date} (${companies}).xlsx`;
          this.downloadXLSX(filename, profiles.data);
        }
      );
    },
    downloadXLSX(fileName, profiles) {
      const a = document.createElement('a');
      a.href = profilesXLSXFile(profiles);
      a.download = fileName;
      a.click();
    }
  }
};
</script>

<style scoped>
@media screen and (min-width: 768px) {
  .user-table-ratio {
    grid-template-columns:
      minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1.1fr)
      140px 200px 80px;
  }
}
</style>
