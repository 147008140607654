import { map } from 'lodash';
import {
  getProfilesConfig,
  getSuggestedUsersConfig,
  getExcelDataConfig
} from '~/functions/setup/api-setup';
import apiQueryControllerWrapper from '~/functions/wrappers/api-query-controller-wrapper';
import dataWrapper from '~/functions/wrappers/data-wrapper';
import profilesWrapper from '~/functions/wrappers/list-filter-wrapper';
import userState from '~/store/state-managers/user';
import {
  USERS_TABLE_PAGE_SIZE,
  LAZY_SEARCH_PAGE_SIZE
} from '~/constants/index';

function useProfiles(companies, callback, reactive = (s) => s) {
  const { isSuperAdmin, isUser } = userState();
  const profilesState = reactive({
    loadingState: {
      profiles: true,
      profileSearch: false
    }
  });

  const profilesHandler = profilesWrapper(
    [],
    {
      all: (profile) => !profile.isMaster,
      masterProfiles: (profile) => profile.isMaster
    },
    reactive
  );

  const {
    publishedProfiles,
    unpublishedProfiles,
    allProfiles,
    fetchData: fetchProfileData
  } = dataWrapper({
    reports: getProfilesConfig(),
    onComplete: () => {
      profilesHandler.setList(allProfiles.value.masterProfiles);
      profilesState.loadingState.profiles = false;
      callback();
    }
  });

  const { suggestedProfiles, fetchData: fetchUserSuggestions } = dataWrapper({
    reports: getSuggestedUsersConfig(
      {
        orderBy: 'fullName',
        order: 'asc',
        limit: LAZY_SEARCH_PAGE_SIZE,
        page: 0,
        companyId: isSuperAdmin ? undefined : map(companies, 'id').join(','),
        isTemplate: false
      },
      isUser
    )
  });
  const { fetchData: fetchExcelData } = dataWrapper({
    reports: getExcelDataConfig()
  });

  const profileQueryHandler = apiQueryControllerWrapper({
    params: {
      orderBy: 'data',
      order: 'desc',
      limit: USERS_TABLE_PAGE_SIZE,
      page: 0,
      filter: '',
      companyId: isSuperAdmin ? undefined : map(companies, 'id').join(','),
      isTemplate: false
    },
    onChange: (query) => {
      profilesState.loadingState.profiles = true;
      fetchProfileData(query);
      callback();
    }
  });

  const resetUserSearch = () => {
    fetchUserSuggestions({});
  };

  return {
    profilesState,
    reloadProfiles: () => {
      profilesState.loadingState.profiles = true;
      fetchProfileData(profileQueryHandler.query);
    },
    profileQueryHandler,
    profilesHandler,
    publishedProfiles,
    unpublishedProfiles,
    allProfiles,
    suggestedProfiles,
    fetchUserSuggestions,
    fetchExcelData,
    resetUserSearch
  };
}

export default useProfiles;
