<template>
  <div class="w-full bg-white h-3/6 px-20 mt-20">
    <div class="flex justify-between items-center border-b pb-10 mb-10">
      <h3 class="leading-none text-3xl"> {{ title }} </h3>
      <i
        class="fa-thin fa-circle-xmark text-4xl cursor-pointer"
        @click="hide"
      ></i>
    </div>

    <h3
      class="
        text-3xl
        mb-20
        leading-none
        flex
        items-center
        justify-between
        cursor-pointer
      "
      v-for="sortItem of config"
      :key="sortItem.key"
      @click="
        () => {
          setSort(sortItem.name);
          hide('mobile-sort');
        }
      "
    >
      <span>{{ sortItem.title }}</span>
      <span
        class="inline-block w-10 h-10 border rounded-full p-2"
        :class="{ 'bg-brand-color': modelValue == sortItem.name }"
      >
      </span>
    </h3>
  </div>
</template>

<script>
import { $vfm } from 'vue-final-modal';
export default {
  emits: ['sort'],
  props: {
    modelValue: String,
    config: {
      type: Array,
      default: () => []
    },
    modalName: {
      type: String,
      default: 'modal'
    },
    title: {
      type: String,
      default: 'Sort by'
    }
  },
  methods: {
    hide() {
      $vfm.hide(this.modalName);
    },
    setSort(name) {
      this.$emit('sort', name);
    }
  }
};
</script>

<style>
</style>