import { get, first, chain } from 'lodash';
import { computed } from 'vue';
import { userRole } from '../../functions/utils-functions';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
export default () => {
  const route = useRoute();
  const store = useStore();
  const getUser = () => {
    return get(store, 'state.auth.user', '');
  };

  const getUserEmail = () => {
    return get(store, 'state.auth.user.email', '');
  };

  const getCurrentFilteredDivision = () => {
    return get(store, 'state.supervisor.filterByDivision', '');
  };

  const getCurrentFilteredProfile = () => {
    return get(store, 'state.supervisor.filterByProfile', '');
  };

  const user = computed(getUser);

  const supervisorCompanies = computed(
    () => store.state?.supervisor?.supervisorCompanies || []
  );

  const userCompanyId = computed(() => {
    return first(get(store, 'state.auth.user.companies', []))?.id;
  });

  const isUser = userRole(getUser()) == 'user';
  const isSuperAdmin = userRole(getUser()) == 'superadmin';
  const isSupervisor = userRole(getUser()) == 'supervisor';

  const getProfileId = () => {
    return chain(route.params.alias)
      .defaultTo(
        isUser
          ? get(store, 'state.auth.user.profile', '')
            ? get(store, 'state.auth.user.profile', '') + '_'
            : '_'
          : '_'
      )
      .split('_')
      .first()
      .value();
  };

  return {
    user,
    supervisorCompanies,
    isUser,
    isSupervisor,
    isSuperAdmin,
    getUser,
    getProfileId,
    getUserEmail,
    getCurrentFilteredDivision,
    getCurrentFilteredProfile,
    currentFilteredDivision: computed(getCurrentFilteredDivision),
    currentFilteredProfile: computed(getCurrentFilteredProfile),
    userRole: () => userRole(getUser()),
    userCompanyId
  };
};
