import { defaultThemesApiConfig, defaultThemesConfig } from '~/functions/setup/api-setup';
import apiWrapper from '~/functions/wrappers/api-wrapper';
import dataWrapper from '~/functions/wrappers/data-wrapper';

function useThemes() {
  const defaultThemesApiFunctions = apiWrapper(
    defaultThemesApiConfig,
    sessionStorage.getItem('_token')
  );

  const {
    defaultThemes,
    fetchData: fetchDefaultThemes
  } = dataWrapper({
    reports: defaultThemesConfig(defaultThemesApiFunctions)
  });
  fetchDefaultThemes();

  return {
    defaultThemes,
    fetchDefaultThemes
  };
}

export default useThemes;
