<template>
  <div class="text-center">
    <p class="text-3xl text-brand-color my-10"
      >This user is not active enough to show data.</p
    >
    <p class="text-3xl text-brand-color font-bold">Time to get active!</p>
    <p class="text-3xl text-brand-color font-bold"
      >Watch the Quick Start Guide...</p
    >
    <div
      class="youtube-container w-auto mx-auto mt-10 mb"
      :class="{ 'padding-for-youtube': !tutVideo }"
    >
      <iframe
        v-if="!tutVideo"
        class="bg-black"
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/vCYhH0PvNaE"
        title="YouTube video player"
        frameborder="0"
        allowfullscreen
      ></iframe>
      <video v-else :src="tutVideo" controls></video>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  computed: {
    tutVideo() {
      return this.$store.state.whiteLabel.video;
    }
  }
};
</script>
<style>
.youtube-container {
  max-width: 464px;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.padding-for-youtube {
  padding-bottom: min(56.25%, 261px);
}
</style>
