<template>
  <main class="
      main
      background
      overflow-hidden
      flex
      relative
      justify-center
      items-center
      w-ful
      h-screen
      l
      py-12
    ">
    <div class="container">
      <div class="flex flex-col justify-center items-center">
        <img
          class="logo-image"
          :src="icon"
          alt="Profile Me Logo"
        />
        <h3 class="text-white text-4xl font-light mb-12 mt-2 text-center uppercase">{{
          formHeader
        }}</h3>
      </div>
      <form @submit.prevent="handleFormSubmit">
        <div class="flex flex-col">
          <label
            class="text-white font-light opacity-60 text-3xl text-center"
            for="register-input"
          >{{ subheading }}</label>
          <input
            v-show="view != 'no-user'"
            :required="view != 'no-user'"
            id="register-input"
            v-model="formData.emailAddress"
            style="border-bottom: 1px white solid"
            class="
              bg-transparent
              border-b border-b-white border-b-solid
              text-3xl text-white
              pt-4
              mt-12
              rounded-none
              pb-1
              focus:outline-none
            "
            type="email"
          />
        </div>

        <pm-button
          v-if="!loading"
          class="uppercase"
          type="submit"
          background="solid-light"
          size="small"
          :gutters="true"
        >{{ buttonText }}</pm-button>
        <pm-button
          class="uppercase"
          v-else
          background="solid-light"
          size="small"
          :gutters="true"
        >Please wait...</pm-button>
        <!--         <div class="or-label flex justify-center items-center my-12">
          <hr class="text-white w-16" />
          <span class="text-white text-xl font-light mx-4">OR</span>
          <hr class="text-white w-16" />
        </div> -->
      </form>
      <need-help></need-help>
      <!-- 
      <a
        :href="getGoogleAuthUrl"
        class="
          google-login-link
          bg-transparent
          text-2xl
          w-full
          text-white
          flex flex-row
          justify-center
        "
        ><GoogleIcon class="mr-4" style="width: 20px; height: 20px" />CONTINUE
        WITH GOOGLE</a
      > -->
    </div>
  </main>
</template>

<script>
import authService from '~/api/auth';
import { useStore } from 'vuex';
//import GoogleIcon from '~/components/icons/google-icon.vue';
import pmButton from '~/components/ui/pm-button.vue';
import { computed } from 'vue';
import NeedHelp from '~/components/ui/need-help.vue';
import axios from 'axios';

const defaultLink = "https://hello.profileme.app/support";
const links = {
  profileme: defaultLink,
  discovery: "mailto:marketing_support@discovery.co.za?subject=Need Help on Hub&body=I need assistance on hub. I am experiencing the following issue..."
};
const buttonText = {
  profileme: "Website",
  discovery: "Support"
};
export default {
  setup() {
    const store = useStore();

    return {
      login: (user) => store.commit('auth/login', user),
      logout: () => {
        store.commit('auth/logout');
      },
      icon: computed(() =>
        store.state.whiteLabel.date
          ? store.state.whiteLabel.lightLogo
          : store.state.whiteLabel.lightIcon
      ),
      companyTitle: computed(() => store.state.whiteLabel.title),
      store
    };
  },
  components: {
    pmButton,
    NeedHelp
  },
  data: function () {
    const formData = {
      emailAddress: ''
    };
    let view = 'register';
    formData.emailAddress = this.$route.query.email;
    view = this.$route.query.view || 'register';
    view == 'no-user';
    return {
      loading: false,
      showPassword: false,
      view,
      // if both login and forgotPassword is false, show register form
      googleAuthUrl: process.env.VUE_APP_STRAPI_URL + '/connect/google',
      formData
    };
  },
  computed: {
    getGoogleAuthUrl: function () {
      if (this.loading) {
        return '#';
      }
      return this.googleAuthUrl;
    },
    formHeader: function () {
      if (this.view == 'no-user') {
        return `You are not a ${this.companyTitle} User`;
      }
      return `ACTIVATE ${this.companyTitle} HUB`.toUpperCase();
    },
    subheading: function () {
      if (this.view == 'no-user') {
        return `You are not registered as a ${this.companyTitle} User.`;
      }
      return 'Enter your email address to receive a verification code';
    },
    buttonText() {
      if (this.view == 'no-user') {
        return buttonText[this.companyTitle.toLowerCase()] || "Website";
      }
      return 'Continue';
    }
  },
  methods: {
    async registrationCompleted() {
      this.loading = true;
      let carryOn = false;
      await axios({
        method: 'GET',
        url: `${process.env.VUE_APP_STRAPI_URL}/profiles/register-completed?email=${encodeURIComponent(this.formData.emailAddress)}`
      }).then(res => {
        if (res.status == 200) {
          if (res.data.success == false) {
            switch (res.data.message) {
              case "must-register":
                this.$showSuccess('Complete Registration');
                carryOn = true;
                break;
              case "not-invited":
                this.$showError("You are not invited to Hub");
                this.view = 'no-user';
                break;
              case "not-exist":
                this.$showError("Credentials are incorrect or your not invited to Hub.");
                this.view = 'no-user';
                break;
              default:
                break;
            }
          } else {
            this.goToLogin();
          }
        }
      }).catch(e => console.warn(e))
        .finally(() => {
          this.loading = false;
        });
      return carryOn;
    },
    goToLogin() {
      if (!this.formData.emailAddress.trim()) {
        this.$router.push({ name: 'Login' });
      } else {
        this.$router.push({ name: 'Login', query: { email: this.formData.emailAddress.trim() } });
      }
    },
    async handleRegister() {
      if (this.view == 'no-user') {
        const link = document.createElement('a');
        link.href = links[this.companyTitle.toLowerCase()] || defaultLink;
        document.body.appendChild(link);
        return link.click();
      }
      if (!this.formData.emailAddress.trim()) {
        return this.$showError('Please enter an email address');
      }
      const carryOn = await this.registrationCompleted();
      if (!carryOn) {
        return;
      }
      this.loading = true;
      const result = await authService('').register(this.formData.emailAddress);
      if (result.success) {
        this.$showSuccess('Email sent with your OTP!');
        this.loading = false;
        this.$router.push(
          `/sign-in?verify=true&email=${encodeURIComponent(this.formData.emailAddress)}`
        );
        return;
      } else if (
        result.message ==
        'You are not registered as a ProfileMe User, please visit our website'
      ) {
        this.view = 'no-user';
        this.loading = false;
        return;
      }
      this.loading = false;
      return this.$showError(result.message);
    },
    handleFormSubmit() {
      this.handleRegister();
    }
  }
};
</script>
<style scoped>
.background {
  background-color: var(--brand-dark-color);
  min-height: 100vh;
}

.container {
  width: 22%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.show-password {
  position: absolute;
  top: 4px;
  padding: 5px;
  color: white;
  right: 12px;
  cursor: pointer;
}

.accent-color {
  color: #ffc507;
}

.logo-image {
  width: 120px;
}

@media screen and (max-width: 1200px) {
  .container {
    width: 30%;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    width: 40%;
  }
}

@media screen and (max-width: 800px) {
  .container {
    width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 80%;
  }
}

@media screen and (max-width: 350px) {
  .container {
    width: 90%;
  }
}

@media screen and (max-height: 600px) {
  .logo-image {
    width: 100px;
  }
}

@media screen and (max-height: 550px) {
  .logo-image {
    width: 50px;
  }
}
</style>
