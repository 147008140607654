import Quill from 'quill';
const BlockEmbed = Quill.import('blots/block/embed');

class HrEmbed extends BlockEmbed {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('style', "margin: 1rem 0 1rem 0;");
    return node;
  }

  static value() {
    return "";
  }

  html() {
    return '<hr />';
  }
}
HrEmbed.blotName = 'hr';
HrEmbed.className = 'ql-hr';
HrEmbed.tagName = 'hr';

export default HrEmbed;
