<template>
  <div :class="`${!edit ? 'cursor-disabled dim' : ''} ${show ? '' : 'hide'}`">
    <div :class="`relative ${!edit ? 'no-edit' : ''}`">
      <slot />
    </div>
  </div>
</template>

<script>
import { getPermissionsValue } from '~/functions/permissions-functions';
export default {
  props: {
    permissions: {
      default: () => [],
      type: Array
    },
    itemid: {
      default: '',
      type: String
    },
    groupId: {
      default: '',
      type: String
    }
  },
  methods: {
    findPermissionValue(key, defaultValue) {
      return getPermissionsValue(
        this.permissions,
        this.itemid || this.groupId,
        key,
        defaultValue
      );
    }
  },
  computed: {
    edit() {
      return this.findPermissionValue('edit', true);
    },
    show() {
      return this.findPermissionValue('show', true);
    }
  }
};
</script>

<style scoped>
.no-edit {
  pointer-events: none;
}
.cursor-disabled {
  cursor: not-allowed;
}
.dim {
  opacity: 0.7;
}
.hide {
  display: none;
}
</style>
