import axios from 'axios';
import { sanitize } from '~/functions/data-functions';
import { omit, reduce } from 'lodash';

export default function (
  config = [],
  jwt,
  { filter, format, callback, catchCallback, query } = {
    filter: (data) => data,
    format: (data) => data,
    callback: () => { },
    catchCallback: (e) => {
      console.error(e);
    },
    query: {}
  }
) {
  const genericGetWithQuery = (path, options) => {
    const usingFilter = options.filter ? options.filter : filter,
      usingFormatter = options.format ? options.format : format,
      usingCallback = options.callback ? options.callback : callback,
      usingCatchCallback = options.catchCallback
        ? options.catchCallback
        : catchCallback,
      usingPath = options.path ? options.path : '',
      usingQuery = options.query ? options.query : {};

    const _append_ = query._append_ || usingQuery._append_ || '';

    const searchString = new URLSearchParams(
      sanitize(omit({
        ...query,
        ...usingQuery
      }, '_append_'))
    ).toString();

    axios({
      method: 'GET',
      url: process.env.VUE_APP_STRAPI_URL + path + usingPath + searchString + _append_,
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    })
      .then(({ data }) => {
        usingCallback(usingFormatter(usingFilter(data)));
      })
      .catch(usingCatchCallback);
  };

  const apiFunctions = reduce(
    config,
    (acc, cur) => {
      acc[cur.name] = (options) => genericGetWithQuery(cur.path, options);
      return acc;
    },
    {}
  );

  return apiFunctions;
}
