<template>
  <v-input-wrapper
    class="mb-12 border-b flex items-center relative"
    :itemId="itemId"
  >
    <vue-google-autocomplete
      ref="mapsInput"
      id="map"
      :country="['za']"
      :name="name"
      @change="changeEvent"
      @input="inputEvent"
      :classname="inputClasses"
      :placeholder="placeholderUrl || ' '"
      @placechanged="getAddressData"
    >
    </vue-google-autocomplete>

    <label
      :for="name"
      :class="[
        `floating-label ${placeholderClasses}`,
        { active: placeholderUrl?.length }
      ]"
    >
      <slot></slot>
    </label>
    <permission-controls
      :permissions="permissions"
      :itemId="itemId"
      @updated="handlePermissionUpdate"
      v-show="mode == 'permissions' && mode !== 'hidden'"
    />
    <i
      v-show="mode != 'permissions' && mode !== 'hidden'"
      @click="focusElement(name)"
      class="fa-solid fa-pencil edit-icon"
    ></i>
  </v-input-wrapper>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import PermissionControls from '~/components/functional/permission-controls.vue';
export default {
  emits: ['update', 'updatePermissions'],
  components: {
    VueGoogleAutocomplete,
    PermissionControls
  },
  props: {
    address: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    itemId: {
      type: String,
      default: ''
    },
    permissions: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: 'default'
    },
    placeholderClasses: {
      type: String,
      default: ''
    },
    inputClasses: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      inputValue: this.modelValue
    };
  },
  watch: {
    address(nV) {
      this.updateCords(nV);
    }
  },
  mounted() {
    if (this.address) {
      this.updateCords(this.address);
    }
  },
  computed: {
    placeholderUrl() {
      try {
        const values = JSON.parse(this.placeholder);
        return values.url || '';
      } catch {
        return '';
      }
    }
  },
  methods: {
    changeEvent() {
      if (!this.$refs['mapsInput'].autocompleteText) {
        this.$emit('update', '');
      }
    },
    inputEvent(e) {
      const {
        target: { value }
      } = e;
      if (
        value.includes('https://maps.google.com/') ||
        value.includes('https://maps.app.goo.gl/') ||
        value.includes('https://goo.gl/maps/')
      )
        this.$emit('update', JSON.stringify({ url: value, title: value }));
    },
    handlePermissionUpdate(data) {
      this.$emit('updatePermissions', data);
    },
    updateCords(url) {
      try {
        const values = JSON.parse(url);
        this.$refs.mapsInput.update(values.title);
      } catch (_) {
        //ignore
      }
    },
    getAddressData(_, r) {
      this.$emit(
        'update',
        JSON.stringify({ url: r.url, title: r.formatted_address })
      );
    },
    focusElement(el) {
      document.getElementById(el).focus();
    }
  }
};
</script>
<style scoped>
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
</style>
