import { selfServiceApiConfig, selfServiceConfig } from '~/functions/setup/api-setup';
import apiWrapper from '~/functions/wrappers/api-wrapper';
import dataWrapper from '~/functions/wrappers/data-wrapper';

function useSelfService(onStartCallback = () => { }, callback = () => { }) {
  const selfServiceApiFunctions = apiWrapper(
    selfServiceApiConfig,
    sessionStorage.getItem('_token')
  );

  const { selfServiceProfile, fetchData: fetchSelfServiceProfile } =
    dataWrapper({
      reports: selfServiceConfig(selfServiceApiFunctions),
      onStart: onStartCallback,
      onComplete: callback
    });

  return {
    fetchSelfServiceProfile,
    selfServiceProfile
  };
}

export default useSelfService;
