<template>
  <div
    class="relative loading-image"
    :class="[containerClss, { 'loading-image-loader': loading }]"
  >
    <div
      v-if="!loading"
      :class="`relative ${containerClss}`"
    >
      <video
        style="height: auto; width: 50%;"
        v-if="videoTag && url"
        muted
        loop
        autoplay
        playsinline
      >
        <source
          :src="url"
          type="video/mp4"
        >
      </video>
      <img
        v-else-if="!url"
        alt="placeholder"
        :class="`object-cover ${imgClss}`"
        :src="placeholderUrl ? placeholderUrl : formatImageUrl($store.state.whiteLabel.profilePlaceholderImage)"
      />
      <img
        v-else
        alt="profile-image"
        :class="`${imgClss}`"
        :src="url"
      />
    </div>

    <div
      v-else
      class="
        loading-image-loader
        absolute
        top-0
        left-0
        w-full
        h-full
        flex
        bg-gray-400
        justify-center
        items-center
      "
    >
      <vue-loaders-ball-beat
        scale="0.7"
        color="white"
      />
    </div>
  </div>
</template>

<script>
import { formatImageUrl } from '~/functions/utils-functions';
export default {
  props: {
    videoTag: {
      default: false,
      type: Boolean
    },
    loading: {
      default: true,
      type: Boolean
    },
    url: {
      default: '',
      type: String
    },
    placeholderUrl: {
      default: '',
      type: String
    },
    imgClss: {
      default: '',
      type: String
    },
    containerClss: {
      default: '',
      type: String
    }
  },
  methods: {
    formatImageUrl
  }
};
</script>

<style>
.loading-image-loader {
  min-height: 8rem;
  min-width: 8rem;
}
</style>