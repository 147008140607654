import { chain } from 'lodash';
import { YouTubeLinkRegex } from '../index';
const normalYoutubeUrl = 'https://youtu.be/';
const embedYoutubeUrl = 'https://www.youtube.com/embed/';
const youtubeUrlPreviewImage =
  'https://img.youtube.com/vi/{{IMAGE_ID}}/hqdefault.jpg';
const iframeString =
  '<iframe loading="lazy" width="560" height="315" src="{{url}}" title="{{title}}" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

export function fetchYouTubeID(url) {
  var match = url.match(YouTubeLinkRegex);
  return (match && match[7].length == 11) ? match[7] : false;
}

export function convertYouTubeUrlToEmbed(url) {
  const ytId = fetchYouTubeID(url);
  return `${embedYoutubeUrl}${ytId}?autoplay=0&rel=0`;
}

export const convertEmbedToURL = (video) =>
  chain(video)
    .split('src="')
    .last()
    .split('" title')
    .first()
    .replace(embedYoutubeUrl, normalYoutubeUrl)
    .value();

export const convertURLToEmbed = (video, title) => {
  const ytId = fetchYouTubeID(video);
  return ytId
    ? chain(iframeString)
      .replace(
        '{{url}}',
        `${embedYoutubeUrl}${ytId}`
      )
      .replace('{{title}}', title ?? "YouTube link")
      .value()
    : '';
};

export const convertURLToImagePreview = (video) => {
  const ytId = fetchYouTubeID(video);
  return ytId ? chain(youtubeUrlPreviewImage)
    .replace(
      '{{IMAGE_ID}}',
      ytId
    )
    .value() : "";
};