<template>
  <div class="line-chart-container flex h-128">
    <div
      class="h-full flex flex-col justify-between pt-8 pb-12 pr-2 text-right label-font"
    >
      <div v-for="label of ticks" :key="label">{{ label }}</div>
    </div>
    <div
      class="line-chart-canvas w-full h-full overflow-x-auto pb-4"
      ref="lineChartContainer"
    >
      <div class="line-graph-width h-full">
        <canvas ref="lineChart"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getLineConfig,
  formatLineChartTitle,
  chartProps,
  updateLineChart,
  chartStyling
} from '~/functions/chart-functions';
import { Chart, registerables } from 'chart.js';
import { get, map, reverse } from 'lodash';
import store from '~/store';
import { hexToRgb } from '~/functions/utils-functions';
Chart.register(...registerables);
/**
 *       {
          backgroundColor: (context) =>
            betweenIndex(context.dataIndex) ? 'white' : brandColor,
          hoverRadius: (context) => (betweenIndex(context.dataIndex) ? 8 : 6),
          radius: (context) => (betweenIndex(context.dataIndex) ? 8 : 6),
          hoverBorderWidth: (context) =>
            betweenIndex(context.dataIndex) ? 5 : 0,
          borderWidth: (context) => (betweenIndex(context.dataIndex) ? 5 : 0)
        }
            const betweenIndex = (index) => {
      const startIndex = indexOf(
        this.chartData.labels,
        this.start.toFormat('MMM yyyy')
      );
      const endIndex = indexOf(this.chartData.labels, this.end.toFormat('MMM yyyy'));
      return startIndex <= index && index <= endIndex;
    };
 */
export default {
  props: chartProps(),
  mounted() {
    this.initLineGraph(this.$refs.lineChart);
  },
  data() {
    const brandColor = store.state.whiteLabel?.brandColor
      ? hexToRgb(store.state.whiteLabel.brandColor)
      : 'rgb(35, 137, 169)';
    return {
      chart: null,
      chartStyling: chartStyling(),
      config: getLineConfig(
        this.chartData.labels,
        this.chartData.data,
        {
          backgroundColor: brandColor,
          hoverRadius: 6,
          radius: 6,
          hoverBorderWidth: 0,
          borderWidth: 0
        },
        store.state.whiteLabel.brandColor || undefined
      )
    };
  },
  watch: {
    chartData(newData) {
      updateLineChart(this.config, this.chart, newData);
      this.initLineGraph(this.$refs.lineChart);
    }
  },
  computed: {
    graphWidth() {
      return this.chartData.data.length * 48 + 'px';
    },
    ticks() {
      return reverse(
        map(get(this.chart, 'scales.y.ticks', []), (tick) => tick.label)
      );
    }
  },
  methods: {
    initLineGraph(ctx) {
      try {
        this.chart = new Chart(ctx, this.config);
      } catch (error) {
        console.log('Retrying line graph');
        setTimeout(() => this.initLineGraph(ctx), 100);
      }
      this.$nextTick(() => {
        this.$refs.lineChartContainer.scrollLeft =
          this.$refs.lineChartContainer.scrollWidth;
      });
    },
    getTitle: formatLineChartTitle
  }
};
</script>
<style>
.line-graph-width {
  width: v-bind(graphWidth);
}
.label-font {
  font-size: 12px;
  font-family: 'Lato, Semibold';
  font-style: 'normal';
  font-weight: 500;
  color: #646464;
}
</style>