import {
  getPermissionsValue,
  getClassForPermission
} from '~/functions/permissions-functions';

export default {
  mounted: (el, { value }) => {
    const permissionId =
      el.getAttribute('itemid') || el.getAttribute('groupid');
    el.classList.add(
      ...getClassForPermission({
        notEdit: getPermissionsValue(value, permissionId, 'edit', true),
        show: getPermissionsValue(value, permissionId, 'show', true),
        optional: getPermissionsValue(value, permissionId, 'optional', true)
      }, permissionId)
    );
    el.id = permissionId;
  }
};
