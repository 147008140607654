<template>
  <div class="slider relative h-5 text-left rounded-full">
    <div class="slider-display h-4 md:h-5 top-0 absolute">
      <div
        class="range bg-brand-color"
        :style="`left: ${startSlider}%; right: ${100 - endSlider}%`"
      ></div>
      <span
        class="thumb bg-white border-profile-me flex"
        :class="{ 'justify-end': startSlider > 90 }"
        :style="`left: ${startSlider}%`"
      >
        <div
          :class="{ 'md:hidden': startSlider < 15 || startSlider > 85 }"
          class="sign"
        >
          <span class="text-gray-900">{{ labelStart }}</span>
        </div>
      </span>
      <span
        class="thumb bg-white border-profile-me flex justify-end"
        :class="{ 'justify-start': endSlider < 10 }"
        :style="`left: ${endSlider}%`"
      >
        <div
          :class="{ 'md:hidden': endSlider < 15 || endSlider > 85 }"
          class="sign"
        >
          <span v-if="labelEnd != labelStart" class="text-gray-900">{{
            labelEnd
          }}</span>
        </div>
      </span>
      <!-- Labels -->
      <div
        class="sign-placeholder justify-start -ml-4 hidden md:flex"
        :style="`left: 0%`"
      >
        <span class="text-gray-900">{{ minPlaceholder }}</span>
      </div>
      <div
        class="sign-placeholder justify-end hidden md:flex"
        :style="`left: 100%`"
      >
        <span class="text-gray-900">{{ maxPlaceholder }}</span>
      </div>
    </div>
    <input
      type="range"
      tabindex="0"
      v-model="startSlider"
      min="0"
      max="100"
      step="1"
      @input="onInputStart"
      @change="onChange"
    />
    <input
      type="range"
      tabindex="0"
      v-model="endSlider"
      min="0"
      max="100"
      step="1"
      v-show="start != count(options) - 1"
      @change="onChange"
      @input="onInputEnd"
    />
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { first, last, indexOf, times, reduce } from 'lodash';
import { count } from '~/functions/data-functions';
import { beginningOfTime } from '~/functions/date-functions';
import { DateTime } from 'luxon';

export default {
  emits: ['onChange'],
  props: {
    options: {
      type: Array,
      default: () => []
    },
    startValue: {
      type: String,
      default: ''
    },
    endValue: {
      type: String,
      default: ''
    },
    formatOutput: {
      type: Function,
      default: (values) => values
    }
  },
  data() {
    const segments = times(
      this.options.length,
      (index) => (100 / this.options.length) * (index + 1)
    );
    return {
      segments,
      labelStart: first(this.options),
      labelEnd: last(this.options),
      start: 0,
      end: count(this.options) - 1,
      startSlider: 0,
      endSlider: 100
    };
  },
  created() {
    this.calculateStart(this.startValue);
    this.calculateEnd(this.endValue);
  },
  methods: {
    getSegmentIndex(value) {
      return reduce(
        this.segments,
        (index, seg) => (value > seg ? index + 1 : index),
        0
      );
    },
    onInputStart(ignoreLimits = false) {
      if (!ignoreLimits)
        this.startSlider = Math.min(this.startSlider, this.endSlider);
      this.start = this.getSegmentIndex(this.startSlider);
      this.labelStart = this.options[this.start];
    },
    onInputEnd(ignoreLimits = false) {
      if (!ignoreLimits)
        this.endSlider = Math.max(this.endSlider, this.startSlider);
      this.end = this.getSegmentIndex(this.endSlider);
      this.labelEnd = this.options[this.end];
    },
    onChange() {
      this.$emit(
        'onChange',
        this.formatOutput({
          start: this.options[this.start],
          end: this.options[this.end]
        })
      );
    },
    getSegmentFromIndex(seg, index, min, max) {
      return index != min && index != max
        ? seg * (index + 1) - seg / 2
        : max == index
        ? seg * (max + 1)
        : seg * min;
    },
    calculateStart(newDate) {
      const seg = 100 / this.options.length;
      const date =
        DateTime.fromFormat(newDate, 'MMMM yyyy') < beginningOfTime()
          ? beginningOfTime().toFormat('MMMM yyyy')
          : newDate;
      this.startSlider = this.getSegmentFromIndex(
        seg,
        indexOf(this.options, date),
        0,
        this.options.length - 1
      );
      this.onInputStart(true);
    },
    calculateEnd(newDate) {
      const seg = 100 / this.options.length;

      this.endSlider = this.getSegmentFromIndex(
        seg,
        indexOf(this.options, newDate),
        0,
        this.options.length - 1
      );
      this.onInputEnd(true);
    },
    count,
    last
  },
  watch: {
    startValue(newValue) {
      this.calculateStart(newValue);
    },
    endValue(newValue) {
      this.calculateEnd(newValue);
    }
  },
  computed: {
    minPlaceholder() {
      return first(this.options);
    },
    maxPlaceholder() {
      return last(this.options);
    }
  }
};
</script>
<style>
.slider-display {
  border-radius: 10px;
  background-color: #fff;
  left: 13px;
  right: 15px;
}

.slider .range {
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  height: 8px;
  border-radius: 14px;
}

.slider .thumb {
  position: absolute;
  top: -6px;
  height: 20px;
  width: 20px;
  border-color: var(--brand-color);
  border-style: solid;
  border-width: 5px;
  text-align: left;
  margin-left: -13px;
  cursor: pointer;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
  border-radius: 50%;
  box-sizing: border-box;
  outline: none;
}

@media screen and (min-width: 768px) {
  .slider .thumb {
    border-width: 7px;
    height: 30px;
    width: 30px;
  }
  .slider .range {
    top: 0;
    height: 14px;
  }
}

.slider .sign-placeholder,
.slider .sign {
  position: absolute;
  top: -39px;
  color: #fff;
  height: 28px;
  word-break: keep-all;
  white-space: nowrap;
}
.slider .sign-placeholder {
  width: 15px;
}

.slider .sign-placeholder,
span .slider .sign span {
  font-family: Lato, Semibold;
}

.slider .sign-placeholder span,
.slider .sign span {
  font-size: 15px;
  font-weight: 700;
  line-height: 28px;
}

.slider > input[type='range'] {
  position: absolute;
  pointer-events: none;
  -webkit-appearance: none;
  z-index: 3;
  height: 14px;
  width: 100%;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}

div.slider > input[type='range']::-ms-track {
  -webkit-appearance: none;
  background: transparent;
  color: transparent;
}

div.slider > input[type='range']::-moz-range-track {
  -moz-appearance: none;
  background: transparent;
  color: transparent;
}

div.slider > input[type='range']:focus::-webkit-slider-runnable-track {
  background: transparent;
  border: transparent;
}

div.slider > input[type='range']:focus {
  outline: none;
}

div.slider > input[type='range']::-ms-thumb {
  pointer-events: all;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 0px;
  border: 0 none;
  background: red;
}

div.slider > input[type='range']::-moz-range-thumb {
  pointer-events: all;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 0px;
  border: 0 none;
}

div.slider > input[type='range']::-webkit-slider-thumb {
  pointer-events: all;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 0px;
  border: 0 none;
  -webkit-appearance: none;
}

div.slider > input[type='range']::-ms-fill-lower {
  background: transparent;
  border: 0 none;
}

div.slider > input[type='range']::-ms-fill-upper {
  background: transparent;
  border: 0 none;
}

div.slider > input[type='range']::-ms-tooltip {
  display: none;
}
</style>