<template>
  <div>
    <!-- Hide premium hardcode -->
    <div
      v-if="
        !featureFlagHandler.isUser ||
        featureFlagHandler.allowedFeature['corporate']
      "
      class="flex box-border flex-wrap"
      :class="`${controlStyling('xl', fixedDirection)}`"
    >
      <v-auto-complete
        class="xl:mb-0 xl:mr-6 xl:order-4 xl:w-1/4"
        placeholder="Select Division"
        labelKey="description"
        valueKey="id"
        :suggestions="suggestedCompanies"
        :selections="divisionFilter"
        :multiple="true"
        @update="handleDivisionSuggestions"
        @onSelect="filterStatsByDivision"
        @onCancel="filterStatsByDivision()"
      />
      <pm-button
        v-if="
          featureFlagHandler.allowedFeature['corporate'] &&
          (viewingForId || featureFlagHandler.isUser)
        "
        class="rounded-2xl w-30 lg:w-72 mr-6 edit-profile-btn xl:order-1"
        size="medium"
        :compact="true"
        @click="goToProfile"
        >Edit profile</pm-button
      >
      <pm-button
        v-if="!featureFlagHandler.isUser && currentAlias"
        @click="goToStats"
        class="rounded-2xl w-auto mr-4 xl:order-2"
        size="medium"
        :compact="true"
        >Back to company stats</pm-button
      >
      <pm-button
        v-if="!featureFlagHandler.isUser && !viewingForId"
        @click="goToUsers"
        class="rounded-2xl w-auto whitespace-nowrap mr-4 xl:order-3 xl:mr-0 xl:w-72"
        size="medium"
        :compact="true"
        >Back to users</pm-button
      >
      <div class="hidden flex-1 xl:order-3 xl:block"></div>
      <v-auto-complete
        v-show="!featureFlagHandler.isUser"
        class="xl:mb-0 xl:order-5 xl:w-1/4"
        :labelKey="['fullName', 'alias']"
        :class="autoCompleteMargins"
        :suggestions="suggestedProfiles.data"
        :total="suggestedProfiles.total"
        @onSelect="filterStatsByUser"
        @update="handleUserFilterSuggestions"
      />
    </div>
    <slot name="datepicker"></slot>
    <div class="grid gap-8 grid-cols-1 xl:grid-cols-10">
      <v-card classes="xl:col-span-4 order-1 xl:order-none">
        <template #heading>
          <h2 :class="headingStyling">Top profile interactions</h2>
        </template>
        <v-pie
          v-if="topProfileInteractions?.data"
          :chartData="topProfileInteractions"
          title="## Interactions"
        />
        <div v-else>
          <h3>No data or data is still loading</h3>
        </div>
      </v-card>
      <v-card classes="xl:col-span-6 order-first xl:order-none">
        <template #heading>
          <h2 :class="headingStyling"
            >Profile views ({{ totalProfileViews }} lifetime views)</h2
          >
        </template>
        <v-line
          v-if="profileVisits?.data"
          :chartData="profileVisits"
          :start="start"
          :end="end"
        />
        <div v-else>
          <h3>No data or data is still loading</h3>
        </div>
      </v-card>
      <v-card
        v-if="topProfiles.data.length && showTopUsers"
        compact
        classes="xl:col-span-10 order-3 xl:order-none"
      >
        <template #heading>
          <h2 :class="headingStyling">Top viewed profiles</h2>
        </template>
        <div
          class="flex flex-col flex-wrap justify-start content-between adaptive-height-all-profiles"
        >
          <div
            v-for="(profile, i) in topProfiles.data"
            class="flex items-center p-8 h-36 border-b"
            :class="{
              'xl:border-b-0': (i + 1) % 5 == 0,
              'border-r': i < 10,
              'w-full xl:w-1/2': topProfiles.data.length <= 10,
              'w-full xl:w-1/3': topProfiles.data.length > 10
            }"
            :key="i"
          >
            <span class="text-2xl leading-none mr-4 w-10">{{ i + 1 }}.</span>
            <v-profile-card
              class="items-center"
              :fullName="profile.fullName"
              :jobTitle="profile.jobTitle"
              :hidePencil="true"
              :profileImage="formatImageUrl(profile.profileThumbnail)"
              @clickProfile="filterStatsByUser(profile)"
            />
          </div>
        </div>
      </v-card>
      <v-card classes="xl:col-span-10 order-3 xl:order-none" compact>
        <template #heading>
          <h2 :class="headingStyling">Your top interactions</h2>
        </template>
        <v-inactive-user v-if="topLinkInteractions.length == 0" />
        <div
          class="top-interactions flex flex-col flex-wrap justify-center content-between adaptive-height-top-link-interactions"
        >
          <template v-for="(link, i) in take(topLinkInteractions, 30)" :key="i">
            <p
              class="text-2xl py-4 flex w-full px-10 top-interactions-row items-center xl:px-16 xl:w-1/2"
            >
              <span class="mr-4">{{ i + 1 }}.</span>

              <span
                class="top-interactions-body whitespace-nowrap truncate text-ellipsis"
              >
                <span class="font-semibold top-interactions-content">{{
                  link.title
                }}</span>
                <span
                  v-if="link.value && link.type != 'modal'"
                  class="text-brand-color pr-2 top-interactions-link"
                >
                  (<a
                    :title="link.value"
                    :href="link.value"
                    target="_blank"
                    class="underline"
                  >
                    {{ link.value }}
                  </a>
                  )
                </span>
              </span>
              <span class="flex-1 pr-4"></span>
              <span
                class="text-brand-color top-interactions-percentage font-semibold whitespace-nowrap"
              >
                {{ percentage(topLinkInteractions, 'data')[i] }}%
                <span class="mx-1">|</span> {{ count(topLinkInteractions, i) }}
              </span>
            </p>
            <div
              v-if="
                Math.floor((min([30, topLinkInteractions.length]) - 1) / 2) == i
              "
              class="border-r h-full"
            ></div>
          </template>
        </div>
      </v-card>
      <v-card classes="xl:col-span-10 order-6 xl:order-none" compact>
        <template #heading>
          <h2 :class="headingStyling">Getting in touch</h2>
        </template>
        <div class="flex flex-wrap">
          <stat-block
            icon="fas fa-phone-alt"
            title="Click to call"
            :stat="clickToCall"
            :average="clickToCallAverage"
          />
          <stat-block
            icon="fas fa-map-marker-alt"
            title="Click to navigate"
            :stat="clickToNavigate"
            :average="clickToNavigateAverage"
          />
          <stat-block
            icon="fas fa-envelope"
            title="Email"
            :stat="clickToContactEmail"
          />
          <stat-block
            icon="fas fa-comments-alt"
            title="Messages"
            :stat="clickToContactMessage"
          />
          <stat-block
            @click="downloadLeadForms"
            :class="{ 'cursor-pointer': leadForms.length }"
            icon="fa-solid fa-file-lines"
            title="Lead form submissions"
            :borderBottom="false"
            :stat="submitLeadForm"
          />
        </div>
      </v-card>
      <v-card classes="xl:col-span-10 order-7 xl:order-none" compact>
        <template #heading>
          <h2 :class="headingStyling">Actions</h2>
        </template>
        <div class="flex flex-wrap">
          <stat-block
            icon="fas fa-home-lg-alt"
            title="Save to home screen"
            :stat="savetoHomeScreen"
            :average="savetoHomeScreenAverage"
          />
          <stat-block
            icon="fas fa-user-plus"
            title="Add to contacts"
            :stat="addToContacts"
            :average="addToContactsAverage"
          />
          <stat-block
            icon="fas fa-link"
            title="Copy link"
            :stat="copyLinks"
            :average="copyLinksAverage"
          />
          <stat-block
            icon="fa-solid fa-share-nodes xl:text-4xl"
            title="Share"
            :stat="share"
          />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import vPie from '~/components/chart/v-pie.vue';
import vLine from '~/components/chart/v-line.vue';
import vCard from '~/components/ui/v-card.vue';
import vInactiveUser from '~/components/functional/v-inactive-user.vue';
import statBlock from '~/components/ui/stat-block.vue';
import vProfileCard from '~/components/ui/v-profile-card.vue';
import { lastWeek, today } from '~/functions/date-functions';
import {
  convertCountToPercentages,
  shortenNumbers
} from '~/functions/data-functions';
import {
  absoluteValue,
  formatPath,
  formatPathByCompanies,
  controlStyling,
  objectToXLSXFile
} from '~/functions/utils-functions';
import { map, sum, take, uniqBy, min, isEmpty, truncate, get } from 'lodash';
import logo from '~/assets/img/person.jpg';
import PmButton from '~/components/ui/pm-button.vue';
import { selfServiceSchema } from '~/constants/schemas';
import userState from '~/store/state-managers/user';
import vAutoComplete from '~/components/functional/v-autocomplete.vue';

export default {
  emits: [
    'filterStatsByDivision',
    'fetchCompanySuggestions',
    'fetchUserSuggestions',
    'selectProfileForStats'
  ],
  components: {
    vCard,
    vPie,
    vAutoComplete,
    vLine,
    vInactiveUser,
    PmButton,
    statBlock,
    vProfileCard
  },
  props: {
    featureFlagHandler: {
      type: Object,
      default: () => {}
    },
    reports: {
      type: Object,
      default: () => {}
    },
    start: {
      type: Object,
      default: () => lastWeek
    },
    selfServiceProfile: {
      type: Object,
      default: selfServiceSchema()
    },
    end: {
      type: Object,
      default: () => today
    },
    userRole: {
      type: String,
      default: 'user'
    },
    classes: {
      type: String,
      default: ''
    },
    titleValues: {
      type: Object,
      default: () => {}
    },
    suggestedProfiles: {
      type: Array,
      default: () => []
    },
    suggestedCompanies: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      ...this.reports,
      headingStyling: 'text-3xl text-brand-color',
      controlStyling,
      userWrapper: userState(),
      searchString: ''
    };
  },
  computed: {
    divisionFilter() {
      return this.$store.state.supervisor.filterByDivision;
    },
    fixedDirection() {
      if (
        this.$route.fullPath === '/statistics' ||
        this.$route.fullPath === '/statistics/'
      ) {
        return 'flex-row';
      }
      return undefined;
    },
    autoCompleteMargins() {
      if (
        this.$route.fullPath === '/statistics' ||
        this.$route.fullPath === '/statistics/'
      ) {
        return ' ';
      }
      return undefined;
    },
    totalProfileViews() {
      return sum(this.profileVisits.data);
    },
    contentInteractionSorted() {
      let list = [];
      if (!this.contentInteraction) return list;
      const filteredList = uniqBy(
        this.contentInteraction.filter((e) => e.data != 0),
        'title'
      );
      let arrayWithPercentages = filteredList;
      arrayWithPercentages = arrayWithPercentages.map((item, index) => {
        return {
          ...item,
          p: this.percentage(filteredList, 'data')[index]
        };
      });

      const sortedArray = arrayWithPercentages.sort((a, b) => b.p - a.p);
      const halfOne =
        sortedArray.length > 10
          ? sortedArray.slice(0, 5)
          : sortedArray.slice(0, Math.ceil(sortedArray.length / 2));
      const halfTwo =
        sortedArray.length > 10
          ? sortedArray.slice(5, 10)
          : sortedArray.slice(
              Math.floor(sortedArray.length / 2) + 1,
              sortedArray.length
            );

      return { halfOne, halfTwo, all: [...halfOne, ...halfTwo] };
    },
    hasDomain() {
      return this.selfServiceProfile.domain?.siteId ? true : false;
    },
    profileId() {
      if (this.userWrapper.isUser) {
        return this.userWrapper.getProfileId();
      }
      const id = this.$route.params.alias?.split('_')?.[0];
      return id;
    },
    viewingForId() {
      const id = this.$route.params.alias?.split('_')?.[0];
      return id;
    },
    currentAlias() {
      return this.$route.params.alias;
    },
    topLinkInteractionsHeight() {
      return this.adaptiveHeight('topLinkInteractions', 30, 40);
    },
    topProfilesHeight() {
      return this.adaptiveHeight('topProfiles.data', 15, 90, 3);
    },
    showTopUsers() {
      return this.userRole != 'user' && !this.profileId;
    }
  },
  methods: {
    isEmpty,
    handleUserFilterSuggestions(value) {
      this.$emit('fetchUserSuggestions', {
        filter: value.filter,
        page: value.page - 1
      });
    },
    handleDivisionSuggestions(value) {
      this.$emit('fetchCompanySuggestions', {
        'filters[description][$containsi]': value.filter,
        'pagination[page]': value.page
      });
    },
    filterStatsByDivision(divisions) {
      this.$store.commit('supervisor/setDivisionFilter', divisions);
      this.$router.push({
        path: formatPathByCompanies(`/statistics/`, divisions)
      });
    },
    filterStatsByUser(profile) {
      this.$store.commit('supervisor/setProfileFilter', profile);
      this.$router.push({
        path: formatPath(`/statistics/`, profile)
      });
    },
    adaptiveHeight(key, take = 20, height = 40, divideBy = 2) {
      return (
        Math.ceil(this.take(get(this, key), take).length / divideBy) * height +
        'px'
      );
    },
    goToProfile() {
      if (this.userWrapper.isUser) {
        return this.$router.push({
          path: `/self-service`
        });
      }
      return this.$router.push({
        path: `/self-service/` + this.currentAlias
      });
    },
    goToUsers() {
      return this.$router.push({
        path: '/users'
      });
    },
    goToStats() {
      return this.$router.push({
        path: '/statistics'
      });
    },
    showAverage() {
      return this.userRole == 'superadmin';
    },
    setMockImage(el) {
      el.target.src = logo;
    },
    formatImageUrl(url) {
      if (url && !url.includes('http')) {
        return process.env.VUE_APP_STRAPI_URL + url;
      }
      return url;
    },
    absoluteValue,
    percentage(data, key) {
      return convertCountToPercentages(map(data, key));
    },
    count(data, index) {
      return shortenNumbers(data[index]['data']);
    },
    downloadLeadForms() {
      if (this.leadForms.length) {
        const filename =
          truncate(
            `Lead form submissions ${this.start.toString().split('T')[0]}-${
              this.end.toString().split('T')[0]
            } for ${this.titleValues.forWho}`,
            {
              length: 200,
              separator: ' '
            }
          ) + '.xlsx';
        this.downloadXLSX(filename);
      }
    },
    downloadXLSX(fileName) {
      const a = document.createElement('a');
      a.href = objectToXLSXFile(this.leadForms, 'formId', 'message');
      a.download = fileName;
      a.click();
    },
    map,
    take,
    min
  }
};
</script>

<style>
.bg-modal {
  background-color: rgba(255, 255, 255, 0.9);
}

.adaptive-height-top-link-interactions {
  padding: 8px 0;
}

@media screen and (min-width: 768px) {
  .adaptive-height-top-link-interactions {
    padding: 0;
    height: calc(v-bind(topLinkInteractionsHeight) + 50px);
  }

  .adaptive-height-all-profiles {
    max-height: v-bind(topProfilesHeight);
  }
}

.selectStyle {
  height: -webkit-fill-available;
  font-size: small;
}
</style>
