<template>
  <div class="flex w-full">
    <img
      :src="profileImage"
      :alt="fullName"
      @error="setMockImage"
      class="w-20 h-20 border object-cover md:w-26 md:h-26 rounded-full mr-4 whitespace-none"
    />
    <div class="truncate flex flex-col justify-center overflow-ellipsis flex-1">
      <div class="flex"
        ><p
          v-if="fullName"
          class="text-2xl font-semibold mb-2 leading-none truncate"
          :class="{ 'cursor-pointer brand-font-color': !disableProfileEvent }"
          @click="profileClick"
        >
          <span class="mr-3">{{ fullName }}</span>
        </p>
        <i
          v-if="!hidePencil"
          @click="profileClick"
          :class="{ 'cursor-pointer brand-font-color': !disableProfileEvent }"
          class="fa-solid fa-pencil"
        ></i>
      </div>
      <p
        v-if="jobTitle"
        class="text-2xl mb-2 leading-none truncate overflow-ellipsis w-full"
        >{{ jobTitle }}</p
      >
      <a
        v-if="url"
        :href="url"
        class="truncate brand-font-color text-2xl font-semibold overflow-ellipsis w-full underline"
        target="_blank"
        >{{ url }}</a
      >
    </div>
  </div>
</template>

<script>
import { setMockImage } from '~/functions/utils-functions';
export default {
  emits: ['clickProfile'],
  props: {
    profileImage: {
      type: String,
      default: ''
    },
    fullName: {
      type: String,
      default: ''
    },
    jobTitle: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    hidePencil: {
      type: Boolean,
      default: false
    },
    disableProfileEvent: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    setMockImage,
    profileClick() {
      if (!this.disableProfileEvent) {
        this.$emit('clickProfile');
      }
    }
  }
};
</script>
<style scoped>
.truncate {
  max-width: min-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
