<template>
  <div style="max-height: 60vh" class="overflow-y-auto">
    <div class="grid grid-cols-5 md:grid-cols-8 gap-8 p-8 justify-center">
      <div
        :class="`
        border
        h-24
        w-24
        flex
        justify-center
        items-center
        ${
          formatIcon(modelValue) === formatIcon(icon)
            ? 'active-border'
            : 'hover-border'
        }
        cursor-pointer
        `"
        v-for="icon in icons"
        :key="icon"
        @click="onIconSelected(icon)"
      >
        <i
          :class="`${formatIcon(icon)} text-4xl  ${
            formatIcon(modelValue) === formatIcon(icon)
              ? 'text-brand-color'
              : ''
          }`"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'solid'
    },
    icons: {
      type: Array,
      default: () => [
        'fa-{type} fa-link',
        'fa-{type} fa-phone-rotary',
        'fa-{type} fa-mobile',
        'fa-{type} fa-envelope',
        'fa-{type} fa-location-arrow',
        'fa-{type} fa-desktop',
        'fa-{type} fa-newspaper',
        'fa-{type} fa-file-alt',
        'fa-{type} fa-chart-line',
        'fa-{type} fa-calendar-alt',
        'fa-{type} fa-user',
        'fa-{type} fa-users',
        'fa-{type} fa-video',
        'fa-{type} fa-photo-video',
        'fa-{type} fa-handshake-alt',
        'fa-{type} fa-suitcase',
        'fa-{type} fa-comments',
        'fa-{type} fa-microphone',
        'fa-{type} fa-users-class',
        'fa-{type} fa-hand-holding-heart',
        'fa-{type} fa-chart-user',
        'fa-{type} fa-screen-users',
        'fa-{type} fa-users',
        'fa-{type} fa-people-arrows',
        'fa-{type} fa-circle-exclamation',
        'fa-{type} fa-bullhorn',
        'fa-{type} fa-calculator',
        'fa-{type} fa-address-card',
        'fa-{type} fa-address-book',
        'fa-{type} fa-file-certificate',
        'fa-{type} fa-graduation-cap',
        'fa-{type} fa-right-to-bracket',
        'fa-{type} fa-square-question',
        'fa-{type} fa-square-info',
        'fa-{type} fa-calendar-lines-pen',
        'fa-{type} fa-memo-circle-info',
        'fa-{type} fa-list',
        'fa-{type} fa-handshake',
        'fa-{type} fa-photo-film-music',
        'fa-{type} fa-image-landscape',
        'fa-{type} fa-user',
        'fa-{type} fa-star',
        'fa-{type} fa-thumbs-up',
        'fa-{type} fa-comments',
        'fa-{type} fa-globe',
        'fa-{type} fa-cloud-arrow-up',
        'fa-{type} fa-rocket',
        'fa-{type} fa-award',
        'fa-{type} fa-trophy',
        'fa-{type} fa-user-tie',
        'fa-{type} fa-lightbulb',
        'fa-{type} fa-solar-panel',
        'fa-{type} fa-puzzle',
        'fa-{type} fa-screwdriver-wrench',
        'fa-{type} fa-microphone-stand',
        'fa-{type} fa-message-dots',
        'fa-{type} fa-hands-holding-heart',
        'fa-{type} fa-map-marker-alt',
        'fa-{type} fa-people-group',
        'fa-{type} fa-suitcase',
        'fa-{type} fa-chart-pie',
        'fa-{type} fa-light fa-magnifying-glass',
        'fa-{type} fa-share-from-square',
        'fa-{type} fa-light fa-chart-line-up',
        'fa-{type} fa-cart-shopping',
        'fa-{type} fa-pen-to-square',
        'fa-{type} fa-right-to-bracket',
        'fa-{type} fa-chart-line',
        'fa-{type} fa-user-shield',
        'fa-{type} fa-photo-film',
        'fa-{type} fa-folder-open',
        'fa-{type} fa-award',
        'fa-{type} fa-user-chart',
        'fa-{type} fa-piggy-bank',
        'fa-{type} fa-thumbs-up',
        'fa-{type} fa-sign-in-alt',
        'fa-{type} fa-glasses-alt',
        'fa-{type} fa-sync-alt',
        'fa-{type} fa-file-check',
        'fa-{type} fa-building',
        'fa-{type} fa-comment-alt-smile',
        'fa-{type} fa-headset',
        'fa-{type} fa-analytics',
        'fa-{type} fa-money-check-edit',
        'fa-{type} fa-hand-receiving',
        'fa-{type} fa-file-certificate',
        'fa-{type} fa-file-alt',
        'fa-{type} fa-pencil',
        'fa-brands fa-whatsapp',
        'fa-{type} fa-cupcake',
        'fa-{type} fa-cake-slice',
        'fa-{type} fa-regular fa-pie',
        'fa-{type} fa-cake-candles',
        'fa-{type} fa-user-chef',
        'fa-{type} fa-rings-wedding',
        'fa-{type} fa-house-chimney-heart',
        'fa-{type} fa-house-building',
        'fa-{type} fa-building-circle-arrow-right',
        'fa-{type} fa-door-open',
        'fa-{type} fa-plane',
        'fa-{type} fa-people-roof',
        'fa-{type} fa-regular fa-display-chart-up',
        'fa-{type} fa-regular fa-file-chart-pie',
        'fa-{type} fa-globe-pointer',
        'fa-{type} fa-thought-bubble',
        'fa-{type} fa-money-check-pen',
        'fa-{type} fa-regular fa-shield-check',
        'fa-{type} fa-book',
        'fab fa-apple',
        'fak fa-candroid',
        'fab fa-facebook-f',
        'fab fa-linkedin-in',
        'fab fa-x-twitter',
        'fab fa-instagram',
        'fab fa-tiktok'
      ]
    }
  },
  emits: ['update:modelValue', 'on-change', 'close'],
  setup(props, ctx) {
    function onIconSelected(icon) {
      ctx.emit('update:modelValue', icon);
      ctx.emit('on-change', icon);
    }

    function formatIcon(icon) {
      return icon?.replace('{type}', props.type || 'regular') || icon;
    }

    const close = () => {
      ctx.emit('close');
    };

    return {
      formatIcon,
      close,
      onIconSelected
    };
  }
};
</script>

<style scoped>
.hover-border:hover {
  border-color: var(--brand-color);
}

.active-border {
  border: 1px solid var(--brand-color);
}
</style>
