<script setup>
import QRCodeStyling from 'qr-code-styling';
import {
  ref,
  reactive,
  watch,
  onMounted,
  toRef
} from 'vue';
import pmButton from '~/components/ui/pm-button.vue';
import colorPicker from '~/components/functional/color-picker.vue';
const dotTypes = ["square", "rounded", "extra-rounded", "dots", "classy", "classy-rounded"];
const cornerSquareTypes = ["square", "extra-rounded", "dot"];
const cornerDotTypes = ["square", "dot"];
const buttonPreset = ' bg-gray-900 w-8 h-8 inline-block mr-2';
const iconClass = {
  "square": '' + buttonPreset,
  "rounded": 'rounded-tl-xl rounded-tr-xl' + buttonPreset,
  "extra-rounded": 'rounded-tl-full rounded-tr-full' + buttonPreset,
  "dot": 'rounded-full' + buttonPreset,
  "dots": 'rounded-full' + buttonPreset,
  "classy": 'rounded-tr-xl rounded-bl-xl' + buttonPreset,
  "classy-rounded": 'rounded-tr-2xl rounded-bl-2xl' + buttonPreset,
};
const defaultProps = () => ({
  colorOptions: {
    dots: { css: '#000000' },
    cornerSquares: { css: '#000000' },
    cornerDots: { css: '#000000' },
  },
  dotType: 'square',
  cornerSquareType: 'square',
  cornerDotType: 'square',
});
const emit = defineEmits(['update:modelValue', 'update', 'change']);
const props = defineProps({
  image: {
    type: String,
    default: ''
  },
  url: {
    type: String,
    default: ''
  },
  editable: {
    type: Boolean,
    default: true
  },
  modelValue: {
    type: Object,
    default: () => ({
      colorOptions: {
        dots: { css: '#000000' },
        cornerSquares: { css: '#000000' },
        cornerDots: { css: '#000000' },
      },
      dotType: 'square',
      cornerSquareType: 'square',
      cornerDotType: 'square',
    })
  },
});
const defaultValues = props.modelValue || defaultProps();
const colorOptions = reactive(defaultValues.colorOptions);
const qrOptions = reactive({
  image: props.image,
  data: props.url,
  width: 800,
  height: 800,
  type: 'canvas',
  margin: 1,
  qrOptions: {
    typeNumber: 0,
    mode: 'Byte',
    errorCorrectionLevel: 'Q'
  },
  imageOptions: {
    hideBackgroundDots: true,
    margin: 5,
    crossOrigin: 'anonymous'
  },
  backgroundOptions: {
    color: '#ffffff',
  },
  dotsOptions: {
    color: defaultValues.colorOptions.dots.css,
    type: defaultValues.dotType
  },
  cornersSquareOptions: {
    color: defaultValues.colorOptions.cornerSquares.css,
    type: defaultValues.cornerSquareType,
  },
  cornersDotOptions: {
    color: defaultValues.colorOptions.cornerDots.css,
    type: defaultValues.cornerDotType,
  }
});

const qrCode = ref();
const qrCodeRef = ref();
const centerImage = toRef(props, 'image');


const generateQrCode = () => {
  if (!qrCode.value) {
    qrCode.value = new QRCodeStyling(qrOptions);
    qrCode.value.append(qrCodeRef.value);
  } else {
    qrCode.value.update(qrOptions);
  }
  if (!qrCodeRef.value) {
    console.warn("QR Code DOM Element Missing");
    return;
  }
};

const downloadQR = () => {
  qrCode.value.download({ name: "QRCode", extension: "png" });
};

const update = () => {
  const opt = {
    colorOptions: { ...colorOptions },
    dotType: qrOptions.dotsOptions.type,
    cornerSquareType: qrOptions.cornersSquareOptions.type,
    cornerDotType: qrOptions.cornersDotOptions.type,
  };
  emit('update:modelValue', opt);
  emit('update', opt);
  emit('change', opt);
};

onMounted(generateQrCode);

const updateColor = () => {
  qrOptions.dotsOptions.color = colorOptions.dots.css;
  qrOptions.cornersSquareOptions.color = colorOptions.cornerSquares.css;
  qrOptions.cornersDotOptions.color = colorOptions.cornerDots.css;
};

watch(qrOptions, () => {
  generateQrCode();
  update();
});

watch(centerImage, () => {
  qrOptions.image = centerImage.value;
  generateQrCode();
  update();
});

watch(props.url, () => {
  qrOptions.data = props.url;
  generateQrCode();
  update();
});

watch(props.modelValue,
  () => {
    qrOptions.dotsOptions.color = props.modelValue.colorOptions.dots.css;
    qrOptions.dotsOptions.type = props.modelValue.dotType;

    qrOptions.cornersSquareOptions.color = props.modelValue.colorOptions.cornerSquares.css;
    qrOptions.cornersSquareOptions.type = props.modelValue.cornerSquareType;

    qrOptions.cornersDotOptions.color = props.modelValue.colorOptions.cornerDots.css;
    qrOptions.cornersDotOptions.type = props.modelValue.cornerDotType;
  },
  { deep: true }
);
</script>
<template>
  <div>
    <div
      ref="qrCodeRef"
      class="qr-code-canvas max-w-lg mb-10"
    ></div>
    <div
      v-if="editable"
      class="flex flex-col w-full"
    >
      <div class="dot-types flex items-center border p-8 mb-5">
        <div class="font-bold text-3xl w-1/4">
          Body
        </div>
        <color-picker
          right
          class="w-1/4"
          name="dotTypes"
          id="dot-types-color-picker"
          title="Color"
          v-model="colorOptions.dots"
          @change="updateColor"
        />
        <div class="text-3xl text-brand-color flex items-center">
          <span class="mr-3">Shape</span>
          <i
            v-for="val in dotTypes"
            class="cursor-pointer"
            :class="[iconClass[val], { 'opacity-50': qrOptions.dotsOptions.type == val }]"
            :key="val"
            :value="val"
            @click="qrOptions.dotsOptions.type = val"
          ></i>
        </div>
      </div>
      <div class="corner-dot-types flex items-center border p-8 mb-5">
        <div class="font-bold text-3xl w-1/4">
          Eye Ball
        </div>
        <color-picker
          right
          class="w-1/4"
          name="cornerDotTypes"
          id="corner-dot-types-color-picker"
          title="Color"
          v-model="colorOptions.cornerDots"
          @change="updateColor"
        />
        <div class="text-3xl text-brand-color flex items-center">
          <span class="mr-3">Shape</span>
          <i
            v-for="val in cornerDotTypes"
            class="cursor-pointer"
            :class="[iconClass[val], { 'opacity-50': qrOptions.cornersDotOptions.type == val }]"
            :key="val"
            :value="val"
            @click="qrOptions.cornersDotOptions.type = val"
          ></i>
        </div>
      </div>
      <div class="corner-square-types flex items-center border p-8 mb-5">
        <div class="font-bold text-3xl w-1/4">
          Eye Frame
        </div>
        <color-picker
          right
          class="w-1/4"
          name="cornerSquareTypes"
          id="corner-square-types-color-picker"
          title="Color"
          v-model="colorOptions.cornerSquares"
          @change="updateColor"
        />
        <div class="text-3xl text-brand-color flex items-center">
          <span class="mr-3">Shape</span>
          <i
            v-for="val in cornerSquareTypes"
            class="cursor-pointer"
            :class="[iconClass[val], { 'opacity-50': qrOptions.cornersSquareOptions.type == val }]"
            :key="val"
            :value="val"
            @click="qrOptions.cornersSquareOptions.type = val"
          ></i>
        </div>
      </div>
    </div>
    <pm-button
      v-if="qrCode"
      class="box-border"
      @click="downloadQR"
    >
      Download
    </pm-button>
  </div>
</template>
<style>
.qr-code-canvas canvas {
  width: 100%
}
</style>