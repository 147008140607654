<template>
  <div
    class="autocomplete-container rounded-2xl border-solid w-full text-2xl border-2 mb-6 p-2 bg-gray-100 xl:p-1 xl:block"
  >
    <v-dropdown
      v-model="popup"
      dropdownClasses="w-full bg-gray-100 mt-4 rounded-2xl text-2xl overflow-hidden"
      class="w-full h-full rounded-2xl text-slate-500 selectStyle max-h-20 focus:outline-none"
      :position="position"
    >
      <template #button>
        <input
          v-model="searchString"
          class="bg-transparent p-2 text-2xl xl:p-4"
          :class="{ 'placeholder-bold': value }"
          :placeholder="currentTitle"
          @input="onUpdate"
          @change="stopProp"
        />
        <i
          v-if="value"
          class="fa-solid fa-circle-xmark absolute top-1/3 right-5 cursor-pointer"
          @click="onSelect()"
        ></i>
        <i
          v-else
          class="fa-light fa-angle-down absolute top-1/3 right-5 cursor-pointer"
        ></i>
      </template>
      <template #content>
        <div class="flex flex-col w-full">
          <div :class="dropdownStatusStyling" v-if="loading"> Loading... </div>
          <template v-else>
            <div class="justify-between" :class="dropdownStatusStyling">
              <div class="flex-1 align-middle">
                <span
                  >Page {{ _page }} : Showing {{ suggestions?.length }}</span
                >
                <span v-if="hasTotal(total)"> of {{ total }}</span>
                <span> results</span>
              </div>
            </div>
            <div
              :class="dropdownStatusStyling"
              v-if="searchString && !suggestions.length"
            >
              No results found
            </div>

            <div
              class="flex justify-center p-2"
              :class="{
                'text-gray-400': _page < 2,
                'cursor-pointer': _page > 1
              }"
              @click="pageUp"
            >
              <i class="fa-solid fa-circle-up"></i>
            </div>
            <div
              class="w-full cursor-pointer overflow-ellipsis truncate py-2 p-2 px-4 hover:bg-gray-200"
              :class="{
                'bg-gray-500 text-white hover:bg-gray-300 hover:text-gray-700':
                  selected(item[valueKey])
              }"
              v-for="item of suggestions"
              :key="item[valueKey]"
              @click="onSelect(item, item[valueKey], getLabel(item))"
            >
              {{ getLabel(item) || item[valueKey] }}
              <span v-if="!getLabel(item)" class="text-gray-500 font-light">
                (No label found)
              </span>
            </div>
            <div
              class="flex justify-center p-2"
              :class="{
                'text-gray-400': !suggestions.length,
                'cursor-pointer': suggestions.length
              }"
              @click="pageDown"
            >
              <i class="fa-solid fa-circle-down"></i>
            </div>
            <div v-if="_selections.length" class="flex w-full px-4 py-4">
              <pm-button
                class="rounded-2xl w-2/3 mr-1 whitespace-nowrap"
                size="small"
                :compact="true"
                @click="apply"
              >
                Apply
              </pm-button>
              <pm-button
                class="rounded-2xl w-1/3 whitespace-nowrap"
                size="small"
                :compact="true"
                :danger="true"
                @click="clearSelections"
              >
                <i class="fa-solid fa-circle-xmark"></i>
                Clear
              </pm-button>
            </div>
          </template>
        </div>
      </template>
    </v-dropdown>
  </div>
</template>

<script>
import { ref, toRef, computed } from '@vue/reactivity';
import { find, findIndex, isString, isUndefined, clone } from 'lodash';
import { watch } from 'vue';
import vDropdown from '~/components/ui/v-dropdown';
import PmButton from '~/components/ui/pm-button.vue';

let debounce = null;
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Search Users'
    },
    inputHeight: {
      default: 'h-initial',
      type: String
    },
    suggestions: {
      type: Array,
      default: () => []
    },
    selections: {
      type: Array,
      default: () => []
    },
    labelKey: {
      type: String,
      default: 'title'
    },
    valueKey: {
      type: String,
      default: 'id'
    },
    total: {
      type: Number,
      default: null
    },
    page: {
      type: Number,
      default: 1
    },
    position: {
      type: String,
      default: 'bottom'
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  components: {
    vDropdown,
    PmButton
  },
  emits: ['onSelect', 'update'],
  setup(props, { emit }) {
    const _page = ref(props.page);
    const _selections = ref(props.selections);
    const searchString = ref('');
    const loading = ref(false);
    const popup = ref(false);
    const suggestions = toRef(props, 'suggestions');
    const value = toRef(props, 'value');
    const labelKey = toRef(props, 'labelKey');

    const onSelect = (suggestion) => {
      searchString.value = '';
      if (props.multiple) {
        const index = findIndex(_selections.value, {
          [props.valueKey]: suggestion[props.valueKey]
        });

        if (index >= 0) _selections.value.splice(index, 1);
        else _selections.value.push(suggestion);
      } else {
        popup.value = false;
        emit('onSelect', suggestion);
      }
    };
    const stopProp = (e) => {
      e.stopPropagation();
    };

    const onUpdate = (e) => {
      stopProp(e);
      loading.value = true;
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        emit('update', { filter: searchString.value, page: _page.value });
      }, 500);
    };

    const hasTotal = (total) => {
      return typeof total == 'number';
    };

    const currentTitle = computed(() => {
      const item = find(suggestions.value, { [props.valueKey]: value.value });
      return item?.[props.labelKey] || props.placeholder;
    });

    const selected = (value) => {
      return (
        find(_selections.value, { [props.valueKey]: value }) && props.multiple
      );
    };

    const getLabel = (item) => {
      if (isString(labelKey.value)) return item[labelKey.value];

      const key = find(labelKey.value, (key) => !isUndefined(item[key]));
      return item[key] || '';
    };

    watch(suggestions, () => (loading.value = false), { deep: true });

    return {
      onSelect,
      onUpdate,
      stopProp,
      hasTotal,
      getLabel,
      selected,
      _page,
      _selections,
      currentTitle,
      searchString,
      popup,
      loading,
      clearSelections: () => {
        _selections.value = [];
        emit('onSelect', clone(_selections.value));
      },
      apply: () => {
        emit('onSelect', clone(_selections.value));
      },
      pageUp: () => {
        if (_page.value > 1) {
          loading.value = true;
          _page.value--;
          emit('update', { filter: searchString.value, page: _page.value });
        }
      },
      pageDown: () => {
        if (suggestions.value.length) {
          loading.value = true;
          _page.value++;
          emit('update', { filter: searchString.value, page: _page.value });
        }
      },
      dropdownStatusStyling:
        'w-full cursor-pointer overflow-ellipsis truncate py-2 p-2 px-4 font-bold hover:bg-gray-200'
    };
  }
};
</script>

<style scoped>
::selection {
  color: #fff;
  background: #664aff;
}

input::placeholder {
  font-size: 14px;
  font-weight: normal;
}

input.placeholder-bold::placeholder {
  font-size: 14px;
  font-weight: bold;
}
</style>
