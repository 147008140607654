import authService from '../api/auth';
import { logoutRoute } from '~/functions/login-functions';
export default async function setup({ next, store, session, to }) {
  if (session._token) {
    if (session._reload) {
      sessionStorage.removeItem('_reload');
      try {
        const user = await authService(session._token).getUser();
        if (user.success) {
          store.commit('auth/login', user.message);
          store.commit('supervisor/init', user.message);
        } else {
          session.removeItem('_token');
          return next(logoutRoute(to));
        }
      } catch (err) {
        console.log(err);
        session.removeItem('_token');
        return next(logoutRoute(to));
      }
    }
  }
  return next();
}
