import {
  map,
  get,
  uniq,
  join,
  chain,
  startCase,
  includes,
  assign,
  isEmpty
} from 'lodash';
import { formatImageUrl } from '~/functions/utils-functions';
import titleWrapper from '~/functions/wrappers/title-wrapper';
import { titles } from '~/constants/titles';

export default (reactive = (s) => s) => {
  const state = reactive({
    companies: [],
    urlAlias: '',
    division: {},
    profile: {},
    profileCount: 0,
    templatesCount: 0,
    approvalsCount: 0,
    dateTitle: '',
    isUser: '',
    username: '',
    isProfileStats: '',
    report: ''
  });
  const titleMatches = reactive({
    users_total: '',
    templates_total: '',
    type: '',
    approvals_total: '',
    for: '',
    time: '',
    profile_picture: ''
  });
  const { getTitle } = titleWrapper(titles);
  const setTitle = () => {
    let forWho = chain(state.urlAlias).split('_').first().value()
      ? get(
          state.profile,
          'fullName',
          chain(state.urlAlias).split('_').last().value()
        )
      : state.isUser
      ? state.username
      : !isEmpty(state.division)
      ? join(uniq(map(state.division, (c) => startCase(c.title))), ', ')
      : join(uniq(map(state.companies, (c) => startCase(c.title))), ', ');

    const profilePic = state.isProfileStats
      ? chain(state.profile)
          .get('profilePicture', '')
          .thru(formatImageUrl)
          .value()
      : '';

    const templatesTotal = state.templatesCount;

    const type = state.isProfileStats ? 'Profile' : 'Company';

    const approvalsText = state.approvalsCount
      ? `<span class="text-gray-400 px-2">(${state.approvalsCount} Approval${
          state.approvalsCount !== 1 ? 's' : ''
        })</span>`
      : '';

    const forText = forWho
      ? `for <span class="text-brand-color font-semibold truncate col-3" title="${forWho}">${forWho}</span>`
      : '';

    const timeText = state.dateTitle
      ? ` <span><span>for</span> <span class="text-brand-color font-semibold">${state.dateTitle}</span></span>`
      : '';

    const profilePicText = profilePic
      ? `<img @error="setMockImage" class="border-4 border-gray-200 rounded-full inline-block mr-6 -my-12 h-28 w-28 object-cover md:mr-8" src="${profilePic}"></img>`
      : '';

    const templatesTotalText = `<span class="text-gray-400 px-2">(${templatesTotal})</span>`;

    const usersTotalText = chain(state.profileCount)
      .thru((count) =>
        count > 0 && !state.urlAlias
          ? `<span class="text-gray-400 ${
              !includes(state.report, 'statistics') ? 'px-2' : ''
            }">(${count} User${count > 1 ? 's' : ''})</span>`
          : ''
      )
      .value();

    titleMatches.users_total = usersTotalText;
    titleMatches.templates_total = templatesTotalText;
    titleMatches.type = type;
    titleMatches.approvals_total = approvalsText;
    titleMatches.for = forText;
    titleMatches.time = timeText;
    titleMatches.profile_picture = profilePicText;
  };
  return {
    setTitle: (titleValues) => {
      assign(state, titleValues);
      setTitle();
    },
    getTitle: (report) => getTitle(report, titleMatches)
  };
};
