<template>
  <div
    @input="inputEvent"
    class="relative grid grid-cols-2 pb-10 w-min p-6 pr-16"
  >
    <div
      :class="rowClasses"
      class="grid col-span-2"
    >
      <permission-item
        :permissions="permissions"
        :itemid="itemKey + '.review'"
        :groupId="'testimonialsGroupReviews'"
        :class="`${classes.placeholder}`"
      >
        <label :for="'testimonial' + id"> Review </label><span
          v-show="itemIsRequired('testimonialsGroupReviews')"
          class="ml-1 text-3xl text-red-600"
        >*
        </span>
      </permission-item>
      <permission-item
        :permissions="permissions"
        :itemid="itemKey + '.review'"
        :groupId="'testimonialsGroupReviews'"
      >
        <v-text-area
          v-model="inputValue.review"
          :fieldKey="'testimonial' + id"
        />
      </permission-item>
    </div>
    <permission-item
      :permissions="permissions"
      :itemid="itemKey + '.author'"
      :groupId="'testimonialsGroupAuthor'"
    >
      <v-input-wrapper class="my-6 border-b col-span-2 xl:mr-8 xl:col-span-1">
        <input
          v-model="inputValue.author"
          type="text"
          :class="classes.inputs"
          :name="'testimonial' + id"
          :id="'testimonial' + id"
          placeholder=" "
        />
        <label
          :for="'testimonial' + id"
          :class="`floating-label ${classes.placeholder}`"
        >Author<span
            v-show="itemIsRequired('testimonialsGroupAuthor')"
            class="ml-1 text-3xl text-red-600"
          >*
          </span></label>
      </v-input-wrapper>
    </permission-item>
    <v-input-wrapper class="my-6 border-b col-span-2 xl:ml-8 xl:col-span-1">
      <permission-item
        :permissions="permissions"
        :itemid="itemKey + '.date'"
        :groupId="'testimonialsGroupDates'"
      >
        <input
          v-model="inputValue.date"
          type="date"
          :class="classes.inputs"
          :name="'testimonial' + id"
          :id="'testimonial' + id"
          placeholder=" "
        />
        <label
          :for="'testimonial' + id"
          :class="`floating-label ${classes.placeholder}`"
        >Date</label>
      </permission-item>
    </v-input-wrapper>
    <v-input-wrapper class="my-6 col-span-2 xl:col-span-1">
      <permission-item
        :permissions="permissions"
        :itemid="itemKey + '.rating'"
        :groupId="'testimonialsGroupRating'"
      >
        <label
          :for="'testimonial' + id"
          :class="`${classes.placeholder} floating-label active`"
        >
          Rating<span
            v-show="itemIsRequired('testimonialsGroupRating')"
            class="ml-1 text-3xl text-red-600"
          >*
          </span>
        </label>
      </permission-item>
      <permission-item
        :permissions="permissions"
        :itemid="itemKey + '.rating'"
        :groupId="'testimonialsGroupRating'"
      >
        <div class="star-rating text-5xl pl-5 mt-8 -ml-7 relative flex star-color">
          <div class="flex relative">
            <div
              class="w-7 h-14 cursor-pointer"
              @click="inputRating(0)"
            ></div>
          </div>
          <div
            v-for="(i, index) of 5"
            :key="index"
            class="flex relative"
          >
            <div
              class="w-7 h-14 cursor-pointer"
              @click="inputRating(index + 0.5)"
            ></div>
            <div
              class="w-7 h-14 cursor-pointer"
              @click="inputRating(i)"
            ></div>
            <i
              class="absolute top-0 left-0 pointer-events-none"
              :class="{
                'fa-solid': showStarByRating(index),
                'fa-regular': !showStarByRating(index),
                'fa-star': !isHalfRating(index, i),
                'fa-star-half': isHalfRating(index, i)
              }"
            ></i>
            <i
              class="
                fa-star fa-regular
                absolute
                top-0
                left-0
                pointer-events-none
              "
              v-if="isHalfRating(index, i)"
            ></i>
          </div>
        </div>
      </permission-item>
    </v-input-wrapper>

    <div class="absolute top-8 right-8 cursor-pointer"><v-modal :title="'Are you sure ?'">
        <template #trigger="{ open }">
          <permission-item
            :permissions="permissions"
            :itemid="itemKey + '.delete'"
            :groupId="'testimonialsGroupDelete'"
          >
            <trash
              @click="open"
              class="h-10 hover-icon-red"
            />
          </permission-item>
        </template>
        <template #content="{ close }">
          <confirm-dialog
            :message="`Are you sure you want to delete testimonial`"
            @confirm="() => {
              removeItem();
              close();
            }
              "
          />
        </template> </v-modal></div>
  </div>
</template>

<script>
import VInputWrapper from '~/components/ui/v-input-wrapper.vue';
import PermissionItem from '~/components/functional/permission-item.vue';
import { random } from 'lodash';
import Trash from '../icons/trash.vue';
import vModal from '~/components/functional/v-modal.vue';
import ConfirmDialog from '~/components/ui/confirm-dialog.vue';
import vTextArea from '~/components/ui/v-textarea.vue';
export default {
  components: {
    VInputWrapper,
    vTextArea,
    ConfirmDialog,
    PermissionItem,
    Trash,
    vModal
  },
  emits: ['input', 'remove'],
  props: {
    classes: {
      type: Object,
      default: () => ({ inputs: '', placeholder: '' })
    },
    permissions: {
      type: Array,
      default: () => []
    },
    itemKey: {
      type: String,
      default: random(10000).toString()
    },
    id: {
      type: Number,
      default: 0
    },

    modelValue: {
      type: Object,
      default: () => ({
        title: '',
        review: '',
        author: '',
        date: '',
        rating: 0,
        key: random(100000) + '' + Date.now()
      })
    }
  },
  data() {
    return {
      rowClasses: 'relative mt-8',
      inputValue: this.modelValue,
      currentRating: (this.modelValue.rating * 10) / 2
    };
  },

  methods: {
    itemIsRequired(itemid) {
      return !this.permissions.filter((e) => e.key === itemid)?.[0]?.optional;
    },
    removeItem() {
      this.$emit('remove', this.itemKey);
    },
    inputEvent(e) {
      e?.stopPropagation();
      this.$emit('input');
    },
    showStarByRating(index) {
      return this.currentRating > index;
    },
    isHalfRating(index, count) {
      return (
        this.showStarByRating(index) &&
        this.currentRating < count &&
        (this.currentRating * 2) % 2 != 0
      );
    },
    inputRating(index) {
      this.currentRating = index;
      this.inputValue.rating = (index * 2) / 10;
      this.inputEvent();
    }
  }
};
</script>
<style lang="css" scoped>
@import url('../../self-service.css');

.star-color {
  color: #fec24d;
}

.hover-icon-red:hover {
  fill: #a92323 !important;
}
</style>
