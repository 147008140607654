<script setup>
import { reactive, watch } from 'vue';
import { getEnvironment } from '~/functions/utils-functions';
import selfServiceFormWrapper from '~/functions/wrappers/self-service-form-wrapper';
import setupSelfServiceMergeLists from '~/functions/setup/setup-self-service-merge-lists';
import selfServiceForm from '~/components/functional/self-service-form.vue';
import { selfServiceSchema } from '~/constants/schemas';
import preview from '~/views/self-service/preview.vue';
import PmButton from '~/components/ui/pm-button.vue';
import { VueFinalModal } from 'vue-final-modal';
import permissionsWrapper from '~/functions/wrappers/permissions';

const props = defineProps({
  selfServiceFunctions: {
    default: () => {},
    type: Object
  },
  reloadProfiles: {
    type: Function,
    default: () => {}
  },
  featureFlagHandler: {
    default: () => {},
    type: Object
  },
  selfServiceProfile: {
    type: Object,
    default: selfServiceSchema()
  },
  defaultThemes: {
    default: () => [],
    type: Array
  }
});
const emit = defineEmits(['fetchSelfServiceProfile', 'reloadProfiles']);
const profile = reactive(props.selfServiceProfile);
const environment = getEnvironment(process.env.VUE_APP_STRAPI_URL);

const permissionsHandler = permissionsWrapper(profile.permissions);

const {
  sectionHandler,
  linksArrayHandler,
  socialMediaArrayHandler,
  popupsArrayHandler,
  variableRecordHandler
} = setupSelfServiceMergeLists(
  profile,
  permissionsHandler,
  reactive,
  !props.featureFlagHandler.allowedFeaturePerProfile(profile.company)['rte']
);

const controls = selfServiceFormWrapper(
  profile,
  props.selfServiceFunctions,
  props.defaultThemes,
  props.featureFlagHandler,
  sectionHandler,
  linksArrayHandler,
  socialMediaArrayHandler,
  popupsArrayHandler,
  permissionsHandler,
  {
    autosave: true,
    fetchNewProfileInstance(profileId) {
      return emit('fetchSelfServiceProfile', profileId);
    },
    afterDetailsSaved() {
      emit('reloadProfiles');
    }
  }
);
watch(controls.sectionAccordions, (updatedValue) => {
  if (!updatedValue) {
    sessionStorage.removeItem('accordionSections');
  } else {
    sessionStorage.setItem('accordionSections', JSON.stringify(updatedValue));
  }
});
</script>

<template>
  <div class="flex">
    <self-service-form
      :controls="controls"
      :sectionHandler="sectionHandler"
      :linksArrayHandler="linksArrayHandler"
      :socialMediaArrayHandler="socialMediaArrayHandler"
      :featureFlagHandler="featureFlagHandler"
      :popupsArrayHandler="popupsArrayHandler"
      :variableRecordHandler="variableRecordHandler"
      :environment="environment"
    >
      <template #controlbar>
        <div
          class="flex-wrap flex py-8 px-6 bg-gray-100 border-t xl:flex-nowrap xl:pl-16"
        >
          <pm-button
            class="flex-1 w-96 mr-8 xl:flex-none"
            :compact="true"
            :background="'outline'"
            @click="controls.preview"
            >Preview</pm-button
          >
          <pm-button
            class="flex-1 w-96 publish-btn-container xl:flex-none xl:mr-8"
            :compact="true"
            :disabled="
              controls.savingProfile.subscribing ||
              controls.savingProfile.loading
            "
            @click="() => controls.publish(true)"
            >{{ controls.saveButtonTitle.value }}</pm-button
          >
        </div>
      </template>
    </self-service-form>
    <preview
      class="top-5 phone-border phone-border-container mt-5 sticky hidden lg:block"
      :profile="controls.profileForPreview"
      :stylingTemplates="controls.themes"
      :showPreview="true"
      :updatePreview="controls.updatePreview"
      :isModal="false"
    />

    <!-- Profile Preview -->
    <vue-final-modal
      :fit-parent="false"
      v-model="controls.showingPreview.value"
      :name="'modal-preview'"
      :esc-to-close="false"
    >
      <preview
        :profile="controls.profileForPreview"
        :stylingTemplates="controls.themes"
        :showPreview="controls.showingPreview.value"
        :updatePreview="controls.updatePreview"
      />
    </vue-final-modal>
  </div>
</template>

<style lang="css" scoped>
@import url('../../self-service.css');
</style>
