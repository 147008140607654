/**
 * This feature flag is highly flawed ideally we should hide each feature individually. i.e.
 * if one company has approvals is active in one of the companies, then approvals should be shown.
 */
import { some, every, mergeWith, clone, isNil } from 'lodash';
const available_feature_flags = {
  approvals: false,
  templates: false,
  permissions: false,
  rte: false,
  corporate: false,
  invite_users: false,
  style_editing: false,
};
function preferenceTrue(objValue, srcValue) {
  return isNil(objValue) ? null : objValue ? objValue : srcValue ? srcValue : false;
}
export default function (companies = [], role = 'user') {
  return {
    allowedFeaturePerProfile(company) {
      if (!company.feature_flags || isNil(company.feature_flags)) { return available_feature_flags; }
      let allowed = clone(available_feature_flags);
      mergeWith(allowed, company.feature_flags, preferenceTrue);
      return allowed;
    },
    get allowedFeature() {
      let allowed = clone(available_feature_flags);
      some(companies, (company) => {
        if (every(allowed, allow => allow == true)) { return true; }
        mergeWith(allowed, company.feature_flags, preferenceTrue);
        return false;
      });
      return allowed;
    },
    get isSupervisor() {
      return role == 'supervisor';
    },
    get isUser() {
      return role == 'user';
    },
    get isSuperAdmin() {
      return role == 'superadmin';
    },
    getPathByPermissions(path, alias) {
      if (role === 'supervisor') {
        if (path.includes('/self-service') && !alias) {
          return {
            path: '/home'
          };
        }
      }
      if (role === 'superadmin') {
        if (path.includes('/self-service') && !alias) {
          return {
            path: '/home'
          };
        }
      }
      if (role === 'user') {
        if (
          path.includes('/templates') ||
          path.includes('/manage-templates') ||
          path.includes('/approvals')
        ) {
          return {
            path: '/self-service'
          };
        }
      }
    }
  };
}
