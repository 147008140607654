import {
  defaultTo,
  get,
  join,
  replace,
  uniq,
  chain,
  map,
  isArray,
  findIndex,
  first,
  isNil,
  forEach,
  last,
  truncate,
  includes,
  isEmpty
} from 'lodash';
import Papa from 'papaparse';
import store from '~/store/index';
import { ROLE_IDS } from '~/constants/index';
import * as XLSX from 'xlsx';
import { DateTime } from 'luxon';

const numberWithCommas = (num) => {
  if (num) return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return '0';
};

const setMockImage = (el) => {
  el.target.src = formatImageUrl(
    store.state.whiteLabel.profilePlaceholderImage
  );
};

const listMatchesBetween = (value, start, end) => {
  if (!value) return [];
  const list = value.match(new RegExp(`${start}(.*?)${end}`, 'g'));
  return map(list, (val) => replace(replace(val, start, ''), end, ''));
};

const roundToDec = (num, dev = 2) => {
  if (!num) return '0.0';
  const preDec = num.toString().split('.')[0];
  const postDec = num.toString().split('.')[1];
  return preDec + '.' + (postDec ? postDec.substring(0, dev) : '0');
};

const joinSansUndefined = (arr, del = '-') =>
  replace(
    join(arr, del),
    new RegExp(`(${del}|^)undefined(${del}|$)|^${del}$|${del}$|^${del}`, 'gim'),
    ''
  );

const smartJoin = (values, del = ' - ') => join(uniq(values), del);

const indexOfAll = (array, searchItem) => {
  let i = array.indexOf(searchItem),
    indexes = [];
  while (i !== -1) {
    indexes.push(i);
    i = array.indexOf(searchItem, ++i);
  }
  return indexes;
};

function titleCase(str) {
  let splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}
function checkObjectForUndefined(obj = {}, check = []) {
  for (let i = 0; i < check.length; i++) {
    if (!get(obj, check[i], false)) {
      return false;
    }
  }
  return true;
}
function checkObjectForUndefinedBy(fn = () => {}, check = []) {
  for (let i = 0; i < check.length; i++) {
    if (!fn(check[i])) {
      return false;
    }
  }
  return true;
}
function isFalse(value) {
  return value === false;
}
function checkForEitherValues(v1, v2) {
  if (v1 || v2) {
    return true;
  }
  return false;
}
const userRole = (user) => {
  return defaultTo(ROLE_IDS[get(user, 'role.type', '')], 'user');
};

const absoluteValue = (num) => {
  if (num < 0) {
    return num * -1;
  }
  return num;
};

const formatPath = (route, profile) => {
  const profilePath = profile
    ? `${defaultTo(profile.id, profile.profileId)}_${defaultTo(
        profile.companyId,
        get(profile, 'company.id', '')
      )}_${defaultTo(profile.fullName, get(profile, 'firstName'))}`
    : '';
  return `${route}${profilePath}`;
};
const formatPathByCompany = (route, company) => {
  const profilePath = company ? `_${get(company, 'id', '')}_` : '';
  return `${route}${profilePath}`;
};
const formatPathByCompanies = (route, companies) => {
  const profilePath = !isEmpty(companies)
    ? `_${join(map(companies, 'id'), ',')}_`
    : '';
  return `${route}${profilePath}`;
};

function truncateWithEllipses(text, max) {
  return text.substr(0, max - 1) + (text.length > max ? '...' : '');
}

function hexToRgb(hex) {
  var bigint = parseInt(hex.split('#')[1], 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;

  return 'rgb(' + r + ',' + g + ',' + b + ')';
}

const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const monthInText = (date) => {
  const d = new Date(date);
  return month[d.getMonth()];
};
function hasDuplicates(array) {
  return new Set(array).size !== array.length;
}

const fixTitles = (title) => {
  const presets = {
    qr_code: 'QR Code',
    linkedin: 'LinkedIn',
    Linkedin: 'LinkedIn'
  };
  const upperFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  if (presets[title]) return presets[title];
  return chain(title).split(' ').map(upperFirstLetter).join(' ').value();
};

const isBillingSetup = (billing) => {
  if (get(billing, 'status', '') !== '') {
    if (get(billing, 'status', '') !== 'failed') {
      if (get(billing, 'status', '') === 'cancelled') {
        const exD = new Date(billing.expirationDate);
        const curD = new Date();
        if (exD.getTime() < curD.getTime()) {
          return false;
        }
      }
      return true;
    }
    return false;
  }
  return false;
};
const doesRequireBilling = (company = { paymentStructure: 'individual' }) => {
  return company.paymentStructure === 'individual';
};
function focusElement(el) {
  document.getElementById(el).focus();
}
const formatImageUrl = (url) => {
  if (url && !url.includes('http') && !url.includes('/img/')) {
    return process.env.VUE_APP_STRAPI_URL + url;
  }
  return url;
};
const getEntryByKey = (
  profile,
  path,
  key,
  valueKey,
  defaultPlaceholder = ''
) => {
  return chain(profile)
    .get(path, [{ key, [valueKey]: defaultPlaceholder }])
    .find({ key })
    .defaultTo({ [valueKey]: defaultPlaceholder })
    .value()[valueKey];
};

const sizedImage = (prefix, path, width) => {
  if (path.includes('res.cloudinary.com') && width) {
    return path.replace('upload', 'upload/w_' + width);
  }
  return prefix + path;
};

const controlStyling = (size = 'md', fixedDirection) => {
  return `
bg-white
flex ${fixedDirection ?? 'flex-col'}
mb-12
px-16 py-16
${size}:py-10
-mx-6
md:-mx-16
${size}:${fixedDirection ?? 'flex-row'}
${size}:justify-between
`;
};

const cellStyling = (center = false, br = true, py = true) => {
  return `text-2xl ${py ? 'py-6' : ''} border-t flex items-center px-6 ${
    center ? 'justify-center' : ''
  } ${br ? 'border-r' : ''}`;
};

const wrapInArrayIfNeeded = (property) => {
  return isArray(property) ? property : [property];
};

const getByKey = (data, path = '', defaultPlaceholder = '') => {
  const keyRegex = /\[(.*?)\]/;
  if (!keyRegex.test(path)) return get(data, path, defaultPlaceholder);

  const keyIndex = chain(path)
    .thru((v) => keyRegex.exec(v))
    .first()
    .replace(/[[\]]/g, '')
    .split(':')
    .thru((keyVal) => {
      return findIndex(get(data, first(path.split(keyRegex))), keyVal);
    })
    .value();
  return keyIndex >= 0
    ? get(data, path.replace(keyRegex, '===').replace('===', `[${keyIndex}]`))
    : defaultPlaceholder;
};

const formatDoubleQuote = (v) => replace(v, /"/g, '""');
const formatOnOff = (v) => replace(v ? 'On' : 'Off', /"/g, '""');
const formatYesNo = (v) => replace(v ? 'Yes' : 'No', /"/g, '""');
const formatDate = (v) =>
  v ? DateTime.fromISO(v).toFormat('yyyy-LL-dd hh:mm a') : '';

function addIncrementsToDuplicates(arr) {
  return chain(arr)
    .groupBy() // Group elements by their values
    .mapValues((group) => {
      if (group.length > 1) {
        return group.map((value, index) =>
          index === 0 ? value : `${value} ${index + 1}`
        );
      }
      return group;
    })
    .flatMap() // Flatten the grouped values back into an array
    .value();
}
const XL_HEADER = [
  'NAME & SURNAME',
  'DESIGNATION',
  'DIVISION / COMPANY NAME',
  'TEMPLATE NAME',
  'URL',
  'EMAIL',
  'OFFICE CELL NO.',
  'MOBILE CELL NO.',
  'WHATSAPP CELL NO.',
  'TOTAL VIEWS',
  'USERNAME' /* Username(User) */,
  'USER EMAIL' /* Email(User) */,
  'LOGIN USER CONFIRMED EMAIL' /* Confirmed Email(User) */,
  'LOGIN USER UPDATED AT' /* Updated At(User) */,
  'INVITED AT',
  '1st PUBLISHED AT',
  'UPDATED AT',
  'COMPANY ID',
  'STYLING_ID',
  'PROFILE_ID',
  /*   "MARKETING OPT IN 1 (YES / NO) (WE STILL NEED TO BUILD THIS)",
    "MARKETING OPT IN 2 (YES / NO) (WE STILL NEED TO BUILD THIS)", */
  'SUBSCRIPTION TYPE',
  'APPROVALS (ON OR OFF?)',
  'BETA FEATURES (ON OR OFF?)',
  'LEAD FORM NAMES',
  'LEAD FORM IDS'
];
const profilesXLSXFile = (profilesWithViews) => {
  // TODO add new fields
  const xslHeader = XL_HEADER;
  const getProfileValue = (profile, path, callback) =>
    chain(profile)
      .get(path)
      .defaultTo('')
      .thru((v) => callback(v))
      .toString()
      .value();

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  const xslRows = chain(profilesWithViews)
    .filter((p) => !isNil(p))
    .map((profile) => {
      const ans = [
        getProfileValue(profile, 'fullName', formatDoubleQuote),
        getProfileValue(profile, 'jobTitle', formatDoubleQuote),
        getProfileValue(profile, 'companyName', formatDoubleQuote),
        getProfileValue(profile, 'templateName', formatDoubleQuote),
        getProfileValue(profile, 'url', formatDoubleQuote),
        getProfileValue(profile, 'email', formatDoubleQuote),
        getProfileValue(profile, 'office', formatDoubleQuote),
        getProfileValue(profile, 'mobile', formatDoubleQuote),
        getProfileValue(profile, 'whatsapp', formatDoubleQuote),
        getProfileValue(profile, 'count', formatDoubleQuote),
        getProfileValue(
          profile,
          'profileUsername',
          formatDoubleQuote
        ) /** Username(User) */,
        getProfileValue(
          profile,
          'profileUserEmail',
          formatDoubleQuote
        ) /** Email(User) */,
        getProfileValue(
          profile,
          'profileUserConfirmed',
          formatYesNo
        ) /** Confirmed Email(User) */,
        getProfileValue(
          profile,
          'profileUserUpdatedAt',
          formatDate
        ) /** Updated At(User) */,
        getProfileValue(profile, 'invitedAt', formatDate),
        getProfileValue(profile, 'firstPublishedAt', formatDate),
        getProfileValue(profile, 'updatedAt', formatDate),
        getProfileValue(profile, 'companyId', formatDoubleQuote),
        getProfileValue(profile, 'profileStyling', formatDoubleQuote),
        getProfileValue(profile, 'id', formatDoubleQuote),
        /** null  /** MARKETING OPT IN 1 (YES / NO) ,*/
        /** null /** MARKETING OPT IN 2 (YES / NO) ,*/
        getProfileValue(profile, 'subscriptionType', formatDoubleQuote),
        getProfileValue(profile, 'enableApprovals', formatOnOff),
        getProfileValue(profile, 'betaFeatures', formatOnOff),
        getProfileValue(profile, 'leadFormNames', formatDoubleQuote),
        getProfileValue(profile, 'leadFormIds', formatDoubleQuote)
      ];
      return ans;
    })
    .sort((a, b) => b[6] - a[6]) /* SORT BY EMAIL */
    .value();

  const xslData = XLSX.utils.book_new();
  const xslSheet = XLSX.utils.aoa_to_sheet([xslHeader, ...xslRows]);
  XLSX.utils.book_append_sheet(xslData, xslSheet, 'Profiles');
  const xlsxBlob = XLSX.write(xslData, { bookType: 'xlsx', type: 'binary' });
  const xslBlob = new Blob([s2ab(xlsxBlob)], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  return URL.createObjectURL(xslBlob);
};

const objectToXLSXFile = (data, pageKey, titleKey) => {
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  const xslData = XLSX.utils.book_new();

  const sheets = chain(data).map(pageKey).uniq().value();
  const titles = [];
  forEach(sheets, (key) => {
    const filterData = chain(data)
      .filter({ [pageKey]: key })
      .value();
    titles.push(
      chain(filterData)
        .first()
        .get(titleKey)
        .replace(/(\\|\/|\?|\*|\[|\]|)/g, '')
        .value()
    );

    const xslSheet = XLSX.utils.json_to_sheet(filterData);

    XLSX.utils.book_append_sheet(
      xslData,
      xslSheet,
      truncate(last(addIncrementsToDuplicates(titles)), {
        length: 31,
        separator: '...'
      })
    );
  });

  const xlsxBlob = XLSX.write(xslData, { bookType: 'xlsx', type: 'binary' });
  const xslBlob = new Blob([s2ab(xlsxBlob)], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  return URL.createObjectURL(xslBlob);
};

const profilesCSVfile = (profilesWithViews) => {
  const csvHeader = [
    'NAME',
    'DIVISION',
    'TEMPLATE',
    'URL',
    'EMAIL',
    'TOTAL VIEWS',
    'INVITED_AT',
    'PUBLISHED_AT',
    'UPDATED_AT'
  ];
  const getProfileValue = (profile, path, callback) =>
    chain(profile)
      .get(path)
      .defaultTo('')
      .thru((v) => callback(v))
      .toString()
      .value();
  const formatDoubleQuote = (v) => replace(v, /"/g, '""');
  const csvRows = chain(profilesWithViews)
    .filter((p) => !isNil(p))
    .map((profile) => ({
      NAME: getProfileValue(profile, 'fullName', formatDoubleQuote),
      DIVISION: getProfileValue(profile, 'companyName', formatDoubleQuote),
      TEMPLATE: getProfileValue(profile, 'templateName', formatDoubleQuote),
      URL: getProfileValue(profile, 'url', formatDoubleQuote),
      EMAIL: getProfileValue(profile, 'email', formatDoubleQuote),
      'TOTAL VIEWS': getProfileValue(profile, 'count', formatDoubleQuote),
      INVITED_AT: getProfileValue(profile, 'invitedAt', formatDate),
      PUBLISHED_AT: getProfileValue(profile, 'publishedAt', formatDate),
      UPDATED_AT: getProfileValue(profile, 'updatedAt', formatDate)
    }))
    .sort((a, b) => b['TOTAL VIEWS'] - a['TOTAL VIEWS'])
    .value();
  const csvData = Papa.unparse(
    { fields: csvHeader, data: csvRows },
    { quotes: true }
  );
  const csvURI = `data:text/xls;charset=utf-8,${encodeURIComponent(csvData)}`;
  return csvURI;
};

const getEnvironment = (url) => {
  if (includes(url, 'beta')) return 'beta';
  if (includes(url, 'dev') || includes(url, 'development'))
    return 'development';
  return 'production';
};

export {
  getEnvironment,
  wrapInArrayIfNeeded,
  getByKey,
  hasDuplicates,
  isBillingSetup,
  doesRequireBilling,
  checkObjectForUndefined,
  checkObjectForUndefinedBy,
  isFalse,
  checkForEitherValues,
  truncateWithEllipses,
  monthInText,
  focusElement,
  numberWithCommas,
  joinSansUndefined,
  indexOfAll,
  roundToDec,
  userRole,
  formatPath,
  formatPathByCompany,
  formatPathByCompanies,
  absoluteValue,
  smartJoin,
  formatImageUrl,
  setMockImage,
  fixTitles,
  titleCase,
  listMatchesBetween,
  getEntryByKey,
  sizedImage,
  hexToRgb,
  controlStyling,
  cellStyling,
  profilesCSVfile,
  profilesXLSXFile,
  objectToXLSXFile
};
