import { chain, find, forEach, map, remove } from "lodash";
import { arrayMergeWrapper } from "./array-merge-wrapper";
/**
 * Wrappers need to be already merged with master
 */
function sectionWrapper(itemWrappers, infoWrapper, reactive = () => { }) {
  /**
   * From now on, when we mention key and type, 
   * then we mean the key and type of the additional_information
   * which will automatically map to the type of the list item.
   */
  const state = reactive({
    infoItems: [],
    items: itemWrappers,
    onchange: () => { }
  });

  const getInfoSectionItem = (info) => {
    const itemsArrayWrapper = find(state.items, ({ filterKey }) => (filterKey?.type == info.key))
      || find(state.items, ({ keyPath, filterKey }) => (keyPath == info.type && !filterKey));
    return {
      info,
      itemsArrayWrapper,
      key: info.key,
      type: info.type,
      permissionKey: info.key || info.type
    };
  };
  const getItems = () => map(infoWrapper.childArray, getInfoSectionItem);
  const getItemByKey = (key) => find(state.infoItems, { key });

  state.infoItems = getItems();
  return {
    getItemByKey,
    infoWrapper,
    state,
    getItemsArrayWrapper(key) {
      return find(state.infoItems, { key })?.itemsArrayWrapper;
    },
    onChange(callback) {
      forEach(state.items, (wrapper) => {
        wrapper.addOnChange(callback);
      });
      state.onChange = callback;
    },
    addToChild(newItem, type, key) {
      const item = find(state.infoItems, { type, key });
      if (item.itemsArrayWrapper)
        item.itemsArrayWrapper.addToChild(newItem);
    },
    addToSection(item, child, master) {
      const itemParams = [child, master, item.type, item.key ? { type: item.key } : null, item.key, reactive];
      state.items[state.items.length] = arrayMergeWrapper(...itemParams);
      state.infoItems[state.infoItems.length] = getInfoSectionItem(item);
      infoWrapper.addToChild(item);
      state.onChange('section-added');
    },
    removeAtKey(key) {
      const removedItem = remove(state.infoItems, { key });
      if (removedItem.length) {
        infoWrapper.removeFromChild(key);
        state.onChange('section-removed');
      }
    },
    updateWholeChildAtId(newItem, itemKey, type, key) {
      const item = find(state.infoItems, { type, key });
      if (item?.itemsArrayWrapper)
        item.itemsArrayWrapper.updateWholeChildAtId(newItem, itemKey);
    },
    getItemWrappersByType(type) {
      return chain(state.infoItems)
        .filter({ type })
        .map('itemsArrayWrapper')
        .map('childArray')
        .flatten()
        .value();
    }
  };
}

export { sectionWrapper };