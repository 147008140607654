<template>
  <div class="border-solid border-2 relative rounded-2xl  bg-gray-100">
    <input
      class="bg-transparent w-full p-5 focus:outline-none"
      type="text"
      name="user-search"
      :placeholder="title"
      v-model="value"
      @input="update"
    />
    <i
      v-show="value === ''"
      class="search-input-icon fas fa-search cursor-pointer"
    />
    <i
      v-show="value !== ''"
      class="search-input-icon fas fa-times cursor-pointer"
      @click="clearValue"
    />
  </div>
</template>

<script>
export default {
  emit: ['update:modelValue'],
  props: {
    modelValue: { type: String, default: '' },
    title: { type: String, default: '' },
  },
  data() {
    return { value: this.modelValue };
  },
  watch: {
    modelValue() {
      this.value = this.modelValue;
    }
  },
  methods: {
    clearValue() {
      this.value = '';
      this.update();
    },
    update() {
      this.$emit('update:modelValue', this.value);
    }
  }
};
</script>