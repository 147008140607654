<template>
  <div
    class="
      stat-block
      flex flex-wrap
      items-center
      md:items-start
      w-1/2
      md:flex-1
      px-10
      py-8
      border-r
      md:border-b-0
    "
    :class="{ 'border-b': borderBottom }"
  >
    <div class="stat-block-header h-20 flex items-start">
      <i class="mr-8 text-3xl md:text-3xl" :class="icon"></i>
      <h2 class="flex-1 font-semibold text-3xl md:text-2xl"> {{ title }} </h2>
    </div>
    <div class="stat-block-body h-full flex w-full flex-col justify-between">
      <span
        class="
          stat-block-value
          text-brand-color
          font-semibold
          text-3xl
          md:text-2xl
        "
      >
        {{ stat }}
      </span>
      <p v-if="showAverage" class="stat-block-average text-gray-400 text-xl">
        <span class="text-gray-400">
          {{ Math.abs(Math.floor(average)) }}%
        </span>
        {{ Math.floor(average) >= 0 ? 'Above ' : 'Below ' }}
        Industry average
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    stat: {
      type: Number,
      default: 0
    },
    average: {
      type: Number,
      default: 0
    },
    showAverage: {
      type: Boolean,
      default: false
    },
    borderBottom: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style>
.stat-block {
  min-height: 11rem;
}
</style>