import { reverse, slice, sortBy, union, map } from 'lodash';
import {
  numberWithCommas,
  indexOfAll,
  hexToRgb
} from '~/functions/utils-functions';
import { lastWeek, today } from '~/functions/date-functions';

//#region
const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.display = 'flex';
    tooltipEl.style.width = 'max-content';
    tooltipEl.style.alignItems = 'center';
    tooltipEl.style.background = 'rgba(255, 255, 255, .9)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.borderColor = 'rgb(200, 200, 200)';
    tooltipEl.style.borderWidth = '1px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const bodyLines = tooltip.body.map((b) => b.lines);
    // Remove old children
    while (tooltipEl.firstChild) {
      tooltipEl.firstChild.remove();
    }

    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const span = document.createElement('span');
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderWidth = '2px';
      span.style.marginRight = '10px';
      span.style.height = '15px';
      span.style.width = '15px';
      span.style.display = 'inline-block';

      const text = document.createElement('span');
      text.innerHTML = document.createTextNode(body).textContent;
      text.style.fontWeight = '600';
      text.style.fontSize = '12px';
      tooltipEl.appendChild(span);
      tooltipEl.appendChild(text);
    });
  }

  const {
    offsetLeft: positionX,
    offsetTop: positionY,
    width: canvasWidth
  } = chart.canvas;

  const sideOffset = () => {
    return Math.max(
      0,
      tooltipEl.clientWidth +
      positionX +
      tooltip.caretX -
      (positionX + canvasWidth)
    );
  };

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX - sideOffset() + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.padding =
    tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
};
//#endregion

//#region Pie chart
const getColors = (count, defaultColor = 'rgb(35,137,169)') => {
  return slice(
    [
      defaultColor,
      'rgb(88,235,229)',
      'rgb(254,207,52)',
      'rgb(94,18,116)',
      'rgb(147,136,228)',
      'rgb(195,195,195)',
      'rgb(135,206,250)',
      'rgb(47,79,79)',
      'rgb(0,128,128)',
      'rgb(0,139,139)',
      'rgb(0,255,255)',
      'rgb(0,255,255)',
      'rgb(224,255,255)',
      'rgb(0,206,209)',
      'rgb(64,224,208)',
      'rgb(72,209,204)',
      'rgb(175,238,238)',
      'rgb(127,255,212)',
      'rgb(176,224,230)',
      'rgb(95,158,160)',
      'rgb(70,130,180)',
      'rgb(100,149,237)',
      'rgb(0,191,255)',
      'rgb(30,144,255)',
      'rgb(173,216,230)',
      'rgb(135,206,235)'
    ],
    0,
    count
  );
};

const getPieConfig = (labels, data, colors) => {
  return {
    type: 'doughnut',
    data: {
      labels: labels,
      datasets: [
        {
          label: '',
          data: data,
          backgroundColor: colors,
          hoverOffset: 4
        }
      ]
    },
    options: {
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          titleColor: '#777777',
          enabled: false,
          position: 'nearest',
          external: externalTooltipHandler
        }
      }
    }
  };
};

const formatPieChartTitle = (title, max) => {
  return title.replace(
    '##',
    `<span class="font-bold text-gray-700">${numberWithCommas(max)}</span>`
  );
};

const updatePieChart = (config, chart, data, defaultColor) => {
  config.data.datasets[0].backgroundColor = getColors(
    data.count + 1,
    defaultColor
  );
  config.data.datasets[0].data = data.data;
  config.data.labels = data.labels;
  chart.destroy();
};
//#endregion

const drawRect = (
  ctx,
  fill = 'white',
  border = 'black',
  borderSize = 1,
  dimensions = { x: 0, y: 0, w: 0, h: 0 }
) => {
  ctx.beginPath();
  ctx.fillStyle = fill;
  ctx.strokeStyle = border;
  ctx.lineWidth = borderSize + '';
  ctx.rect(dimensions.x, dimensions.y, dimensions.w, dimensions.h);
  ctx.fill();
  ctx.stroke();
};

//#region Line chart

const getLineConfig = (labels, data, highlightPoints, color = '#2389A9') => {
  return {
    type: 'line',
    data: {
      labels: labels,
      datasets: [
        {
          label: '',
          data: data,
          fill: false,
          borderColor: hexToRgb(color),
          pointBorderColor: hexToRgb(color),
          tension: 0.3,
          lineBorderWidth: 4
        }
      ]
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false
        }
      },
      scales: {
        y: {
          ticks: {
            display: false,
          },
          suggestedMin: 0
        }
      },
      layout: {
        padding: {
          top: 30
        }
      },
      elements: {
        point: {
          backgroundColor: highlightPoints['backgroundColor'],
          hoverRadius: highlightPoints['hoverRadius'],
          radius: highlightPoints['radius'],
          hoverBorderWidth: highlightPoints['hoverBorderWidth'],
          borderWidth: highlightPoints['borderWidth'],
          display: true
        }
      }
    },
    plugins: [
      {
        afterDatasetsDraw: function (chart) {
          const ctx = chart.ctx;
          chart.data.datasets.forEach(function (dataset, index) {
            const datasetMeta = chart.getDatasetMeta(index);
            if (datasetMeta.hidden) return;
            datasetMeta.data.forEach(function (point, index) {
              const value = dataset.data[index],
                x = point.getCenterPoint().x,
                y = point.getCenterPoint().y,
                fontSize = 14,
                fontFamily = 'Lato, Semibold',
                fontColor = color,
                fontStyle = 'normal';
              const xOffSet = value.toString().length * 2;
              ctx.save();
              ctx.textBaseline = 'middle';
              ctx.textAlign = 'center';
              ctx.font = fontStyle + ' ' + fontSize + 'px' + ' ' + fontFamily;
              const borderSize = 1;
              const backgroundWidthProxy = document.createElement('span-block');
              backgroundWidthProxy.classList.add('inline-block');
              backgroundWidthProxy.innerHTML = value;

              document
                .getElementsByTagName('body')[0]
                .appendChild(backgroundWidthProxy);

              backgroundWidthProxy.style.fontSize = fontSize + 'px';
              backgroundWidthProxy.style.fontFamily = fontFamily;
              backgroundWidthProxy.style.color = fontColor;
              backgroundWidthProxy.style.fontWeight = fontStyle;

              const backgroundWidth = backgroundWidthProxy.clientWidth + 10;
              backgroundWidthProxy.remove();

              const offsetX =
                index == 0
                  ? x + xOffSet
                  : index == datasetMeta.data.length - 1
                    ? x - xOffSet
                    : x;
              drawRect(ctx, 'white', '#CCCCCC', borderSize, {
                x: offsetX - backgroundWidth / 2,
                y: y - 35,
                w: backgroundWidth,
                h: 20
              });

              ctx.fillStyle = fontColor;
              ctx.fillText(value, offsetX, y - fontSize - 10);
              ctx.restore();
            });
          });
        }
      }
    ]
  };
};

const formatLineChartTitle = (title, max) => title + max;

const updateLineChart = (config, chart, data) => {
  config.data.datasets[0].data = data.data;
  config.data.labels = data.labels;
  chart.destroy();
};

//#endregion

//#region Chart untils
const chartProps = () => ({
  chartData: {
    type: Object,
    default: () => ({
      data: [],
      labels: [],
      count: 0
    })
  },
  title: {
    type: String,
    default: ''
  },
  limit: {
    type: Number,
    default: 5
  },
  start: {
    type: Object,
    default: () => lastWeek
  },
  end: {
    type: Object,
    default: () => today
  }
});

const sortLabelsAndWithData = (data, labels) => {
  const sortedData = sortBy(data);
  const arrangedIndexes = union(
    ...map(sortedData, (value) => indexOfAll(data, value))
  );
  return {
    data: reverse(sortedData),
    labels: reverse(map(arrangedIndexes, (index) => labels[index]))
  };
};

const chartStyling = () =>
  'flex flex-col md:flex-row items-center justify-center w-full h-full';
//#endregion

export {
  getColors,
  chartProps,
  getPieConfig,
  formatPieChartTitle,
  updatePieChart,
  getLineConfig,
  formatLineChartTitle,
  updateLineChart,
  chartStyling,
  sortLabelsAndWithData
};
