<template>
  <button
    :type="type"
    :disabled="disabled"
    :class="`pm-button ${background} ${size}  ${danger
      ? background == 'outline'
        ? 'danger-outline'
        : 'danger-solid'
      : ''
      } ${gutters ? 'my-12' : ''} ${disabled ? 'disable' : ''} button  ${compact ? '' : 'fill-width'
      }`"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    background: {
      type: String,
      default: 'solid'
    },
    danger: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'large'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    gutters: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.button {
  border: 1px solid;
  border-radius: 4px;
}

.button.fill-width {
  width: 100%;
}

.small {
  padding: 6px 12px;
  font-size: 14px;
}

.slim {
  padding: 10px 14px;
  font-size: 14px;
}

.medium {
  padding: 12px 14px;
  font-size: 14px;
}

.large {
  padding: 16px;
  font-size: 18px;
}

@media screen and (min-width: 768px) {
  .md\:small {
    padding: 6px 12px;
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .md\:slim {
    padding: 10px 14px;
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .md\:medium {
    padding: 12px 14px;
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .md\:large {
    padding: 16px;
    font-size: 18px;
  }
}

button.pm-button.disable {
  background: #e6e8ef;
  color: white;
  border-color: #e6e8ef;
}

.solid {
  border-color: var(--brand-dark-color);
  background: var(--brand-dark-color);
  color: white;
}

.solid-light {
  border-color: #fff;
  background: #fff;
  color: var(--brand-dark-color);
}

button.pm-button.outline {
  border-color: var(--brand-dark-color);
  color: var(--brand-dark-color);
  background: transparent;
}

.text {
  border-color: transparent;
  background: transparent;
}

button.pm-button.danger-solid {
  color: white;
  border: 1px solid red;
  background: red;
}

button.pm-button.danger-outline {
  color: red;
  border-color: red;
}

button.pm-button.solid.danger-solid:hover {
  color: red;
  border: 1px solid red;
  background: white;
}

button:disabled {
  background: gray !important;
  border-color: gray !important;
  cursor: default !important;
}

button.pm-button.outline.danger-outline:hover {
  color: white;
  border-color: red;
  background: red;
}

button.pm-button:hover {
  border-color: #157188;
  background: #157188;
  color: white;
}

button.pm-button.disable:hover {
  background: #e6e8ef;
  color: white;
  border-color: #e6e8ef;
}


button.pm-button.success {
  border-color: var(--brand-color-success);
  background: var(--brand-color-success);
  color: white;
}

button.pm-button.success:hover {
  border-color: var(--brand-color-success-hover);
  background: var(--brand-color-success-hover);
  color: white;
}
</style>