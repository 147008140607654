import {
  map,
  chain,
} from "lodash";
const regex = /\{([^}]+)\}/g;

// Use lodash to map over the matches and extract the captured strings

export default (titles) => {
  const getTitle = (titleKey, titleMatches) => {
    const matches = map(titles[titleKey].match(regex), (match) => match.slice(1, -1));
    return chain(matches)
      .reduce((acc, val) => acc.replace(`{${val}}`, titleMatches[val]), titles[titleKey])
      .value();
  };

  return {
    getTitle
  };
};