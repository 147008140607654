import { assign, isEqual } from 'lodash';

/**
 * orderBy
 * order
 * limit
 * page
 * filter
 * custom: e.g. companyId, start, end, isLive, isTemplate.
 */
export default (config, reactive = (s) => s) => {
  const query = reactive({
    orderBy: config.params.orderBy,
    order: config.params.order,
    limit: config.params.limit,
    page: config.params.page,
    filter: config.params.filter,
    ...config.params
  });
  const onChange = config.onChange || (() => {});

  return {
    query,
    getSortDirection(name, fieldName, direction) {
      if (name != fieldName) return '';
      return direction;
    },
    setQueryParam(key, value, mute = false) {
      if (!isEqual(query[key], value)) {
        query[key] = value;
        if (!mute) onChange(query);
      }
    },
    reload: () => onChange(query),
    resetQuery: () => {
      assign(query, config.params);
    }
  };
};
