<template>
  <div class="bg-gray-200 flex flex-col responsive">
    <div class="flex justify-center align-center p-16">
      <img
        v-if="!file.url"
        :class="square
          ? 'h-32'
          : 'rounded-full border object-cover border-white photo-size'
          "
        alt="picture"
        :src="formatImageUrl(fallbackImage)"
      />
      <loading-image
        v-else
        :loading="loading"
        :imgClss="square ? 'h-32' : 'photo-size'"
        :containerClss="imageClass"
        :url="formatImageUrl(file.url)"
      />
    </div>

    <div class="h-1 bg-white" />
    <div class="flex justify-around items-center">
      <cropper
        :aspect="aspect"
        :minWidth="minWidth"
        :minHeight="minHeight"
        :maxWidth="maxWidth"
        :maxHeight="maxHeight"
        ref="cropper-modal"
        :forceCrop="forceCrop"
        v-if="file.og || file.url || file.tempUrl || file.tempOg"
        v-model="file"
      >
        <template #trigger>
          <div
            @click="forceCrop = false"
            class="flex flex-col justify-center align-center p-8 items-center cursor-pointer"
          >
            <i class="fa-solid fa-crop-simple brand-font-color text-5xl p-6"></i>
            <p class="text-brand-color mt-8 text-2xl">Crop</p>
          </div>
        </template>
      </cropper>

      <label :for="photoInputId">
        <div class="flex flex-col justify-center align-center p-8 items-center cursor-pointer">
          <i class="fa-solid fa-camera brand-font-color text-5xl p-6"></i>
          <p class="text-brand-color mt-8 text-2xl">Upload Photo</p>
        </div>
      </label>
      <input
        name="profile-image-input"
        type="file"
        accept="image/*"
        @change="changePhoto"
        hidden
        :id="photoInputId"
      />
      <div
        v-show="file.url"
        @click="deleteImage"
        class="flex flex-col justify-center align-center p-8 items-center cursor-pointer"
      >
        <i class="fa-solid fa-trash-can brand-font-color text-5xl p-6"></i>
        <p class="text-brand-color mt-8 text-2xl">Delete</p>
      </div>
    </div>

    <div class="flex justify-center align-center py-12 px-20 bg-white">
      <pm-button @click="save">SAVE</pm-button>
    </div>
  </div>
</template>

<script>
import Cropper from '~/components/functional/cropper.vue';
import pmButton from '~/components/ui/pm-button.vue';
import loadingImage from '~/components/ui/loading-image.vue';
import { formatImageUrl } from '~/functions/utils-functions';
export default {
  setup: function () {
    const photoInputId = new Date().getTime();
    return {
      photoInputId
    };
  },
  props: {
    imageSrc: {
      type: Object,
      default: () => { }
    },
    loading: {
      type: Boolean,
      default: false
    },
    square: {
      type: Boolean,
      default: false
    },
    fallback: {
      type: String,
      default: ''
    },
    aspect: {
      type: Number,
      default: NaN
    },
    minWidth: {
      type: Number,
      default: 256
    },
    minHeight: {
      type: Number,
      default: 256
    },
    maxWidth: {
      type: Number,
      default: 1280
    },
    maxHeight: {
      type: Number,
      default: 1280
    },
  },
  data: function () {
    return {
      file: { ...this.imageSrc },
      forceCrop: false
    };
  },
  emits: ['image'],
  components: {
    loadingImage,
    Cropper,
    pmButton
  },
  watch: {
    imageSrc(newV) {
      this.file = newV;
    }
  },
  computed: {
    imageClass() {
      if (this.square) {
        return 'h-32 overflow-hidden object-contain w-full justify-center flex';
      }
      return 'photo-size rounded-full border border-white object-cover overflow-hidden';
    },
    fallbackImage() {
      if (this.fallback) {
        return this.fallback;
      }
      return this.$store.state.whiteLabel.profilePlaceholderImage;
    }
  },
  methods: {
    formatImageUrl,
    setValueToNull(id) {
      document.getElementById(id).value = null;
    },
    deleteImage() {
      this.file = {
        url: '',
        id: null
      };
    },
    createObjectURL: function (object) {
      return window.URL
        ? window.URL.createObjectURL(object)
        : window.webkitURL.createObjectURL(object);
    },
    changePhoto(e) {
      this.file = {
        ...this.file,
        tempOg: this.createObjectURL(e.target.files[0]),
        tempUrl: this.createObjectURL(e.target.files[0]),
        tempId: this.file.id
      };
      this.setValueToNull(this.photoInputId);
      this.$nextTick(() => {
        this.forceCrop = true;
        this.$refs['cropper-modal']?.openDialog();
      });
    },
    save: function () {
      this.$emit('image', this.file);
    }
  }
};
</script>

<style>
.responsive {
  width: 30vw;
}

.photo-size {
  width: 200px;
  height: 200px;
}

@media screen and (max-width: 1400px) {
  .responsive {
    width: 60vw;
  }
}

@media screen and (max-width: 768px) {
  .responsive {
    width: 95vw;
  }
}
</style>