<template>
  <div
    class="bg-white w-full relative"
    :class="{ 'pt-32 h-screen': isModal, 'h-full': !isModal }"
  >
    <div
      :type="'primary'"
      v-if="isModal"
      class="absolute top-0 left-0 right-0 bg-gray-300 flex justify-center items-center py-12"
    >
      <div
        v-show="listOfProfileChanges.length > 0"
        class="flex justify-center items-center cursor-default"
      >
        <p class="font-bold text-xl md:text-2xl mr-2"> Profile changes </p>
        <i
          class="bg-brand-color rounded-full h-10 w-10 text-white text-xl text-center items-center justify-center flex p-3 ml-2 text-lg"
          >{{ listOfProfileChanges.length }}</i
        >
      </div>
      <div
        v-show="listOfProfileChanges.length > 0"
        style="background: darkgray; width: 2px"
        class="h-8 mx-4 md:mx-8"
      />

      <div
        v-show="listOfProfileChanges.length > 0"
        class="flex justify-center items-center cursor-pointer"
      >
        <v-modal
          :showColon="true"
          title="Profile Updates"
          :extraTitle="`(${listOfProfileChanges.length})`"
          classes="modal-content-small"
          ><template #trigger="{ open }">
            <p
              @click="open"
              class="font-bold text-brand-color text-xl md:text-2xl mr-2 underline"
            >
              View updates
            </p>
          </template>
          <template #content>
            <v-changes-table :changes="listOfProfileChanges" /> </template
        ></v-modal>

        <i
          class="fa-solid fa-eye bg-brand-color rounded-full h-10 w-10 ml-2 text-white text-xl text-center items-center justify-center flex p-3 text-lg"
        ></i>
      </div>
      <div
        v-show="listOfProfileChanges.length > 0"
        style="background: darkgray; width: 2px"
        class="h-8 mx-4 md:mx-8"
      />

      <div
        @click="closeThis"
        class="flex justify-center items-center cursor-pointer"
      >
        <p class="font-bold text-xl md:text-2xl mr-2">
          Close profile preview
        </p>
        <i
          class="fa-solid fa-xmark bg-brand-color rounded-full h-10 ml-2 w-10 text-white text-center items-center justify-center flex p-3 text-lg"
        ></i>
      </div>
    </div>
    <iframe
      class="w-full h-full"
      @load="loadIframe"
      :src="previewURL"
      ref="iframe"
      frameborder="0"
    ></iframe>
    <div
      class="bg-white absolute w-full h-full top-0 left-0 flex justify-center items-center"
      :class="{ 'mt-36': isModal }"
      v-if="loading"
    >
      <v-loader :hideText="true"></v-loader>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { $vfm } from 'vue-final-modal';
import { onMounted } from 'vue';
import { computed, ref, toRef } from '@vue/reactivity';
import { watch } from 'vue';
import vChangesTable from '~/components/functional/v-changes-table.vue';
import vModal from '~/components/functional/v-modal.vue';
import vLoader from '~/components/ui/v-loader.vue';
export default {
  components: { vChangesTable, vModal, vLoader },
  props: {
    profile: {
      type: Object,
      default: () => {}
    },
    updatePreview: {
      type: Number,
      default: 0
    },
    profileChanges: {
      type: Array,
      default: () => []
    },
    showPreview: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: true
    }
  },
  emits: ['closePreview'],
  setup(props, { emit }) {
    // const preview = ref(null);
    const profile = toRef(props, 'profile');

    const updatePreview = toRef(props, 'updatePreview');
    const listOfProfileChanges = toRef(props, 'profileChanges');

    const showPreview = toRef(props, 'showPreview');
    const iframe = ref(null);
    const loading = ref(true);
    const previewURL = computed(
      () =>
        ({ development: '/preview-dev.html', beta: '/preview-beta.html' }[
          process.env.VUE_APP_CDN_ENV
        ] || '/preview.html')
    );

    const updateShowPreview = () => {
      loading.value = !showPreview.value;
      iframe.value.contentWindow.ProfileMeState.commit(
        'updateShowPreview',
        showPreview.value
      );
    };
    const updateProfile = () => {
      iframe.value.contentWindow.ProfileMeState.commit(
        'updateProfile',
        profile.value || {}
      );
    };
    const loadIframe = () => {
      watch(updatePreview, updateProfile);
      watch(showPreview, updateShowPreview);
      updateProfile();
      updateShowPreview();
      loading.value = !showPreview.value;
    };

    const closeThis = () => {
      emit('closePreview');
      $vfm.hide('modal-preview');
    };

    return {
      loading,
      previewURL,
      iframe,
      listOfProfileChanges,
      loadIframe,
      closeThis
    };
  }
};
</script>
