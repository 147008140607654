<template>
  <div
    class="relative flex items-center py-2 text whitespace-nowrap truncate w-full"
    :class="{ 'cursor-not-allowed': disabled }"
    style="overflow-wrap: anywhere;"
    :style="{ 'pointer-events:': disabled ? 'none' : '' }"
  >
    <input
      v-if="editable"
      @input="updateVal"
      @blur="save"
      @keydown.enter="save"
      :value="inputValue"
      :ref="name"
      :placeholder="textValue"
      class="text-4xl leading-none overflow-ellipsis w-full md:text-3xl"
      :style="{ 'pointer-events:': disabled ? 'none' : '', 'border-bottom': '1px solid lightgray' }"
      :type="type"
      :name="name"
    />
    <span
      v-else
      @click="editable = true"
      class="dynamic-width w-auto truncate"
      :class="{
        'text-brand-color': type == 'url',
        'font-bold text-4xl text-gray-800 cursor-pointer md:text-3xl':
          header,
        'bg-transparent text-3xl cursor-pointer': !header,
        'placeholder-black': type != 'url',
        'placeholder-link': type == 'url'
      }"
    >{{ textValue }}</span>
    <label
      class="cursor-pointer"
      @click="editable = true"
    >
      <span
        v-show="isRequired"
        class="ml-1 text-3xl text-red-600 leading-none"
      >* </span>
      <slot name="pre-pencil"></slot>
      <i class="fa-solid fa-pen text-gray-400 text-2xl w-6 ml-4 inline cursor-pointer leading-none"></i>
    </label>
  </div>
</template>

<script>
import { nextTick } from '@vue/runtime-core';
export default {
  props: {
    modelValue: String,
    emptyString: {
      type: String,
      default: 'Add Url'
    },
    type: {
      type: String,
      default: 'text'
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    header: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'edit-text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    inputPlaceholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editable: false,
      inputValue: this.modelValue
    };
  },
  watch: {
    modelValue(nv) {
      this.inputValue = nv;
    },
    editable(nv) {
      if (nv) {
        nextTick(() => {
          this.$refs[this.name].focus();
        });
      }
    }
  },
  computed: {
    textValue() {
      return this.inputValue || this.placeholder || this.emptyString;
    }
  },
  emits: ['update:modelValue', 'change'],
  methods: {
    truncateString(str, num) {
      if (str.length > num) {
        return str.slice(0, num) + '...';
      } else {
        return str;
      }
    },
    isURL(url = '') {
      if (!url) return false;
      return /https:\/\/[\S]*|#[\S]*/g.test(url);
    },
    updateVal(e) {
      this.inputValue = e.target.value;
      this.$emit('update:modelValue', this.inputValue);
      this.$emit('change', this.inputValue);
    },
    save(e) {
      this.editable = false;
      e.stopPropagation();
      this.$emit('update:modelValue', this.inputValue);
      this.$emit('change', this.inputValue);
    }
  }
};
</script>

<style scoped>
.position-checkmark {
  position: absolute;
  right: 0px;
  top: 50%;
}

input:focus,
input:active,
input:focus-within {
  border: none;
  outline: none;
}

input.placeholder-black::placeholder {
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

input.placeholder-link::placeholder {
  color: var(--brand-color);
}

input::placeholder {
  color: #afafaf;
}

.dynamic-width {
  max-width: calc(100% - 30px);
}
</style>
