<template>
  <div class="table-mobile-cell bg-white border rounded-lg pt-5 px-5">
    <div class="profile-card justify-center items-center" :class="styling">
      <slot name="profile-card"></slot>
    </div>
    <div class="preview justify-end items-center" :class="styling">
      <slot name="preview-buttons"></slot>
    </div>
    <hr class="separator" />
    <div class="info items-start" :class="styling">
      <slot name="content"></slot>
    </div>
    <div class="action items-center justify-end" :class="styling">
      <slot name="action-buttons"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      styling: 'flex'
    };
  }
};
</script>

<style>
.table-mobile-cell {
  display: grid;
  grid-template:
    'p p p pr pr' 80px
    'sep sep sep sep sep' auto
    'i i i i a' auto / minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
      0,
      1fr
    )
    100px;
}

.info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50px;
  padding: 10px;
  gap: 10px;
}

.profile-card {
  grid-area: p;
}
.preview {
  grid-area: pr;
}
.separator {
  grid-area: sep;
}
.info {
  grid-area: i;
}
.action {
  grid-area: a;
}
</style>