import { filter, every, pick, omit, findIndex } from 'lodash';
import { wrapInArrayIfNeeded } from '../utils-functions';

export default function (
  items = [],
  filters = {},
  status = '',
  reactive = (v) => v
) {
  const state = reactive({
    items,
    status,
    filters,
    updater: 0
  });

  const filterWithAllConditions = () =>
    filter(state.items, (item) =>
      every(state.filters, (filter) => filter(item))
    );
  const filterWithConditions = (keys) =>
    filter(state.items, (item) =>
      every(pick(state.filters, wrapInArrayIfNeeded(keys)), (filter) =>
        filter(item)
      )
    );

  return {
    get filteredItems() {
      return filterWithAllConditions();
    },
    get currentItems() {
      return filterWithConditions(state.status);
    },
    get items() {
      return state.items;
    },
    applyFilter(...keys) {
      return filterWithConditions(keys);
    },
    setList(items) {
      state.items = items;
    },
    setValueAtKey(key, id, valueKey, value) {
      const index = findIndex(state.items, { [key]: id });
      if (index >= 0 && state.items[index][valueKey])
        state.items[index][valueKey] = value;
    },
    removeItemByKey(key, value) {
      state.items = filter(state.items, (i) => i[key] != value);
    },
    addItem(item) {
      state.items[state.items.length] = item;
    },
    toggleItemsStatus(...status) {
      state.status = status;
    },
    setItemsFilters(filter) {
      state.filters = { ...state.filters, ...filter };
    },
    removeFilterByKey(...keys) {
      state.filters = omit(state.filters, keys);
    },
    update() {
      state.updater++;
    },
    get size() {
      return filterWithConditions(state.status).length;
    }
  };
}
