import axios from 'axios';
import {
  convertDBProfileToSelfServiceScheme,
  convertSelfServiceSchemeToDBProfile
} from '~/functions/conversion-functions';
import { selfServiceSchema } from '~/constants/schemas';
import { cloneDeep, filter } from 'lodash';

const BASE_URL = process.env.VUE_APP_STRAPI_URL;

export default function selfServiceApi(jwt) {
  const removeAllCloudinaryToBannerSlider = async (profileBanner) => {
    profileBanner.banner.slider = filter(
      profileBanner.banner.slider,
      (slide) => {
        deleteImage(slide.id);
        return false;
      }
    );
    return profileBanner;
  };
  const createNewProfileBanner = async (
    profile,
    cloudinaryImage,
    bannerType
  ) => {
    const profileBanner = {
      profile,
      type: bannerType,
      banner: {
        slider: [cloudinaryImage]
      }
    };
    const { success, message } = await createProfileBanner(profileBanner);
    if (!success) {
      throw message;
    }
    return message;
  };
  const updateProfileBannerImage = async (
    profileBanner,
    cloudinaryImage = null
  ) => {
    profileBanner = await removeAllCloudinaryToBannerSlider(profileBanner);
    if (cloudinaryImage) {
      profileBanner.banner.slider.push(cloudinaryImage);
    }
    const { success, message } = await createProfileBanner(profileBanner, true);
    if (!success) {
      throw message;
    }
    return message;
  };
  const createProfileBanner = async (profileBanner, update = false) => {
    try {
      const data = await axios({
        method: update ? 'PUT' : 'POST',
        url: `${BASE_URL}/profile-banners${
          update ? `/${profileBanner.id}` : ''
        }?populate[0]=banner.slider`,
        headers: {
          Authorization: `Bearer ${jwt}`
        },
        data: { data: profileBanner }
      });
      return { success: true, message: data.data };
    } catch (err) {
      return { success: false, message: err.message };
    }
  };
  const deleteProfileBanner = async (profileBanner) => {
    try {
      const data = await axios({
        method: 'DELETE',
        url: `${BASE_URL}/profile-banners/${profileBanner.id}`,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      return { success: true, message: data.data };
    } catch (err) {
      return { success: false, message: err.message };
    }
  };
  const getProfile = async (profileId) => {
    try {
      const data = await axios({
        method: 'GET',
        url: `${BASE_URL}/profiles/${profileId}`,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      return { success: true, message: data.data };
    } catch (err) {
      return { success: false, message: err.message };
    }
  };
  const getPurchaseHistory = async (userId) => {
    try {
      const data = await axios({
        method: 'GET',
        url: `${BASE_URL}/purchase-histories?_where[user.id]=${userId}`,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      return { success: true, message: data.data };
    } catch (err) {
      return { success: false, message: err.message };
    }
  };
  const getLeadForm = async (formId) => {
    try {
      const data = await axios({
        method: 'GET',
        url: `${BASE_URL}/lead-forms?form_id=${formId}`,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      return data.data;
    } catch (err) {
      return { success: false, message: err.message };
    }
  };

  const blobUrlToFile = (blobUrl) =>
    new Promise((resolve) => {
      fetch(blobUrl).then((res) => {
        res.blob().then((blob) => {
          const file = new File(
            [blob],
            new Date().getTime()?.toString() + '.' + blob.type.split('/')[1],
            {
              type: blob.type
            }
          );
          resolve(file);
        });
      });
    });

  const updateLeadform = async ({ firstName, lastName, contactForm }) => {
    try {
      const form = await axios({
        method: 'PUT',
        url: `${BASE_URL}/lead-forms/${contactForm.id}`,
        headers: {
          Authorization: `Bearer ${jwt}`
        },
        data: {
          data: {
            title: `${firstName}${lastName}-leadform`,
            fields: [
              {
                label: 'Email Address',
                required: contactForm.emailAddress.required,
                type: 'email',
                field_name: 'emailAddress',
                enabled: contactForm.emailAddress.enabled
              },
              {
                label: 'Name',
                required: contactForm.name.required,
                type: 'text',
                field_name: 'name',
                enabled: contactForm.name.enabled
              },
              {
                label: 'Mobile',
                required: contactForm.mobile.required,
                type: 'number',
                field_name: 'mobile',
                enabled: contactForm.mobile.enabled
              },
              {
                label: 'Message',
                required: contactForm.message.required,
                type: 'message',
                field_name: 'message',
                enabled: contactForm.message.enabled
              }
            ],
            enabled: contactForm.enabled,
            form_submitted_message: contactForm.thankYouMessage
          }
        }
      });

      return {
        success: true,
        message: {
          title: form.data.title,
          formId: form.data.form_id,
          id: form.data.id
        }
      };
    } catch (err) {
      return { success: false, message: err.message };
    }
  };
  const createLeadForm = async ({ firstName, lastName, contactForm }) => {
    try {
      const formId = `${firstName}${lastName}`.replace(/[!@#$%^&*()]/gi, '');
      const newForm = await axios({
        method: 'POST',
        url: `${BASE_URL}/lead-forms`,
        headers: {
          Authorization: `Bearer ${jwt}`
        },
        data: {
          title: `${formId}-leadform`,
          fields: [
            {
              label: 'Email Address',
              required: contactForm.emailAddress.required,
              type: 'email',
              field_name: 'emailAddress',
              enabled: contactForm.emailAddress.enabled
            },
            {
              label: 'Name',
              required: contactForm.name.required,
              type: 'text',
              field_name: 'name',
              enabled: contactForm.name.enabled
            },
            {
              label: 'Mobile',
              required: contactForm.mobile.required,
              type: 'number',
              field_name: 'mobile',
              enabled: contactForm.mobile.enabled
            },
            {
              label: 'Message',
              required: contactForm.message.required,
              type: 'message',
              field_name: 'message',
              enabled: contactForm.message.enabled
            }
          ],
          subject: 'New Lead Form Received!',
          form_submitted_message: contactForm.thankYouMessage,
          form_id: `${formId}-leadform-${Date.now()}`.split(' ').join('-')
        }
      });
      return {
        success: true,
        message: {
          title: newForm.data.title,
          formId: newForm.data.form_id,
          id: newForm.data.id
        }
      };
    } catch (err) {
      return { success: false, message: err.message };
    }
  };
  const uploadImage = async ({ url, id = null }, withUrl) => {
    if (url) {
      if (url?.toString().includes('blob')) {
        let blob = await blobUrlToFile(url);
        const formData = new FormData();
        formData.append('files', blob);
        let uploadedImage = {};
        try {
          uploadedImage = await axios({
            method: 'POST',
            url: `${BASE_URL}/upload`,
            headers: {
              Authorization: `Bearer ${jwt}`,
              'Content-Type': 'multipart/form-data'
            },
            data: formData
          });
        } catch (err) {
          return {
            success: false,
            message:
              err?.response?.data?.message?.[0].messages?.[0]?.message ||
              'Oops! Error'
          };
        }
        if (withUrl) {
          return {
            success: true,
            message: {
              id: uploadedImage.data[0].id,
              url: uploadedImage.data[0].url
            }
          };
        }
        return { success: true, message: uploadedImage.data[0].id };
      } else {
        if (withUrl) {
          return { success: true, message: { id, url } };
        }
        return { success: true, message: id };
      }
    }
    return { success: true, message: null };
  };
  const deleteImage = async (id) => {
    console.log('Deleting Image', id);
    try {
      await axios({
        method: 'DELETE',
        url: `${BASE_URL}/upload/files/${id}`,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      console.log('Image deleted');
      return {
        success: true
      };
    } catch (err) {
      console.warn('Image delete error', err);
      return { success: true, message: err };
    }
  };
  const updatePermissions = async (id, data) => {
    try {
      const result = await axios({
        method: 'PUT',
        url: `${BASE_URL}/permissions/${id}`,
        headers: {
          Authorization: `Bearer ${jwt}`
        },
        data: {
          data: { permissions: data }
        }
      });
      return { success: true, id: result.data.id };
    } catch (err) {
      return { success: false, message: err.message };
    }
  };
  const clearPermissions = async (id) => {
    try {
      const result = await axios({
        method: 'PUT',
        url: `${BASE_URL}/permissions/${id}`,
        headers: {
          Authorization: `Bearer ${jwt}`
        },
        data: {
          data: { permissions: [] }
        }
      });
      return { success: true, id: result.data.id };
    } catch (err) {
      return { success: false, message: err.message };
    }
  };
  const createPermissions = async (id, data, title, updateMaster) => {
    try {
      const result = await axios({
        method: 'POST',
        url: `${BASE_URL}/permissions/`,
        headers: {
          Authorization: `Bearer ${jwt}`
        },
        data: {
          data: updateMaster
            ? {
                permissions: data,
                title: 'Master - ' + title
              }
            : {
                permissions: data,
                title
              }
        }
      });

      await updateProfile(
        {
          ...(updateMaster
            ? {
                master_permission: { set: [result.data.id] }
              }
            : {
                child_permission: { set: [result.data.id] }
              })
        },
        id
      );
      return { success: true, id: result.data.id };
    } catch (err) {
      return { success: false, message: err.message };
    }
  };

  const updateProfile = async (data, profileId) => {
    try {
      const result = await axios({
        method: 'PUT',
        url: `${BASE_URL}/profiles/${profileId}`,
        headers: {
          Authorization: `Bearer ${jwt}`
        },
        data: { data }
      });
      return { success: true, message: result.data.id };
    } catch (err) {
      return { success: false, message: err.message };
    }
  };

  const updateStyleSheet = async (data, stylingId) => {
    try {
      const result = await axios({
        method: 'PUT',
        url: `${BASE_URL}/profile-stylings/${stylingId}`,
        headers: {
          Authorization: `Bearer ${jwt}`
        },
        data: { data }
      });
      return { success: true, message: result.data.id };
    } catch (err) {
      return { success: false, message: err.message };
    }
  };

  const createProfile = async (data) => {
    try {
      const result = await axios({
        method: 'POST',
        url: `${BASE_URL}/profiles`,
        headers: {
          Authorization: `Bearer ${jwt}`
        },
        data
      });
      return { success: true, message: result.data.id };
    } catch (err) {
      return { success: false, message: err.message };
    }
  };

  const testAlias = async (alias) => {
    try {
      const result = await axios({
        method: 'GET',
        url: `${BASE_URL}/profiles/validateAlias?alias=${alias}`,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      if (result.data) {
        return { success: true, message: true };
      }
      return { success: true, message: false };
    } catch (err) {
      return { success: false, message: err.message };
    }
  };

  const buildProfile = async (id) => {
    try {
      const response = await axios({
        method: 'PUT',
        url: `${BASE_URL}/profiles/${id}/publish`,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      if (response.data.success) {
        return {
          success: true,
          message: '',
          site: {
            siteId: response.data.site.netlifySiteID,
            domainName: response.data.site.domainName
          }
        };
      } else {
        return { success: false, message: '' };
      }
    } catch (err) {
      console.error(err.message);
      return { success: false, message: err.message };
    }
  };

  const setAlias = async (profileId, alias) => {
    try {
      const result = await axios({
        method: 'PUT',
        url: `${BASE_URL}/profiles/${profileId}`,
        headers: {
          Authorization: `Bearer ${jwt}`
        },
        data: {
          profileAlias: alias
        }
      });
      return { success: true, message: result.data.id };
    } catch (err) {
      return { success: false, message: err.message };
    }
  };

  const getSelfServiceProfile = async (profileId) => {
    if (profileId) {
      const profileFromDB = await getProfile(profileId);
      if (profileFromDB?.success) {
        const converted = convertDBProfileToSelfServiceScheme(
          profileFromDB?.message
        );
        return { success: true, message: converted };
      } else {
        return { success: true, message: selfServiceSchema() };
      }
    } else {
      // error getting profile from db
      return { success: true, message: selfServiceSchema() };
    }
  };

  const saveProfileToDB = async (
    profile,
    options = {
      isSupervisor: false,
      isSuperAdmin: false,
      userId: 0,
      userEmail: ''
    }
  ) => {
    try {
      // handle company
      const profileId = profile.profileId;
      const userEmail = profile.isMaster
        ? ''
        : options.isSupervisor || options.isSuperAdmin
        ? profile.userAccountEmail
        : options.userEmail;
      const userId = profile.isMaster
        ? null
        : options.isSupervisor || options.isSuperAdmin
        ? profile.userId
        : options.userId || null;

      const data = cloneDeep(profile);

      if (!data.userId) {
        delete data.userId;
      }

      const companyId = data.companyId;
      const convertedProfile = convertSelfServiceSchemeToDBProfile({
        ...data,
        userId,
        companyId,
        userAccountEmail: userEmail
      });

      // handle profile
      let profileResult = undefined;
      if (profileId) {
        profileResult = await updateProfile(convertedProfile, profileId);
      } else {
        return { success: true, hideNotification: true };
      }

      if (profileResult?.success) {
        return {
          success: true,
          message: profileResult.message,
          profile: convertedProfile,
          companyId: companyId
        };
      } else {
        // profile update/create failed
        console.error('Profile Update Failed', profileResult?.message);
        return {
          success: false,
          message: 'Failed to update profile'
        };
      }
    } catch (err) {
      // save profile function failed
      console.error('Save Profile Function Failed', err?.message);
      return {
        success: false,
        message: 'Failed to update profile'
      };
    }
  };

  const updateMasterChildren = async (masterId) => {
    try {
      axios({
        method: 'POST',
        url: `${BASE_URL}/profiles/rebuild-children/${masterId}`,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      });
      return { success: true };
    } catch (err) {
      console.log(err);
      return { success: false, message: err.message };
    }
  };

  return {
    buildProfile,
    getProfile,
    updateMasterChildren,
    getLeadForm,
    blobUrlToFile,
    updateLeadform,
    createLeadForm,
    uploadImage,
    updateProfile,
    setAlias,
    createProfile,
    updateStyleSheet,
    testAlias,
    getSelfServiceProfile,
    saveProfileToDB,
    getPurchaseHistory,
    deleteImage,
    updatePermissions,
    createPermissions,
    clearPermissions,
    createProfileBanner,
    deleteProfileBanner,
    createNewProfileBanner,
    updateProfileBannerImage
  };
}
