<template>
  <div
    class="htmlText px-12 pt-12"
    v-html="`<p class='embedded_html'>${html}</p>`"
  />
</template>

<script>
export default {
  props: {
    html: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
:v-deep(p) span {
  font-size: 18px;
}
.htmlText :v-deep(h1) {
  font-size: 26px;
  font-weight: bold;
}
.htmlText :v-deep(h2) {
  font-size: 24px;
  font-weight: bold;
}
.htmlText :v-deep(h3) {
  font-size: 20px;
  font-weight: bold;
}
.htmlText :v-deep(h4) {
  font-size: 16px;
  font-weight: bold;
}
.htmlText :v-deep(h5) {
  font-size: 14px;
  font-weight: bold;
}
.htmlText :v-deep(ul) {
  list-style: circle;
}

.htmlText :v-deep(ul) li ul li {
  margin-left: 16px;
}
:v-deep(ul) li span {
  font-size: 16px;
  margin-bottom: 8px;
}
.htmlText :v-deep(ul) li {
  margin-bottom: 8px;
}
.htmlText :v-deep(a) {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
.htmlText :v-deep(table) {
  border-collapse: collapse;
  border-spacing: 0;
}

.htmlText :v-deep(hr) {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}
</style>