import { createRouter, createWebHistory } from 'vue-router';
import authService from '~/api/auth.js';

import middlewarePipeline from '../middleware/index.js';
import routes from './routes.js';

// Define router
const router = createRouter({
  history: createWebHistory(),
  routes
});

// Middleware
function global() {
  const { $store } = router.app.config.globalProperties;
  return { store: $store };
}

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;

  const local = localStorage;
  const session = sessionStorage;
  const store = global().store;

  // if not from static pages
  if (to.name == 'Main') {
    authService(sessionStorage.getItem('_token'))
      .validateJWT()
      .then(({ success }) => {
        if (!success) {
          sessionStorage.removeItem('_token');
          router.push({ name: 'Login' });
        }
      });
  }

  const context = {
    to,
    from,
    next,
    store,
    session,
    local
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  });
});

export default router;
