<template>
  <div
    class="bg-color-nav flex relative w-full items-center justify-between px-16 md:px-0 py-8 md:py-0 md:justify-between md:h-48"
  >
    <div
      class="logo white flex justify-between items-center md:px-10 md:bg-brand-color md:w-1/3 md:h-full"
    >
      <img
        :src="formatImageUrl(mobileLogo)"
        class="md:hidden h-20 ml-6"
        alt="logo"
      />
      <img :src="formatImageUrl(logo)" class="hidden md:block" alt="logo" />
    </div>
    <div class="w-full">
      <div class="flex relative w-full items-center justify-between md:h-48">
        <div
          class="hidden items-center justify-center flex-1 md:flex md:px-16 md:border-b-2 md:justify-start md:h-full"
        >
          <h1
            class="text-gray-900 leading-none font-bold text-center text-2xl ml-5 mr-5 md:text-4xl md:text-left md:font-normal"
          >
            <span class="whitespace-nowrap text-gray-700"
              >Hello {{ startCase(title) }}
            </span>
          </h1>
        </div>
        <div
          style="width: inherit"
          class="nav-links flex items-center justify-end md:h-full md:static md:border-b-2 md:pr-20"
        >
          <div
            v-if="!isUser"
            @click="openNavigation"
            class="cursor-pointer mr-5 block md:hidden"
          >
            <i
              class="fa-solid fa-bars text-6xl font-normal mr-8 md:mr-1 md:text-7xl md:font-medium md:text-gray-400 text-white"
            ></i>
          </div>
          <div @click="openSettings" class="cursor-pointer">
            <i
              class="far fa-cog text-6xl font-thin md:mr-1 md:text-7xl md:font-medium md:text-gray-400 text-white"
            ></i>
          </div>
        </div>
      </div>
    </div>

    <vue-final-modal
      :fit-parent="false"
      v-model="showSettings"
      :name="'modal-settings'"
      :esc-to-close="false"
    >
      <settings @close="closeSettings" />
    </vue-final-modal>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { get, startCase } from 'lodash';
import { $vfm, VueFinalModal } from 'vue-final-modal';
import settings from '~/views/settings/settings-page.vue';
import { formatImageUrl } from '~/functions/utils-functions';
export default {
  emits: ['clickOpen'],
  props: {
    isUser: {
      type: Boolean,
      default: false
    }
  },
  setup(_, { emit }) {
    const store = useStore();
    const title = computed(() => {
      if (get(store.state.auth, 'user.title', false))
        return get(store.state.auth, 'user.title');
      return get(store.state.auth, 'user.username');
    });
    const showSettings = ref(false);

    const mobileLogo = computed(() => store.state.whiteLabel.lightIcon);
    const logo = computed(() => store.state.whiteLabel.lightLogo);

    const openSettings = () => {
      location.hash = '';
      $vfm.show('modal-settings');
    };
    const closeSettings = () => {
      $vfm.hide('modal-settings');
    };

    return {
      title,
      openSettings,
      closeSettings,
      showSettings,
      mobileLogo,
      formatImageUrl,
      logo,
      startCase,
      openNavigation: () => emit('clickOpen')
    };
  },
  components: {
    VueFinalModal,
    settings
  }
};
</script>
<style scoped>
.logo {
  width: 80%;
  max-width: 412px;
}
.bg-color-nav {
  background: var(--brand-dark-color);
}

.toolbar-padding {
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (min-width: 200px) {
  .toolbar-padding {
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media screen and (min-width: 300px) {
  .toolbar-padding {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (min-width: 330px) {
  .toolbar-padding {
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media screen and (min-width: 360px) {
  .toolbar-padding {
    padding-left: 22px;
    padding-right: 22px;
  }
}

@media screen and (min-width: 375px) {
  .toolbar-padding {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (min-width: 400px) {
  .toolbar-padding {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media screen and (min-width: 450px) {
  .toolbar-padding {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media screen and (min-width: 480px) {
  .toolbar-padding {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (min-width: 500px) {
  .toolbar-padding {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media screen and (min-width: 520px) {
  .toolbar-padding {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (min-width: 550px) {
  .toolbar-padding {
    padding-left: 55px;
    padding-right: 55px;
  }
}

@media screen and (min-width: 580px) {
  .toolbar-padding {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media screen and (min-width: 620px) {
  .toolbar-padding {
    padding-left: 65px;
    padding-right: 65px;
  }
}

@media screen and (min-width: 650px) {
  .toolbar-padding {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media screen and (min-width: 680px) {
  .toolbar-padding {
    padding-left: 75px;
    padding-right: 75px;
  }
}

@media screen and (min-width: 700px) {
  .toolbar-padding {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media screen and (min-width: 740px) {
  .toolbar-padding {
    padding-left: 85px;
    padding-right: 85px;
  }
}

@media screen and (min-width: 768px) {
  .logo {
    max-width: 255px;
    min-width: 320px;
  }
  .bg-color-nav {
    background: white !important;
  }
  .toolbar-padding {
    padding: 0;
  }
}
</style>