<template>
  <div
    class="dropdown relative"
    :class="classes"
  >
    <div
      v-if="position == 'top'"
      class="dropdown-content absolute right-0 bottom-0 mb-20 z-20"
      :class="dropdownClasses"
      v-show="showContent"
      @click.stop
    >
      <slot name="content"></slot>
    </div>
    <div
      class="dropdown-button"
      @click.stop.prevent="showContentEvent"
    >
      <slot name="button"></slot>
    </div>
    <div
      v-if="position != 'top'"
      class="dropdown-content absolute right-0 mt-2 z-20"
      :class="dropdownClasses"
      v-show="showContent"
      @click.stop
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  emit: ['update:modelValue'],
  props: {
    modelValue: { type: Boolean, default: false },
    classes: {
      type: String,
      default: ''
    },
    dropdownClasses: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'bottom'
    }
  },
  data() {
    return { showContent: this.modelValue };
  },
  created() {
    document.addEventListener('click', () => {
      this.showContent = false;
    });
  },
  watch: {
    modelValue() {
      this.showContent = this.modelValue;
    }
  },
  methods: {
    showContentEvent() {
      if (!this.showContent) {
        document.getElementsByTagName('body')[0].click();
        this.showContent = true;
      }
      this.$emit('update:modelValue', this.showContent);
    }
  }
};
</script>