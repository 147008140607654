import { find, isNil } from 'lodash';
const getNextDirection = (direction, newSortingName = false) => {
  if (newSortingName || direction == '') return 'desc';
  else if (direction == 'desc') return 'asc';
  else if (direction == 'asc') return '';
};
const getNextDirectionReverse = (direction, newSortingName = false) => {
  if (newSortingName || direction == '') return 'asc';
  else if (direction == 'asc') return 'desc';
  else if (direction == 'desc') return '';
};

const getSortDirectionStatic = (name, fieldName, direction) => {
  if (name != fieldName) return '';
  return direction;
};
/**
 * Static means its a static function
 */
export default (config, data, reactive = (obj) => obj) => {
  const state = reactive({
    direction: '',
    fieldName: '',
    data: data,
    onSort: () => { }
  });
  const getSortedDataStatic = (fieldName, direction, data) => {
    const key = find(config, { name: fieldName });
    if (key) {
      if (direction == 'asc')
        return data.sort((a, b) => {
          const aVal = !isNil(a[key.key]) ? a[key.key] : a[key.backupKey];
          const bVal = !isNil(b[key.key]) ? b[key.key] : b[key.backupKey];
          if (typeof bVal == 'number') return aVal - bVal;
          return aVal.localeCompare(bVal);
        });
      if (direction == 'desc')
        return data.sort((a, b) => {
          const aVal = !isNil(a[key.key]) ? a[key.key] : a[key.backupKey];
          const bVal = !isNil(b[key.key]) ? b[key.key] : b[key.backupKey];
          if (typeof bVal == 'number') return bVal - aVal;
          return bVal.localeCompare(aVal);
        });
    }
    return data;
  };
  return {
    setData(data) {
      state.data = data;
    },
    getSortName() {
      return state.fieldName;
    },
    getSortDirection(name) {
      if (name != state.fieldName) return '';
      return state.direction;
    },
    getSortedData() {
      return getSortedDataStatic(state.fieldName, state.direction, state.data);
    },
    getSortDirectionStatic,
    setSort(name) {
      state.direction = getNextDirection(
        state.direction,
        name != state.fieldName
      );
      state.fieldName = name;
      state.onSort();
    },
    getNextDirection,
    getSortedDataStatic,
    onSort(fn = () => { }) {
      state.onSort = fn;
    }
  };
};
export {
  getNextDirection,
  getSortDirectionStatic,
  getNextDirectionReverse
};