import { computed, ref } from 'vue';
import { approvalsConfig } from '~/functions/setup/api-setup';
import dataWrapper from '../wrappers/data-wrapper';
import userState from '~/store/state-managers/user';
import qs from 'qs';
import { isEmpty, map } from 'lodash';

function useApprovals(
  companies,
  onStartCallback = () => {},
  callback = () => {}
) {
  const { userRole, isSuperAdmin } = userState();
  const getCompaniesApiQuery = (c) =>
    '&' +
    qs.stringify(
      {
        _where: {
          _or: map(c, (id) => ({
            'company.id': id
          }))
        },
        approved_null: true
      },
      { encode: false }
    );
  const queryString = isSuperAdmin
    ? ''
    : getCompaniesApiQuery(map(companies, 'id'));

  const { approvals: allApprovals, fetchData: fetchApprovals } = dataWrapper({
    reports: approvalsConfig(),
    onComplete: () => {
      callback();
    }
  });

  const reloadApprovals = (query = {}) => {
    const division = map(query.division, 'id');
    const _append_ = isEmpty(division)
      ? queryString
      : getCompaniesApiQuery(division);
    if (_append_) {
      onStartCallback();
      fetchApprovals({ role: userRole(), _append_ });
    } else callback();
  };

  const useActiveApprovals = ref(true);
  const approvals = computed(() => {
    if (useActiveApprovals.value) {
      return activeApprovals.value;
    }
    return inactiveApprovals.value;
  });
  const toggleApprovalStatus = () => {
    useActiveApprovals.value = !useActiveApprovals.value;
    callback();
  };
  const activeApprovals = computed(() => {
    return allApprovals.value.filter(
      (app) => app.approved === null || app.approved === undefined
    );
  });
  const inactiveApprovals = computed(() => {
    return allApprovals.value.filter(
      (app) => app.approved === true || app.approved === false
    );
  });

  return {
    useActiveApprovals,
    approvals,
    toggleApprovalStatus,
    reloadApprovals
  };
}

export default useApprovals;
